import useRootHook from "../hooks/useRoot";
import {Backdrop, CircularProgress} from "@mui/material";
import * as React from "react";

export default function Loader (props) {
    const { showLoader} = useRootHook();
    return showLoader &&
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={showLoader}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
}