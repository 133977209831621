import * as React from 'react';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from '../Title';
import {useEffect, useState} from "react";
import admins from '../../models/admins'
import helpers from "../../helpers/common";
import useRootHook from "../../hooks/useRoot";
import {Add, Check} from "@mui/icons-material";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import {Link as RouterLink} from "react-router-dom";
import Paper from "@mui/material/Paper";
import BreadCrumbs from "../BreadCrumbs";
import {Stack} from "@mui/material";

export default function AdminIndex(props) {
    const [adminData, setAdminData] = useState([])
    const {setShowLoader} = useRootHook()

    useEffect(() => {
        setShowLoader(true)
        admins.getData()
            .then((data) => {
                data && setAdminData(data)
                setShowLoader(false)
            }).catch(() => setShowLoader(false))
    }, [])

    return <React.Fragment>
        <Grid item xs={12}>
            <BreadCrumbs elements={[['Dashboard', '/'], ['Admins', '/admins']]}/>
        </Grid>
        <Grid item xs={12}>
            <Stack spacing={2} direction="row">
                <Button to="/admins/add" component={RouterLink} variant="contained" startIcon={<Add />}>
                    Add Admin
                </Button>
            </Stack>
        </Grid>
        <Grid item xs={12}>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                <Title>Admins</Title>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Username</TableCell>
                            <TableCell>Super</TableCell>
                            <TableCell>Date Created</TableCell>
                            <TableCell>Date Modified</TableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {adminData.map((row) => (
                            <TableRow key={`admin-${row.id}`}>
                                <TableCell>
                                    <Link color="primary" to={`edit?id=${row.id}`} component={RouterLink}>
                                        {`${row.username}`}
                                    </Link>
                                </TableCell>
                                <TableCell>{row.super ? <Check /> : ''}</TableCell>
                                <TableCell>{helpers.getDate(row.created)}</TableCell>
                                <TableCell>{helpers.getDate(row.modified)}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Paper>
        </Grid>
    </React.Fragment>
}


