import helpers from "../helpers/common";
import request from "../helpers/request";

const currencies = {}

currencies.defaultCurrency = 'EUR'

currencies.index = () => {
    return request.get('/currencies', {}, true)
}
currencies.get = (id) => {
    return request.get('/currencies/get', {id}, true)
}
currencies.add = (data) => {
    return request.post('/currencies/add', data, true)
}
currencies.edit = (data) => {
    return request.post('/currencies/edit', data, true)
}

currencies.methodValidator = {}
currencies.methodValidator.add = (fields, errors = {}) => {
    currencies.fieldValidator.currency(fields, errors)
    currencies.fieldValidator.subunit(fields, errors)

    return errors
}
currencies.methodValidator.edit = (fields, errors = {}) => {
    currencies.fieldValidator.currency(fields, errors)
    currencies.fieldValidator.subunit(fields, errors)

    return errors
}

currencies.fieldValidator = {}
currencies.fieldValidator.id = (fields, errors) => {
    if (!fields.id) {
        errors.id = 'Incorrect currency ID'
    }
    fields.id = parseInt(fields.id)
}
currencies.fieldValidator.currency = (fields, errors) => {
    if (!fields.currency) {
        errors.currency = 'Please insert currency code'
    }
    fields.currency = fields.currency.toUpperCase()

}

currencies.fieldValidator.subunit = (fields, errors) => {
    console.log(fields)
    if (!fields.subunit || typeof fields.subunit !== 'string') {
        errors.subunit = 'Please insert subunit'
        return
    }

    const err = 'Please insert a 10 to the power of x number (x >= 0)'
    if (!helpers.onlyDigitsRegex(fields.subunit)) {
        errors.subunit = err
        return
    }

    if (parseInt(fields.subunit) / (Math.pow(10, fields.subunit.length - 1)) !== 1) {
        errors.subunit = err
    }
}

export default currencies