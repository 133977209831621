const moment = require('moment-timezone')
moment.tz.setDefault('GMT')

const helpers = {}

helpers.forcedMerchantID = null
helpers.addForcedMerchantID = (filters) => {
    if (helpers.forcedMerchantID) {
        filters.merchant_id = helpers.forcedMerchantID
    }
}

Object.defineProperty(String.prototype, 'capitalize', {
    value: function() {
        return this.charAt(0).toUpperCase() + this.slice(1);
    },
    enumerable: false
})

helpers.roles = {
    A: 'admin',
    S: 'super',
    M: 'merchant',
    P: 'public'
}

helpers.preventDefault = (event) => {
    event.preventDefault();
}

helpers.sleep = (timeMillis) => {
    return new Promise((resolve, reject) => {
        setTimeout(() => resolve(), timeMillis)//returning the timeout, just for fun
    })
}

helpers.uniqueID = () => {
    return (Math.random() + 1).toString(36).substring(7)
}

helpers.getDateTime = (dateString, asString = true) => {
    const dateArray = (new Date(Date.parse(dateString))).toISOString().split('T')
    return dateArray[0] + ' ' + dateArray[1].split('.')[0]
}
helpers.getDate = (dateString, asString = true) => {
    const dateArray = (new Date(Date.parse(dateString))).toISOString().split('T')
    return dateArray[0]
}
helpers.getTime = (dateString, asString = true) => {
    const dateArray = (new Date(Date.parse(dateString))).toISOString().split('T')
    return dateArray[1].split('.')[0]
}

helpers.UUIDv4Transform = (str) => {
    if (str.length === 32) return (str.slice(0,8)+"-"+str.slice(8,12)+"-"+str.slice(12,16)+"-"+str.slice(16,20)+"-"+str.slice(20))
    return str.replace(/-/g, '')
}

helpers.getFormEventData = (event) => {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name

    return [target, value, name]
}

helpers.onlyDigitsRegex = (string) => {
    return /^\d+$/.test(string)
}
helpers.testDecimal = (string) => {
    return /^\d+(\.(\d+))?$/.test(string)
}
helpers.noTagRegex = /(<([^>]+)>)/ig
helpers.isValidHttpUrl = (string) => {
    let url;
    try {
        url = new URL(string);
    } catch (_) {
        return false;
    }
    return url.protocol === "http:" || url.protocol === "https:";
}

helpers.copyTextToClipboard = (text) => {
    return navigator.clipboard.writeText(text);
}

helpers.invertObject = (object) => {
    const inverted = {}
    Object.entries(object).map(([ key]) => {
        inverted[object[key]] = key
    })

    return inverted
}

helpers.objectToQueryString = (data) => {
    return Object.entries(data).map(pair => pair.map(encodeURIComponent).join('=')).join('&')
}

helpers.cutLongString = (str, maxLength) => {
    return str.length > maxLength ? `${str.substring(0, maxLength)}...` : str
}

helpers.months = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
]

helpers.getSubunitAmountString = (amount, symbol, subunit) => {
    return `${symbol}${(amount / subunit).toFixed(2)}`
}

helpers.emailValidator = (fields, errors, fieldName = 'username') =>  {
    let emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if (typeof fields[fieldName] !== 'string' || !fields[fieldName] || fields[fieldName].length > 254 || !emailRegex.test(fields[fieldName])) {
        errors[fieldName] = 'Incorrect email address'
    }
    fields[fieldName] = fields[fieldName].trim()
    fields[fieldName] = fields[fieldName].toLowerCase()
}

const MIN_PASSWORD_LENGTH = 8
const MAX_PASSWORD_LENGTH = 20
helpers.passwordValidator = (fields, errors) => {
    if (!fields.password || typeof fields.password !== 'string') errors.password = 'Please insert a password'
    if (fields.password.length < MIN_PASSWORD_LENGTH || fields.password.length > MAX_PASSWORD_LENGTH) errors.password = `Password should be between ${MIN_PASSWORD_LENGTH} and ${MAX_PASSWORD_LENGTH} characters`
    if (fields.password.search(/.*\d/) < 0) errors.password = 'Password must contain at least one digit'
    if (fields.password.search(/.*[a-z]/) < 0) errors.password = 'Password must contain at least one lowercase letter'
    if (fields.password.search(/.*[A-Z]/) < 0) errors.password = 'Password must contain at least one uppercase letter'
    if (fields.password.search(/.*[!@#$%^&*() =+_-]/) < 0) errors.password = 'Password must contain at least one symbol in this list !@#$%^&*()=+_- or a space'
}

helpers.subunitAmount = (amount, subunit, precision = 2) => {
    amount = typeof amount === 'string' ? parseFloat(amount) : amount
    amount = amount / subunit
    return parseFloat(amount.toFixed(precision))
}

export default helpers