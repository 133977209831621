import * as React from 'react';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from '../Title';
import {useEffect, useState} from "react";
import paymethods from '../../models/paymethods'
import helpers from "../../helpers/common";
import useRootHook from "../../hooks/useRoot";
import {Check, Add, FormatListBulleted, Delete} from '@mui/icons-material';
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import {Link as RouterLink, useNavigate, useSearchParams} from "react-router-dom";
import Paper from "@mui/material/Paper";
import {Stack, Tooltip} from "@mui/material";
import BreadCrumbs from "../BreadCrumbs";
import IconButton from "@mui/material/IconButton";

export default function PayMethodIndex(props) {
    const [searchParams] = useSearchParams()

    const [filters, setFilters] = useState({})
    const [payMethodData, setPayMethodData] = useState([])
    const [refreshId, setRefreshId] = useState(0)
    const {setShowLoader, notify} = useRootHook()
    const navigate = useNavigate()

    useEffect(() => {
        setShowLoader(true)
        paymethods.index(filters)
            .then((data) => {
                data && setPayMethodData(data)
                setShowLoader(false)
            }).catch(() => setShowLoader(false))
    }, [filters, refreshId])

    const handleRefresh = () => {
        setRefreshId((prevId) => {
            return prevId + 1
        });
        return true;
    }

    const handleSearch = (event) => {
        const data = new FormData(event.currentTarget)
        navigate('?' + new URLSearchParams(Object.fromEntries(data.entries())).toString())
    }

    const handleDelete = (method) => {
        if (!window.confirm('Are you sure you want to proceed?')) return
        setShowLoader(true)
        paymethods.delete(method.id)
            .then((result) => {
                result && handleRefresh() && notify('Method deleted')
                setShowLoader(false)
            }).catch(() => setShowLoader(false))
    }

    return <React.Fragment>
        <Grid item xs={12}>
            <BreadCrumbs elements={[['Dashboard', '/'], ['Paymethods', '/paymethods']]}/>
        </Grid>
        <Grid item xs={12}>
            <Stack spacing={2} direction="row">
                <Button to="/paymethods/add" component={RouterLink} variant="contained" startIcon={<Add />}>
                    Add Paymethod
                </Button>
            </Stack>
        </Grid>
        <Grid item xs={12}>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                <Title>Payment Methods</Title>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>System</TableCell>
                            <TableCell>Enabled</TableCell>
                            <TableCell>Date</TableCell>
                            <TableCell align="right"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {payMethodData.map((row) => (
                            <TableRow key={`pm-${row.id}`}>
                                <TableCell>
                                    <Link color="primary" to={`edit?id=${row.id}`} component={RouterLink}>
                                        {`${row.name}`}
                                    </Link>
                                </TableCell>
                                <TableCell>{row.paysystem.name}</TableCell>
                                <TableCell>{row.enabled ? <Check fontSize="small" /> : ''}</TableCell>
                                <TableCell>{helpers.getDate(row.created)}</TableCell>
                                <TableCell align="right">
                                    <Tooltip title="View transactions">
                                        <IconButton to={`/transactions?paymethod_id=${row.id}`} component={RouterLink}>
                                            <FormatListBulleted fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Delete paymethod">
                                        <IconButton onClick={() => handleDelete(row)}>
                                            <Delete fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Paper>
        </Grid>
    </React.Fragment>
}

