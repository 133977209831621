import helpers from '../helpers/common';
import request from '../helpers/request';

const paymethods = {};

paymethods.FORM_FIELDS = ['id', 'paysystem_id', 'name', 'enabled', 'available', 'min_amount', 'max_amount', 'max_day_total'];
paymethods.filterFormFields = (customerData, mapperFields) => {
    const allFields = paymethods.FORM_FIELDS.concat(mapperFields);
    return Object.keys(customerData)
        .filter(key => allFields.includes(key))
        .reduce((obj, key) => {
            obj[key] = customerData[key];
            return obj;
        }, {});
};

paymethods.index = async () => {
    return request.get('/paymethods', {}, true);
};

paymethods.get = async (id) => {
    return request.get('/paymethods/get', {id}, true);
};
paymethods.getBalance = async (id) => {
    return request.get('/paymethods/provider-balance', {id}, true);
};

paymethods.getBalanceTransactions = async (id) => {
    return request.get('/paymethods/provider-balance-transactions', {id}, true);
};

paymethods.add = async (data) => {
    return request.post('/paymethods/add', data, true);
};

paymethods.edit = async (data) => {
    return request.put('/paymethods/edit', data, true);
};

paymethods.delete = async (id) => {
    return request.delete('/paymethods/delete', {id}, true);
};

paymethods.methodValidator = {};
paymethods.methodValidator.add = (fields, errors = {}) => {
    return errors;
};
paymethods.methodValidator.edit = (fields, errors = {}) => {

    return errors;
};

paymethods.fieldValidator = {};
paymethods.fieldValidator.id = (fields, errors) => {
    if (!fields.id) {
        errors.id = 'Incorrect currency ID';
    }
    fields.id = parseInt(fields.id);
};
paymethods.fieldValidator.currency = (fields, errors) => {
    if (!fields.currency) {
        errors.currency = 'Please insert currency code';
    }
    fields.currency = fields.currency.toUpperCase();

};

paymethods.fieldValidator.subunit = (fields, errors) => {
    if (!fields.subunit || typeof fields.subunit !== 'string') {
        errors.subunit = 'Please insert subunit';
        return;
    }

    const err = 'Please insert a 10 to the power of x number (x >= 0)';
    if (!helpers.onlyDigitsRegex(fields.subunit)) {
        errors.subunit = err;
        return;
    }

    if (parseInt(fields.subunit) / (Math.pow(10, fields.subunit.length - 1)) !== 1) {
        errors.subunit = err;
    }
};

export default paymethods;