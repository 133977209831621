import * as React from 'react';
import ReactDOM from 'react-dom';
import CssBaseline from '@mui/material/CssBaseline';
import {ThemeProvider} from '@mui/material/styles';
import {BrowserRouter} from 'react-router-dom'
import {RootProvider} from "./hooks/useRoot";
import App from './App';
import theme from './theme';

ReactDOM.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <BrowserRouter>
                <RootProvider>
                    <App />
                </RootProvider>
            </BrowserRouter>
        </ThemeProvider>
    </React.StrictMode>,
    document.querySelector('#root'),
);