import helpers from '../helpers/common';

const callbacks = {};

callbacks.statusToEnum = {
    'pending': '1',
    'completed': '2',
    'error': '3'
};
callbacks.enumToStatus = helpers.invertObject(callbacks.statusToEnum);
export default callbacks;