import {Tab, Tabs} from "@mui/material";
import Box from "@mui/material/Box";
import {useSearchParams} from "react-router-dom";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import * as React from "react";
import BreadCrumbs from "../BreadCrumbs";
import {useEffect, useState} from "react";
import useRootHook from "../../hooks/useRoot";
import helpers from "../../helpers/common";
import projects from "../../models/projects";
import PropTypes from "prop-types";
import ProjectEditMain from "./ProjectEditMain";
import ProjectEditMethods from "./ProjectEditMethods";
import ProjectEditAPI from "./ProjectEditAPI";
import {Webhook as WebhookIcon, Edit as EditIcon, Payments as PaymentsIcon} from '@mui/icons-material';

export default function ProjectEdit() {
    const [searchParams, setSearchParams] = useSearchParams()
    const [projectData, setProjectData] = useState(null)
    const [tabIndex, setTabIndex] = React.useState(0)
    const [refreshId, setRefreshId] = useState(0)
    const {setShowLoader, userData} = useRootHook()

    const handleChange = (event, newValue) => {
        setTabIndex(newValue);
    }
    const refresh = () => {
        setRefreshId(prevState => prevState + 1)
        return true
    }

    useEffect(() => {
        setShowLoader(true)
        projects.get(searchParams.get('uuid'))
            .then((data) => {
                setShowLoader(false)
                data && setProjectData(data)
            }).catch(() => setShowLoader(false))

    }, [refreshId])

    if (!projectData) return null

    return <React.Fragment>
        <Grid item xs={12}>
            <BreadCrumbs elements={[['Dashboard', '/'], ['Projects', '/projects'], ['Edit', `/projects/edit?uuid=${helpers.UUIDv4Transform(projectData.uuid)}`]]}/>
        </Grid>
        <Grid item xs={12}>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                <Box sx={{ width: '100%' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs variant="fullWidth" value={tabIndex} textColor="secondary" indicatorColor="secondary" onChange={handleChange} aria-label="basic tabs example">
                            {/*<Tab label="Statistics" icon={<BarChartIcon />} iconPosition="start" id='edit-tab-main' aria-controls='edit-tabpanel-statistics' />*/}
                            <Tab label="Edit Info"
                                 icon={<EditIcon />} iconPosition="top"
                                 id='edit-tab-0' tabIndex={3} aria-controls='edit-tabpanel-0'
                            />
                            <Tab label="Payment Methods" disabled={userData.role === helpers.roles.M}
                                 icon={<PaymentsIcon/>} iconPosition="top"
                                 id='edit-tab-1' tabIndex={1} aria-controls='edit-tabpanel-1'
                            />
                            <Tab label="API Settings"
                                 icon={<WebhookIcon />} iconPosition="top"
                                 id='edit-tab-2' tabIndex={2} aria-controls='edit-tabpanel-2'
                            />
                        </Tabs>
                    </Box>
                    {/*<TabPanel value={tabIndex} index={0}>
                        <Dashboard project={projectData} />
                    </TabPanel>*/}
                    <TabPanel value={tabIndex} index={0}>
                        <ProjectEditMain project={projectData} refresh={refresh} />
                    </TabPanel>
                    {userData.role !== helpers.roles.M && <TabPanel value={tabIndex} index={1}>
                        <ProjectEditMethods project={projectData} />
                    </TabPanel>}
                    <TabPanel value={tabIndex} index={2}>
                        <ProjectEditAPI project={projectData} refresh={refresh} />
                    </TabPanel>
                </Box>
            </Paper>
        </Grid>
    </React.Fragment>
}

function TabPanel(props) {
    const { children, value, index, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`edit-tabpanel-${index}`}
            aria-labelledby={`edit-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{pt: 5 }}>
                    {children}
                </Box>
            )}
        </div>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
}