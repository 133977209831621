import {useNavigate, useSearchParams} from "react-router-dom";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Title from "../Title";
import * as React from "react";
import BreadCrumbs from "../BreadCrumbs";
import {useEffect, useState} from "react";
import useRootHook from "../../hooks/useRoot";
import helpers from "../../helpers/common";
import admins from "../../models/admins";
import AdminForm from "./AdminForm";

export default function AdminEdit() {
    const [searchParams] = useSearchParams();
    const [adminData, setAdminData] = useState(null)

    const [fields, setFields] = useState({})
    const [errors, setErrors] = useState({});

    const {setShowLoader} = useRootHook();
    const navigate = useNavigate()

    useEffect(() => {
        setShowLoader(true)
        admins.get(searchParams.get('id'))
            .then((data) => {
                setShowLoader(false)
                data && setAdminData(data)
                data && setFields(data)
            }).catch(() => setShowLoader(false))

    }, [])

    const handleChange = (ev) => {
        const [target, value, name] = helpers.getFormEventData(ev);
        console.log(name, value)

        setErrors({...errors, [name]: undefined});

        setFields((prevState) => {
            let newFields = {...prevState};
            newFields[name] = value;

            return newFields
        })
    }

    const handleBlur = (ev) => {
        const [target, value, name] = helpers.getFormEventData(ev)

        const newErrors = {...errors}
        const newFields = {...fields}

        if (name === 'password' && !value) return

        typeof admins.fieldValidator[name] === 'function'
        && admins.fieldValidator[name](newFields, newErrors)

        setErrors(newErrors)
    }

    const handleSubmit = (ev) => {
        ev.preventDefault()
        const validationErrors = admins.methodValidator.edit(fields)
        setErrors(validationErrors)

        if (Object.keys(validationErrors).length !== 0) return

        setShowLoader(true)
        admins.edit(fields)
            .then(() => {
                setShowLoader(false)
                navigate('/admins')
            }).catch(() => setShowLoader(false))
    }

    if (!adminData) return null

    return <React.Fragment>
        <Grid item xs={12}>
            <BreadCrumbs elements={[['Dashboard', '/'], ['Admins', '/admins'], ['Edit', `/admins/edit/?id=${adminData.id}`]]}/>
        </Grid>
        <Grid item xs={12}>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                <Title>Admin Edit</Title>
                <AdminForm fields={fields} errors={errors} handleChange={handleChange}
                           handleBlur={handleBlur} handleSubmit={handleSubmit} />
            </Paper>
        </Grid>
    </React.Fragment>
}