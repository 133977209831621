import * as React from 'react';
import moment from 'moment'
import {LocalizationProvider} from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {useState} from "react";
import {Stack, TextField, ToggleButton, ToggleButtonGroup} from "@mui/material";
import PropTypes from 'prop-types'

const DATE_FORMAT = 'DD/MM/YYYY'

function StatsContainer(props) {
    const {fullPage, setFilters, filters} = props
    const [filterType, setFilterType] = useState('day')

    const handleSetInterval = (ev, type) => {
        const date = moment.utc()
        switch (type) {
            case 'day':
                setFilterType(type)
                setFilters(prevFilters => {
                    return {
                        ...prevFilters,
                        from: date.startOf('day').toISOString(),
                        to: date.endOf('day').toISOString()
                    }
                })
                break;
            case 'week':
                setFilterType(type)
                setFilters(prevFilters => {
                    const from = date.clone().subtract(1, 'week')
                    return {
                        ...prevFilters,
                        from: from.startOf('day').toISOString(),
                        to: date.endOf('day').toISOString()
                    }
                })
                break;
            case 'month':
                setFilterType(type)
                setFilters(prevFilters => {
                    const from = date.clone().subtract(1, 'month')
                    return {
                        ...prevFilters,
                        from: from.startOf('day').toISOString(),
                        to: date.endOf('day').toISOString()
                    }
                })
                break;
        }
    }

    return (
        <React.Fragment>
            <Stack spacing={2} sx={{mb: 2}} direction="row" justifyContent="flex-end">
                <ToggleButtonGroup
                    value={filterType}
                    size="small"
                    exclusive
                    onChange={handleSetInterval}
                    aria-label="Date interval"
                >
                    <ToggleButton value="day" aria-label="left aligned">
                        Day
                    </ToggleButton>
                    <ToggleButton value="week" aria-label="centered">
                        Week
                    </ToggleButton>
                    <ToggleButton value="month" aria-label="right aligned">
                        Month
                    </ToggleButton>
                </ToggleButtonGroup>
                {fullPage && <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                        label="From"
                        disableFuture={true}
                        value={moment.utc(filters.from)}
                        inputFormat={DATE_FORMAT}
                        onChange={(newValue) => {
                            setFilters(prevFilters => {
                                setFilterType(null)
                                let newFilters = {...prevFilters, from: newValue.startOf('day').toISOString()}
                                if (moment.utc(prevFilters.to).isBefore(newValue)) {
                                    newFilters.to = newValue.endOf('day').toISOString()
                                }
                                return newFilters
                            });
                        }}
                        renderInput={(params) => <TextField variant="standard" size="small" {...params} />}
                    />
                </LocalizationProvider>
                }
                {fullPage && <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                        label="To"
                        disableFuture={true}
                        value={moment.utc(filters.to)}
                        inputFormat={DATE_FORMAT}
                        onChange={(newValue) => {
                            setFilters(prevFilters => {
                                setFilterType(null)
                                let newFilters = {...prevFilters, to: newValue.endOf('day').toISOString()}
                                if (moment.utc(prevFilters.from).isAfter(newValue)) {
                                    newFilters.from = newValue.startOf('day').toISOString()
                                }
                                return newFilters
                            });
                        }}
                        renderInput={(params) => <TextField variant="standard" size="small" {...params} />}
                    />
                </LocalizationProvider>
                }
            </Stack>
            {props.children}
        </React.Fragment>
    );
}

StatsContainer.propTypes = {
    fullPage: PropTypes.bool,
    filters: PropTypes.object,
    setFilters: PropTypes.func
}

export default StatsContainer