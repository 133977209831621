import {useSearchParams} from "react-router-dom";
import useRootHook from "../hooks/useRoot";
import {useEffect, useState} from "react";
import payment from '../models/payment'
import {Typography} from "@mui/material";

export default function Payment () {
    const [searchParams] = useSearchParams()
    const {setShowLoader} = useRootHook()
    const [text, setText] = useState('Please wait...')

    useEffect(() => {
        setShowLoader(true)
        payment.getLink({
            customer: searchParams.get('c'),
            currency: searchParams.get('cr'),
            amount: searchParams.get('a'),
        }).then((urlData) => {
            setShowLoader(false)
            if (!urlData || !urlData.url) {
                setText(urlData.message || 'Error processing request')
            } else {
                window.location.href = urlData.url
            }
        }).catch(() => setShowLoader(false))
    }, [])

    return <Typography variant="h2" gutterBottom component="div">
        {text}
    </Typography>
}