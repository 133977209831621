import * as React from 'react';
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import {useNavigate, useSearchParams} from "react-router-dom";
import Title from "../Title";
import BreadCrumbs from "../BreadCrumbs";
import {useState} from "react";
import useRootHook from "../../hooks/useRoot";
import merchants from "../../models/merchants";
import helpers from "../../helpers/common";
import MerchantForm from "./MerchantForm";

export default function MerchantAdd(props) {
    const [searchParams] = useSearchParams();
    const {setShowLoader} = useRootHook()
    const navigate = useNavigate()
    const [errors, setErrors] = useState({});

    const defaultFields = searchParams.get('project_id') ? {project_id: parseInt(searchParams.get('project_id'))} : {}
    const [fields, setFields] = useState(defaultFields)

    const handleChange = (ev) => {
        const [target, value, name] = helpers.getFormEventData(ev);

        setErrors({...errors, [name]: undefined});

        setFields((prevState) => {
            let newFields = {...prevState};
            newFields[name] = value;

            return newFields
        })
    }

    const handleBlur = (ev) => {
        const [target, value, name] = helpers.getFormEventData(ev)

        const newErrors = {...errors}
        const newFields = {...fields}

        typeof merchants.fieldValidator[name] === 'function'
        && merchants.fieldValidator[name](newFields, newErrors)

        setErrors(newErrors)
    }

    const handleSubmit = (ev) => {
        ev.preventDefault()
        const validationErrors = merchants.methodValidator.add(fields)
        setErrors(validationErrors)

        if (Object.keys(validationErrors).length !== 0) return

        setShowLoader(true)
        merchants.add(fields)
            .then(() => {
                setShowLoader(false)
                navigate('/merchants')
            }).catch(() => setShowLoader(false))
    }

    return <React.Fragment>
        <Grid item xs={12}>
            <BreadCrumbs elements={[['Dashboard', '/'], ['Merchants', '/merchants'], ['Add', '/merchants/add']]}/>
        </Grid>
        <Grid item xs={12}>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                <Title>Add Merchant</Title>
                <MerchantForm fields={fields} errors={errors} handleChange={handleChange}
                              handleBlur={handleBlur} handleSubmit={handleSubmit} />
            </Paper>
        </Grid>
    </React.Fragment>
}