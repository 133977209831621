import request from '../helpers/request';
import helpers from '../helpers/common';

const customers = {};

customers.FORM_FIELDS = ['project_id', 'email', 'name', 'surname', 'phone', 'address', 'zip', 'city', 'country'];
customers.filterFormFields = (customerData) => {
    return Object.keys(customerData)
        .filter(key => customers.FORM_FIELDS.includes(key))
        .reduce((obj, key) => {
            obj[key] = customerData[key];
            return obj;
        }, {});
};

customers.getData = async (filters) => {
    helpers.addForcedMerchantID(filters);
    return request.get('/customers', filters, false);
};
customers.add = (data) => {
    return request.post('/customers/add', data, true);
};
customers.edit = (data) => {
    return request.put('/customers/edit', data, true);
};
customers.get = async (uuid) => {
    return request.get('/customers/get', {uuid}, true);
};

customers.methodValidator = {};
customers.methodValidator.add = (fields, errors = {}) => {
    customers.fieldValidator.project_id(fields, errors);
    customers.fieldValidator.email(fields, errors);
    customers.fieldValidator.name(fields, errors);
    customers.fieldValidator.surname(fields, errors);
    customers.fieldValidator.phone(fields, errors);
    customers.fieldValidator.address(fields, errors);
    customers.fieldValidator.city(fields, errors);
    customers.fieldValidator.country(fields, errors);
    customers.fieldValidator.zip(fields, errors);

    return errors;
};
customers.methodValidator.edit = (fields, errors = {}) => {//todo check
    customers.fieldValidator.project_id(fields, errors);
    customers.fieldValidator.email(fields, errors);
    customers.fieldValidator.name(fields, errors);
    customers.fieldValidator.surname(fields, errors);
    customers.fieldValidator.phone(fields, errors);
    customers.fieldValidator.address(fields, errors);
    customers.fieldValidator.city(fields, errors);
    customers.fieldValidator.country(fields, errors);
    customers.fieldValidator.zip(fields, errors);

    return errors;
};


customers.fieldValidator = {};
customers.fieldValidator.project_id = (fields, errors) => {
    if (!fields.project_id) {
        return errors.project_id = 'Please select a project';
    }
};
customers.fieldValidator.email = (fields, errors) => {
    let email = fields.email?.trim();
    if (!email) {
        errors.email = 'Please insert email';
        return;
    }
    helpers.emailValidator(fields, errors, 'email');
};
customers.fieldValidator.name = (fields, errors) => {
    let name = fields.name?.trim();
    if (typeof name !== 'string' || !name) {
        errors.name = 'Please insert name';
        return;
    }
    if (helpers.noTagRegex.test(name)) {
        errors.name = 'Prohibited characters';
        return;
    }
    if (name.length > 50) {
        errors.name = 'Name should be max 50 characters long';
    }
};

customers.fieldValidator.surname = (fields, errors) => {
    let surname = fields.surname?.trim();
    if (typeof surname !== 'string' || !surname) {
        errors.surname = 'Please insert surname';
        return;
    }
    if (helpers.noTagRegex.test(surname)) {
        errors.surname = 'Prohibited characters';
        return;
    }
    if (surname.length > 50) {
        errors.surname = 'Surname should be max 50 characters long';
    }
};

customers.fieldValidator.phone = (fields, errors) => {
    if (helpers.noTagRegex.test(fields.phone)) {
        errors.name = 'Prohibited characters';
        return;
    }
    if (fields.phone && !/^[0-9+]{5,16}$/.test(fields.phone)) {
        errors.phone = 'Incorrect phone, only + sign and digits allowed';
    }
    fields.phone = fields.phone.trim();
};
customers.fieldValidator.address = (fields, errors) => {
    if (helpers.noTagRegex.test(fields.address)) {
        errors.name = 'Prohibited characters';
        return;
    }
    if (fields.address && fields.address.length < 5) {
        errors.address = 'Please insert a valid address';
    }
};
customers.fieldValidator.zip = (fields, errors) => {
    if (helpers.noTagRegex.test(fields.zip)) {
        errors.name = 'Prohibited characters';
        return;
    }
};
customers.fieldValidator.city = (fields, errors) => {
    if (helpers.noTagRegex.test(fields.city)) {
        errors.name = 'Prohibited characters';
        return;
    }
};
customers.fieldValidator.country = (fields, errors) => {
    if (helpers.noTagRegex.test(fields.country)) {
        errors.name = 'Prohibited characters';
        return;
    }
    if (!fields.country || fields.country.length !== 2) {
        errors.country = 'Please select a country';
    }
};


export default customers;