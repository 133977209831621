import React from 'react';
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

const ConfirmDialog = (props) => {
    const { title, children, confirmOpen, setConfirmOpen, onConfirm } = props;
    return (
        <Dialog
            open={confirmOpen}
            onClose={() => setConfirmOpen(false)}
        >
            <DialogTitle id="confirm-dialog">{title}</DialogTitle>
            <DialogContent>{children}</DialogContent>
            <DialogActions>
                <Button
                    variant="outlined"
                    onClick={() => setConfirmOpen(false)}
                >
                    No
                </Button>
                <Button
                    variant="contained"
                    onClick={() => {
                        setConfirmOpen(false)
                        onConfirm()
                    }}
                >
                    Yes
                </Button>
            </DialogActions>
        </Dialog>
    );
};
export default ConfirmDialog;