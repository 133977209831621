import * as React from 'react';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Title from './Title';
import helpers from "../helpers/common";
import {Link as RouterLink} from "react-router-dom";

export default function DayTotal({dayTotal, defaultCurrency}) {
    const date = new Date()

    if (!dayTotal) return null

    return (
        <React.Fragment>
            <Title>Today's Total</Title>
            <Typography component="p" variant="h4">
                {`${defaultCurrency.symbol}${(dayTotal.amount).toFixed(1)}`}
            </Typography>
            <Typography color="text.secondary" sx={{ flex: 1 }}>
                on {`${date.getDate()} ${helpers.months[date.getMonth()]}, ${date.getFullYear()}`}
            </Typography>
            <div>
                <Link color="primary" to="/transactions" component={RouterLink}>
                    View transactions
                </Link>
            </div>
        </React.Fragment>
    );
}