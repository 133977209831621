import * as React from 'react';
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import {useNavigate} from "react-router-dom";
import Title from "../Title";
import BreadCrumbs from "../BreadCrumbs";
import {useState} from "react";
import useRootHook from "../../hooks/useRoot";
import helpers from "../../helpers/common";
import AdminForm from "./AdminForm";
import admins from "../../models/admins";

export default function AdminAdd(props) {
    const {setShowLoader} = useRootHook()
    const navigate = useNavigate()
    const [errors, setErrors] = useState({});
    const [fields, setFields] = useState({super: false})

    const handleChange = (ev) => {
        const [target, value, name] = helpers.getFormEventData(ev);

        console.log(name, value)

        setErrors({...errors, [name]: undefined});

        setFields((prevState) => {
            let newFields = {...prevState};
            newFields[name] = value;

            return newFields
        })
    }

    const handleBlur = (ev) => {
        const [target, value, name] = helpers.getFormEventData(ev)

        const newErrors = {...errors}
        const newFields = {...fields}

        typeof admins.fieldValidator[name] === 'function'
        && admins.fieldValidator[name](newFields, newErrors)

        console.log(newErrors)
        setErrors(newErrors)
    }

    const handleSubmit = (ev) => {
        ev.preventDefault()
        const validationErrors = admins.methodValidator.add(fields)
        setErrors(validationErrors)

        if (Object.keys(validationErrors).length !== 0) return

        setShowLoader(true)
        console.log(fields)
        admins.add(fields)
            .then(() => {
                setShowLoader(false)
                navigate('/admins')
            }).catch(() => setShowLoader(false))
    }

    return <React.Fragment>
        <Grid item xs={12}>
            <BreadCrumbs elements={[['Dashboard', '/'], ['Admins', '/admins'], ['Add', '/admins/add']]}/>
        </Grid>
        <Grid item xs={12}>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                <Title>Admin Add</Title>
                <AdminForm fields={fields} errors={errors} handleChange={handleChange}
                           handleBlur={handleBlur} handleSubmit={handleSubmit} />
            </Paper>
        </Grid>
    </React.Fragment>
}