import {InputLabel, Stack, TextField} from "@mui/material";
import Button from "@mui/material/Button";
import {RestartAlt, Search} from "@mui/icons-material";
import * as React from "react";
import {useSearchParams} from "react-router-dom";
import Grid from "@mui/material/Grid";
import {Fragment, useEffect, useState} from "react";
import helpers from "../../helpers/common";
import moment from 'moment'
import {LocalizationProvider} from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DateTimePicker} from '@mui/x-date-pickers/DateTimePicker';

const DATE_FORMAT = 'DD/MM/YYYY HH:mm'
export default function CustomersFilter() {
    const [searchParams, setSearchParams] = useSearchParams()
    const [fields, setFields] = useState({})
    useEffect(() => {
        setFields({
            email: searchParams.get('email') || '',
            name: searchParams.get('name') || '',
            surname: searchParams.get('surname') || '',
            country: searchParams.get('country') || '',
            created_from: searchParams.get('created_from') || undefined,
            created_to: searchParams.get('created_to') || undefined
        })
    }, [searchParams])

    const handleSearch = (ev) => {
        ev.preventDefault()
        Object.keys(fields).forEach((key) => {
            if (!fields[key]) return
            searchParams.set(key, fields[key].toString())
        })
        setSearchParams(searchParams)
    }

    const handleChange = (ev) => {
        const [target, value, name] = helpers.getFormEventData(ev);

        setFields((prevState) => {
            let newFields = {...prevState}
            newFields[name] = value

            return newFields
        })
    }
    return <Fragment>
        <Grid container spacing={2}
                     component="form"
                     onSubmit={handleSearch}
                     noValidate
                     autoComplete="off"
        >
            <Grid item xs={12} md={3}>
                <InputLabel>Email</InputLabel>
                <TextField
                    value={fields.email || ''}
                    id="email"
                    name="email"
                    variant="standard"
                    size="small"
                    fullWidth={true}
                    onChange={handleChange}
                />
            </Grid>
            <Grid item xs={12} md={3}>
                <InputLabel>Name</InputLabel>
                <TextField
                    value={fields.name || ''}
                    id="name"
                    name="name"
                    variant="standard"
                    size="small"
                    fullWidth={true}
                    onChange={handleChange}
                />
            </Grid>
            <Grid item xs={12} md={3}>
                <InputLabel>Surname</InputLabel>
                <TextField
                    value={fields.surname || ''}
                    id="surname"
                    name="surname"
                    variant="standard"
                    size="small"
                    fullWidth={true}
                    onChange={handleChange}
                />
            </Grid>
            <Grid item xs={12} md={3}>
                <InputLabel>Country</InputLabel>
                <TextField
                    value={fields.country || ''}
                    id="country"
                    name="country"
                    placeholder="2 digits country code"
                    variant="standard"
                    size="small"
                    fullWidth={true}
                    onChange={handleChange}
                />
            </Grid>
            <Grid item xs={12} md={3}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DateTimePicker
                        label="Created from"
                        disableFuture={true}
                        value={moment.utc(fields.created_from)}
                        ampm={false}
                        inputFormat={DATE_FORMAT}
                        onChange={(newValue) => {
                            setFields((prevState) => {
                                let newFields = {...prevState}
                                newFields.created_from = newValue.set({second: 0}).toISOString()

                                return newFields
                            })
                        }}
                        renderInput={(params) => <TextField variant="standard" fullWidth={true} size="small" {...params} />}
                    />
                </LocalizationProvider>
            </Grid>
            <Grid item xs={12} md={3}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DateTimePicker
                        label="Created To"
                        value={moment.utc(fields.created_to)}
                        ampm={false}
                        inputFormat={DATE_FORMAT}
                        onChange={(newValue) => {
                            setFields((prevState) => {
                                let newFields = {...prevState}
                                newFields.created_to = newValue.set({second: 59}).toISOString()

                                return newFields
                            })
                        }}
                        renderInput={(params) => <TextField variant="standard" fullWidth={true} size="small" {...params} />}
                    />
                </LocalizationProvider>
            </Grid>
            <Grid item xs={12}>
                <Stack spacing={2} direction="row">
                    <Button type="submit" variant="outlined" startIcon={<Search />}>
                        Search
                    </Button>
                    <Button onClick={() => {setFields({}); setSearchParams({})}} variant="outlined" color="secondary" startIcon={<RestartAlt />}>
                        Reset
                    </Button>
                </Stack>
            </Grid>
        </Grid>
    </Fragment>
}