import * as React from 'react';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from '../Title';
import {useEffect, useState} from "react";
import projects from '../../models/projects'
import helpers from "../../helpers/common";
import useRootHook from "../../hooks/useRoot";
import {Add, Check, FormatListBulleted, People} from "@mui/icons-material";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import {Stack, Tooltip} from "@mui/material";
import BreadCrumbs from "../BreadCrumbs";
import {AclRouterLink} from "../ACL";
import {Link as RouterLink, useSearchParams} from "react-router-dom";
import IconButton from "@mui/material/IconButton";

export default function ProjectIndex() {
    const [searchParams] = useSearchParams()
    const [projectsData, setProjectsData] = useState(null)
    const {setShowLoader, userData} = useRootHook()

    useEffect(() => {
        setShowLoader(true)
        projects.index(searchParams ? Object.fromEntries(searchParams) : {})
            .then((data) => {
                data && setProjectsData(data)
                setShowLoader(false)
            }).catch(() => setShowLoader(false))
    }, [])

    if (!projectsData) return null

    return <React.Fragment>
        <Grid item xs={12}>
            <BreadCrumbs elements={[['Dashboard', '/'], ['Projects', '/projects']]}/>
        </Grid>
        <Grid item xs={12}>
            <Stack spacing={2} direction="row">
                <Button to="/projects/add" component={AclRouterLink} variant="contained" startIcon={<Add />}>
                    Add Project
                </Button>
            </Stack>
        </Grid>
        <Grid item xs={12}>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', overflowX: 'auto'}}>
                <Title>Projects</Title>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Name</TableCell>
                            {userData.role !== helpers.roles.M && <TableCell>Merchant</TableCell>}
                            <TableCell>URL</TableCell>
                            <TableCell>Enabled</TableCell>
                            <TableCell>Date</TableCell>
                            <TableCell align="right"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {projectsData.projects.map((row) => (
                            <TableRow key={`pro-${row.id}`}>
                                <TableCell>
                                    {row.id}
                                </TableCell>
                                <TableCell>
                                    <Link to={`/projects/edit?uuid=${helpers.UUIDv4Transform(row.uuid)}`} component={AclRouterLink}>
                                        {row.name}
                                    </Link>
                                </TableCell>
                                {userData.role !== helpers.roles.M && <TableCell>{row.merchant.name}</TableCell>}
                                <TableCell>
                                    {row.url}
                                </TableCell>
                                <TableCell>{!!row.enabled ? <Check fontSize="small" /> : ''}</TableCell>
                                <TableCell>{helpers.getDate(row.created)}</TableCell>
                                <TableCell align="right">
                                    <Tooltip title="View transactions">
                                        <IconButton to={`/transactions?project_id=${row.id}`} component={RouterLink}>
                                            <FormatListBulleted fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="View customers">
                                        <IconButton to={`/customers?project_id=${row.id}`} component={RouterLink}>
                                            <People fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Paper>
        </Grid>
    </React.Fragment>
}

