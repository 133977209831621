import Grid from "@mui/material/Grid";
import {FormHelperText, InputLabel, TextField} from "@mui/material";
import Button from "@mui/material/Button";
import {Add} from "@mui/icons-material";
import * as React from "react";

export default function CurrencyForm ({fields, errors, handleChange, handleBlur, handleSubmit}) {
    return <React.Fragment>
        <Grid container spacing={2}
              component="form"
              onSubmit={handleSubmit}
              autoComplete="off"
        >
            <Grid item xs={4} md={4}>
                <InputLabel>Currency Code</InputLabel>
                <TextField
                    error={!!errors.currency}
                    value={fields.currency || ""}
                    name="currency"
                    id="currency"
                    required={true}
                    variant="standard"
                    fullWidth={true}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                {errors.currency && <FormHelperText>{errors.currency}</FormHelperText>}
            </Grid>
            <Grid item xs={4} md={4}>
                <InputLabel>Subunit</InputLabel>
                <TextField
                    error={!!errors.subunit}
                    value={fields.subunit || ""}
                    name="subunit"
                    type="number"
                    id="subunit"
                    required={true}
                    variant="standard"
                    fullWidth={true}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                {errors.subunit && <FormHelperText>{errors.subunit}</FormHelperText>}
            </Grid>
            <Grid item xs={4} md={4}>
                <InputLabel>Symbol</InputLabel>
                <TextField
                    error={!!errors.symbol}
                    value={fields.symbol || ""}
                    name="symbol"
                    type="text"
                    id="symbol"
                    required={true}
                    variant="standard"
                    fullWidth={true}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                {errors.symbol && <FormHelperText>{errors.symbol}</FormHelperText>}
            </Grid>
            <Grid container item spacing={3} sx={{mt: 2}}>
                <Grid item xs={6}>
                    <Button type="submit" onClick={handleSubmit} variant="outlined" startIcon={<Add />} >
                        Submit
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    </React.Fragment>
}
