import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleIcon from '@mui/icons-material/People';
import BarChartIcon from '@mui/icons-material/BarChart';
import NextWeekIcon from '@mui/icons-material/NextWeek';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import BusinessIcon from '@mui/icons-material/Business';
import PaymentsIcon from '@mui/icons-material/Payments';
import PaidIcon from '@mui/icons-material/Paid';
import {FormatListBulleted} from '@mui/icons-material';
import EuroIcon from '@mui/icons-material/Euro';
import StorageIcon from '@mui/icons-material/Storage';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';

import {Link as RouterLink, useLocation} from 'react-router-dom'
import {AclRouterLink} from "./ACL";
import helpers from "../helpers/common";
import {useEffect, useState} from "react";
import projects from "../models/projects";
import useRootHook from "../hooks/useRoot";

export const MainListItems = () => {
    const {forcedMerchantID} = useRootHook()
    const location = useLocation()

    return <React.Fragment>
        <ListItemButton to="/" underline="none" selected={location.pathname === '/'} component={RouterLink}>
            <ListItemIcon>
                <DashboardIcon/>
            </ListItemIcon>
            <ListItemText primary="Dashboard"/>
        </ListItemButton>
        <ListItemButton to="/merchants" underline="none" disabled={!!forcedMerchantID} selected={location.pathname === '/merchants'} component={AclRouterLink}>
            <ListItemIcon>
                <BusinessIcon/>
            </ListItemIcon>
            <ListItemText primary="Merchants"/>
        </ListItemButton>
        <ListItemButton to="/projects" underline="none" selected={location.pathname === '/projects'} component={AclRouterLink}>
            <ListItemIcon>
                <BusinessCenterIcon/>
            </ListItemIcon>
            <ListItemText primary="Projects"/>
        </ListItemButton>
        <ListItemButton to="/customers" underline="none" selected={location.pathname === '/customers'} component={AclRouterLink}>
            <ListItemIcon>
                <PeopleIcon/>
            </ListItemIcon>
            <ListItemText primary="Customers"/>
        </ListItemButton>
        <ListItemButton to="/transactions" underline="none" selected={location.pathname === '/transactions'} component={AclRouterLink}>
            <ListItemIcon>
                <FormatListBulleted/>
            </ListItemIcon>
            <ListItemText primary="Transactions"/>
        </ListItemButton>
        <ListItemButton to="/paymethods" underline="none" color="inherit" disabled={!!forcedMerchantID} selected={location.pathname === '/paymethods'} component={AclRouterLink}>
            <ListItemIcon>
                <PaymentsIcon/>
            </ListItemIcon>
            <ListItemText primary="Methods"/>
        </ListItemButton>
        <ListItemButton to="/currencies" underline="none"
                        forceRoles={[helpers.roles.A, helpers.roles.S]} disabled={!!forcedMerchantID} selected={location.pathname === '/currencies'} component={AclRouterLink}>
            <ListItemIcon>
                <EuroIcon/>
            </ListItemIcon>
            <ListItemText primary="Currencies"/>
        </ListItemButton>
        <ListItemButton to="/servers" underline="none" disabled={!!forcedMerchantID} selected={location.pathname === '/servers'} component={AclRouterLink}>
            <ListItemIcon>
                <BarChartIcon/>
            </ListItemIcon>
            <ListItemText primary="Servers"/>
        </ListItemButton>
        <ListItemButton to="/payouts" underline="none" selected={location.pathname === '/payouts'} component={AclRouterLink}>
            <ListItemIcon>
                <PaidIcon/>
            </ListItemIcon>
            <ListItemText primary="Payouts"/>
        </ListItemButton>
        <ListItemButton to="/stats" underline="none" selected={location.pathname === '/stats'} component={AclRouterLink}>
            <ListItemIcon>
                <StorageIcon/>
            </ListItemIcon>
            <ListItemText primary="Stats"/>
        </ListItemButton>
        <ListItemButton to="/admins" underline="none" disabled={!!forcedMerchantID} selected={location.pathname === '/admins'} component={AclRouterLink}>
            <ListItemIcon>
                <AdminPanelSettingsIcon/>
            </ListItemIcon>
            <ListItemText primary="Admins"/>
        </ListItemButton>
    </React.Fragment>
}

export const SecondaryListItems = () => {
    const [projectsData, setProjectsData] = useState([])
    useEffect(() => {
        projects.index().then((data) => {
            data && data.projects && setProjectsData(data.projects)
        })
    }, [])

    if (!projectsData) return null

    return <React.Fragment>
        <ListSubheader component="div" inset>
            Projects
        </ListSubheader>
        {projectsData.map((project, key) => {
            if (key > 4) return null
            return <ListItemButton key={project.uuid} underline="none" color="inherit" to={`/projects?${project.uuid}`} component={AclRouterLink}>
                <ListItemIcon>
                    <NextWeekIcon/>
                </ListItemIcon>
                <ListItemText primary={`${project.name}`}/>
            </ListItemButton>
        })}
    </React.Fragment>
}