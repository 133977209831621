import {useNavigate, useSearchParams} from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Title from '../Title';
import * as React from 'react';
import BreadCrumbs from '../BreadCrumbs';
import {useEffect, useState} from 'react';
import useRootHook from '../../hooks/useRoot';
import helpers from '../../helpers/common';
import PayMethodForm from './PayMethodForm';
import paysystems from '../../models/paysystems';
import paymethods from '../../models/paymethods';

export default function PayMethodEdit() {
    const [searchParams] = useSearchParams();
    const [payMethodData, setPayMethodData] = useState(null);
    const [paySystemsData, setPaySystemsData] = useState([]);
    const [mapper, setMapper] = useState({
        keyToFieldMapper: {},
        fieldToKeyMapper: {}
    });

    const [fields, setFields] = useState({});
    const [errors, setErrors] = useState({});

    const {setShowLoader} = useRootHook();
    const navigate = useNavigate();

    useEffect(() => {
        if (payMethodData) return;
        setShowLoader(true);

        const promises = [
            paysystems.getData(),
            paymethods.get(searchParams.get('id')),
            //paymethods.getBalance(searchParams.get('id')),
            //paymethods.getBalanceTransactions(searchParams.get('id'))
        ];

        Promise.all(promises)
            .then(([psysdata, pmdata, providerBalance, providerBalanceTransactions]) => {
                setShowLoader(false);
                psysdata && setPaySystemsData(psysdata);
                pmdata && setPayMethodData(pmdata);
                providerBalance && console.log(providerBalance);
                providerBalanceTransactions && console.log(providerBalanceTransactions);
            }).catch(() => setShowLoader(false));

    }, []);

    useEffect(() => {
        if (!paySystemsData || paySystemsData.length === 0 || !payMethodData) return;
        const newMapper = setMapperByRecordID(payMethodData.paysystem_id);
        const fieldsData = paymethods.filterFormFields(payMethodData, Object.keys(newMapper.fieldToKeyMapper));
        setFields((prevFields) => {
            return {...prevFields, ...fieldsData, paysystem_id: payMethodData.paysystem_id, id: payMethodData.id};
        });
    }, [payMethodData, paySystemsData]);

    const setMapperByRecordID = (id) => {
        const record = paySystemsData.find(system => system.id === id);
        if (!record || !record.paymethod_field_mapper) return;
        const recordMapper = record.paymethod_field_mapper;
        const newMapper = {keyToFieldMapper: recordMapper, fieldToKeyMapper: helpers.invertObject(recordMapper)};
        setMapper(newMapper);
        setFields((prevFields) => {
            return {...payMethodData, ...prevFields};
        });
        return newMapper;
    };

    const handleChange = (ev) => {
        const [target, value, name] = helpers.getFormEventData(ev);

        if (name === 'paysystem_id') setMapperByRecordID(parseInt(value));

        setErrors({...errors, [name]: undefined});

        setFields((prevState) => {
            let newFields = {...prevState};
            newFields[name] = value;

            return newFields;
        });
    };

    const handleBlur = (ev) => {
        const [target, value, name] = helpers.getFormEventData(ev);

        if (!paymethods.FORM_FIELDS.concat(Object.keys(mapper.fieldToKeyMapper)).includes(name)) return;

        const newErrors = {...errors};
        const newFields = {...fields};

        typeof paymethods.fieldValidator[name] === 'function'
        && paymethods.fieldValidator[name](newFields, newErrors);

        console.log(newErrors);
        setErrors(newErrors);
    };

    const handleSubmit = (ev) => {
        ev.preventDefault();
        let filteredFields = paymethods.filterFormFields(fields, Object.keys(mapper.fieldToKeyMapper));
        const validationErrors = paymethods.methodValidator.edit(filteredFields);
        setErrors(validationErrors);

        if (Object.keys(validationErrors).length !== 0) return;

        setShowLoader(true);
        paymethods.edit(filteredFields)
            .then(() => {
                setShowLoader(false);
                navigate('/paymethods');
            }).catch(() => setShowLoader(false));
    };

    if (!payMethodData || !paySystemsData || paySystemsData.length === 0) return null;

    return <React.Fragment>
        <Grid item xs={12}>
            <BreadCrumbs
                elements={[['Dashboard', '/'], ['Methods', '/paymethods'], ['Edit', `/paymethods/edit?${payMethodData.id}`]]}/>
        </Grid>
        <Grid item xs={12}>
            <Paper sx={{p: 2, display: 'flex', flexDirection: 'column'}}>
                <Title>Edit Paymethod</Title>
                <PayMethodForm paySystemsData={paySystemsData} mapper={mapper} fields={fields} errors={errors}
                               handleChange={handleChange}
                               handleBlur={handleBlur} handleSubmit={handleSubmit} project_id={fields.project_id}/>
            </Paper>
        </Grid>
    </React.Fragment>;
}