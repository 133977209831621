import {useNavigate, useSearchParams} from "react-router-dom";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Title from "../Title";
import * as React from "react";
import BreadCrumbs from "../BreadCrumbs";
import {useEffect, useState} from "react";
import useRootHook from "../../hooks/useRoot";
import helpers from "../../helpers/common";
import projects from "../../models/projects";
import customers from "../../models/customers";
import CustomerForm from "./CustomerForm";

export default function CustomerEdit() {
    const [searchParams] = useSearchParams();
    const [customerData, setCustomerData] = useState(null)
    const [projectsData, setProjectsData] = useState([])

    const [fields, setFields] = useState({})
    const [errors, setErrors] = useState({});

    const {setShowLoader} = useRootHook();
    const navigate = useNavigate()

    useEffect(() => {
        if (customerData) return
        setShowLoader(true)

        const promises = [
            projects.index({}),
            customers.get(searchParams.get('uuid'))
        ]

        Promise.all(promises)
            .then(([projects, customer]) => {
                setShowLoader(false)
                projects && setProjectsData(projects)

                customer && setCustomerData(customer)

                const fieldsData = customers.filterFormFields(customer)
                fieldsData.uuid = helpers.UUIDv4Transform(customer.uuid)
                customer && setFields(fieldsData)
            }).catch(() => setShowLoader(false))

    }, [])

    const handleChange = (ev) => {
        const [target, value, name] = helpers.getFormEventData(ev);
        console.log(name, value)

        setErrors({...errors, [name]: undefined});

        setFields((prevState) => {
            let newFields = {...prevState};
            newFields[name] = value;

            return newFields
        })
    }

    const handleBlur = (ev) => {
        const [target, value, name] = helpers.getFormEventData(ev)

        if (!customers.FORM_FIELDS.includes(name)) return

        const newErrors = {...errors}
        const newFields = {...fields}

        typeof customers.fieldValidator[name] === 'function'
        && customers.fieldValidator[name](newFields, newErrors)

        console.log(newErrors)
        setErrors(newErrors)
    }

    const handleSubmit = (ev) => {
        ev.preventDefault()
        const validationErrors = customers.methodValidator.edit(fields)
        setErrors(validationErrors)

        if (Object.keys(validationErrors).length !== 0) return

        setShowLoader(true)
        customers.edit(fields)
            .then(() => {
                setShowLoader(false)
                navigate('/customers')
            }).catch(() => setShowLoader(false))
    }

    if (!customerData || !projectsData || projectsData.length === 0) return null

    return <React.Fragment>
        <Grid item xs={12}>
            <BreadCrumbs elements={[['Dashboard', '/'], ['Customers', '/customers'], ['Edit', `/customers/edit/${helpers.UUIDv4Transform(customerData.uuid)}`]]}/>
        </Grid>
        <Grid item xs={12}>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                <Title>Edit Customer</Title>
                <CustomerForm projectsData={projectsData} fields={fields} errors={errors} handleChange={handleChange}
                              handleBlur={handleBlur} handleSubmit={handleSubmit} project_id={fields.project_id} />
            </Paper>
        </Grid>
    </React.Fragment>
}