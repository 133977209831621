import request from '../helpers/request'
import helpers from "../helpers/common";

const admins = {}

admins.init = async () => {
    return request.get('/init')
}

admins.getData = async () => {
    return request.get('/admins')
}

admins.get = async (id) => {
    return request.get('/admins/get', {id}, true)
}

admins.add = async (data) => {
    return request.post('/admins/add', {username: data.username, password: data.password, super: data.super}, true)
}

admins.edit = async (data) => {
    return request.put('/admins/edit', {id: data.id, username: data.username, password: data.password, super: data.super}, true)
}

admins.delete = async (id) => {
    return request.delete('/admins/delete', {id}, true)
}

admins.login = async (username, password) => {
    return request.post('/admins/login', {username, password}, true)
}

admins.logout = async () => {
    await request.post('/admins/logout', {}, true)
}

admins.methodValidator = {}
admins.methodValidator.add = (fields, errors = {}) => {
    admins.fieldValidator.username(fields, errors)
    admins.fieldValidator.password(fields, errors)
    return errors
}
admins.methodValidator.edit = (fields, errors = {}) => {
    admins.fieldValidator.username(fields, errors)
    fields.password && admins.fieldValidator.password(fields, errors)
    return errors
}

admins.fieldValidator = {}
admins.fieldValidator.username = (fields, errors) => {
    helpers.emailValidator(fields, errors, 'username')
}
admins.fieldValidator.password = (fields, errors) => {
    helpers.passwordValidator(fields, errors)
}

export default admins