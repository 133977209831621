import * as React from 'react';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from '../Title';
import {useEffect, useState} from "react";
import payouts from '../../models/payouts'
import helpers from "../../helpers/common";
import useRootHook from "../../hooks/useRoot";
import {Add, Payments} from "@mui/icons-material";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import {Link as RouterLink, useNavigate} from "react-router-dom";
import Paper from "@mui/material/Paper";
import {Stack} from "@mui/material";
import BreadCrumbs from "../BreadCrumbs";
import Pagination from "../Pagination";
import {AclRouterLink} from "../ACL";

export default function PayoutIndex() {
    const [payoutsData, setPayoutsData] = useState(null)
    const {setShowLoader, userData} = useRootHook()
    const navigate = useNavigate()

    useEffect(() => {
        setShowLoader(true)
        payouts.index({})
            .then((data) => {
                data && setPayoutsData(data)
                setShowLoader(false)
            }).catch(() => setShowLoader(false))
    }, [])

    if (!payoutsData) return null

    return <React.Fragment>
        <Grid item xs={12}>
            <BreadCrumbs elements={[['Dashboard', '/'], ['Payouts', '/payouts']]}/>
        </Grid>
        <Grid item xs={12}>
            <Stack spacing={2} direction="row">
                <Button to="/payouts/add" component={AclRouterLink} variant="contained" startIcon={<Add />}>
                    Add Payout
                </Button>
                <Button to="/payout-methods" component={AclRouterLink} variant="contained" startIcon={<Payments />}>
                    Payout Methods
                </Button>
            </Stack>
        </Grid>
        <Grid item xs={12}>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', overflowX: 'auto'}}>
                <Title>Payouts</Title>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            {userData.role !== helpers.roles.M && <TableCell>Merchant</TableCell>}
                            <TableCell>Method</TableCell>
                            <TableCell>Amount</TableCell>
                            <TableCell>Amount Net</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Added</TableCell>
                            <TableCell align="right">Modified</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {payoutsData.payouts.map((row) => (
                            <TableRow key={`payout-${row.id}`}>
                                <TableCell>
                                    {
                                        userData.role !== helpers.roles.M ?
                                        <Link to={`edit?id=${row.id}`} component={RouterLink}>
                                            {row.id}
                                        </Link>
                                        :
                                        row.id
                                    }

                                </TableCell>
                                {userData.role !== helpers.roles.M
                                    && <TableCell>{row['merchant.name']}</TableCell>
                                }
                                <TableCell>{row['payout_method.name']}</TableCell>
                                <TableCell>
                                    {helpers.subunitAmount(row.amount_total, userData.defaultCurrency.subunit)}
                                </TableCell>
                                <TableCell>
                                    {helpers.subunitAmount(row.amount, userData.defaultCurrency.subunit)}
                                </TableCell>
                                <TableCell>{row.status}</TableCell>
                                <TableCell>{helpers.getDateTime(row.created)}</TableCell>
                                <TableCell align="right">{helpers.getDateTime(row.modified)}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <Pagination url={'/payouts'} count={payoutsData.count}/>
            </Paper>
        </Grid>
    </React.Fragment>
}

