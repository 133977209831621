import * as React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import DayTotal from './DayTotal';
import TransactionIndex from './transactions/TransactionIndex';
import transactions from "../models/transactions";
import TotalCompletedChart from "./stats/TotalCompletedChart";
import {useEffect, useState} from "react";

const InfoCell = ({title, value}) => {
    return <Paper elevation={5} sx={{p: 1, backgroundColor: "#efefef"}}>
        <Typography variant="subtitle1" align="center">{title}</Typography>
        <Typography variant="h6" align="center">{value}</Typography>
    </Paper>
}

export default function Dashboard() {
    const [totals, setTotals] = useState(null)

    useEffect(() => {
        transactions.getTotals()
            .then((totals) => {
                totals && setTotals(totals)
            })
    }, [])

    if (!totals) return null

    const symbol = totals.defaultCurrency.symbol

    return <React.Fragment>
        <Grid item xs={12} md={8} lg={9}>
            <Paper
                sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 240,
                }}
            >
                <TotalCompletedChart />
            </Paper>
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
            <Paper
                sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 240,
                }}
            >
                <DayTotal {...totals}/>
            </Paper>
        </Grid>
        <Grid item xs={12}>
            <Paper elevation={1} sx={{ p: 3}}>
                <Grid container spacing={3}>
                    <Grid item xs={6} sm={3}>
                        <InfoCell title="Total" value={`${(totals.allTimeTotal.amount).toFixed(1)}${symbol}`} />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <InfoCell title="Commissions" value={`${(totals.allTimeCommissions.amount).toFixed(1)}${symbol}`} />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <InfoCell title="Payouts" value={`${(totals.allTimePayouts.amount).toFixed(1)}${symbol}`} />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <InfoCell title="Refunds / Disputed" value={`${(totals.allTimeRefunds.amount).toFixed(1)}${symbol} / ${totals.allTimeDisputed.amount.toFixed(1)}${symbol}`} />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <InfoCell title="Dispute Cost" value={`${(totals.allTimeDisputeCost.amount).toFixed(1)}${symbol}`} />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <InfoCell title="Rolling / Hold" value={`${(totals.rollingReserve.amount).toFixed(1)}${symbol} / ${totals.holdAmount.amount.toFixed(1)}${symbol}`} />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <InfoCell title="Balance" value={`${(totals.totalBalance).toFixed(1)}${symbol}`} />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <InfoCell title="Available" value={`${(totals.availableBalance).toFixed(1)}${symbol}`} />
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
        <Grid item xs={12}>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', overflowX: 'auto'}}>
                <TransactionIndex fullPage={false} filters={{status: transactions.statusToEnum.completed}}/>
            </Paper>
        </Grid>
    </React.Fragment>
}