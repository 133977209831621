import merchants from "../models/merchants";

const request = {}

const defaultOptions = {
    credentials: 'same-origin',
    headers: {
        'Accept': 'application/json'
    }
};
const defaultUrl = '/iapi'

request.get = async (path, params = {}, displayError = false) => {
    const options = {...defaultOptions}
    options.method = 'GET'

    //const urlParams = Object.keys(params).length > 0 ? new URLSearchParams(params).toString() : ''
    const urlParams =  new URLSearchParams(params).toString()
    const url = `${defaultUrl}${path}?${urlParams}`

    return request.send(url, options, displayError)
}
request.post = async (path, data, displayError = false) => {
    const options = {...defaultOptions}
    options.method = 'POST'
    options.body = JSON.stringify(data)
    options.headers['Content-Type'] = 'application/json'

    const url = `${defaultUrl}${path}`

    return request.send(url, options, displayError)
}
request.patch = async (path, data, displayError = false) => {
    const options = {...defaultOptions}
    options.method = 'PATCH'
    options.body = JSON.stringify(data)
    options.headers['Content-Type'] = 'application/json'

    const url = `${defaultUrl}${path}`

    return request.send(url, options, displayError)
}
request.put = async (path, data, displayError = false) => {
    const options = {...defaultOptions}
    options.method = 'PUT'
    options.body = JSON.stringify(data)
    options.headers['Content-Type'] = 'application/json'

    const url = `${defaultUrl}${path}`

    return request.send(url, options, displayError)
}
request.delete = async (path, data, displayError = false) => {
    const options = {...defaultOptions}
    options.method = 'DELETE'
    options.body = JSON.stringify(data)
    options.headers['Content-Type'] = 'application/json'

    const url = `${defaultUrl}${path}`

    return request.send(url, options, displayError)
}

request.send = async (url, options, displayError) => {
    try {
       let resData = await fetch(url, options);

        let ok = resData.ok;
        resData = await resData.json();

        if (Array.isArray(resData.actions) && resData.actions.length > 0) {
            actionsWorker(resData.actions)
        }

        if (!ok) {
            displayError && alert(resData.message)
            return null
        }
        return resData
    } catch (err) {
        displayError && alert(err.message)
        return null
    }
}

const ACTION_REDIRECT = 'redirect'
const ACTION_LOGOUT = 'logout'
const ACTION_NOTIFY = 'notify'

const actionsWorker = (actions) => {
    actions.forEach((action) => {
        switch (action.type) {
            case ACTION_REDIRECT:
                window.location.href = action.to
                break;
            case ACTION_LOGOUT:
                merchants.logout()
                window.location.href = '/'
                break;
        }
    })
}

export default request