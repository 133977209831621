import * as React from "react";
import {LineChart, Line, XAxis, YAxis, ResponsiveContainer, Tooltip} from 'recharts';
import { useTheme } from '@mui/material/styles';
import {useEffect, useState} from "react";
import stats from "../../models/stats";
import StatsContainer from "./StatsContainer";
import moment from "moment";

const defaultFilters = {
    from: moment.utc().startOf('day').toISOString(),
    to: moment.utc().endOf('day').toISOString()
}

export default function TotalCompletedChart(props) {
    const {fullPage} = props
    const theme = useTheme()
    const [data, setData] = useState([])
    const [filters, setFilters] = useState(defaultFilters)

    useEffect(() => {
        const newFilters = {...filters, type: 'totalAmount'}
        stats.getStats(newFilters).then((statsData) => {
            setData(stats.buildData(statsData.stats, statsData.interval, statsData.from, statsData.to, statsData.subunit))
        })
    }, [filters])

    return <StatsContainer fullPage={fullPage} setFilters={setFilters} filters={filters}>
        <ResponsiveContainer>
            <LineChart
                data={data}
                margin={{
                    top: 16,
                    right: 16,
                    bottom: 0,
                    left: 24,
                }}
            >
                <XAxis
                    dataKey="time"
                    stroke={theme.palette.text.secondary}
                    style={theme.typography.body2}
                    tick={false}
                />
                <YAxis
                    stroke={theme.palette.text.secondary}
                    style={theme.typography.body2}
                >
                </YAxis>
                <Tooltip formatter={(value, name, props) => [value, "€"]}
                />
                <Line
                    isAnimationActive={false}
                    type="monotone"
                    dataKey="amount"
                    stroke={theme.palette.secondary.main}
                    dot={false}
                    label={false}
                />
            </LineChart>
        </ResponsiveContainer>
    </StatsContainer>
}