import {Link as RouterLink} from "react-router-dom";
import Link from "@mui/material/Link";
import {Breadcrumbs} from "@mui/material";

export default function BreadCrumbs({elements}) {

    return (
        <div role="presentation">
            <Breadcrumbs aria-label="breadcrumb">
                {
                    elements.map((element, index) => {
                        const lastEl = index === elements.length - 1
                        return <Link
                            key={`bc-${index}`}
                            underline="hover"
                            color={lastEl ? 'text.primary' : 'inherit'}
                            to={element[1]}
                            component={RouterLink}
                            aria-current={lastEl ? 'page' : 'false'}
                        >
                            {element[0]}
                        </Link>
                    })
                }
            </Breadcrumbs>
        </div>
    );
}