import * as React from 'react';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from '../Title';
import {useEffect, useState} from "react";
import helpers from "../../helpers/common";
import useRootHook from "../../hooks/useRoot";
import EnhancedEncryptionIcon from '@mui/icons-material/EnhancedEncryption';
import {Add, Refresh, DeleteForever} from "@mui/icons-material";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import {Link as RouterLink} from "react-router-dom";
import Paper from "@mui/material/Paper";

import BreadCrumbs from "../BreadCrumbs";
import servers from "../../models/servers";
import {Stack} from "@mui/material";

export default function ServerIndex(props) {
    const [serversData, setServersData] = useState(null)
    const {setShowLoader, notify} = useRootHook()
    const [refreshId, setRefreshId] = useState(0)

    useEffect(() => {
        setShowLoader(true)
        servers.getServers()
            .then((data) => {
                data && setServersData(data)
                setShowLoader(false)
            }).catch(() => setShowLoader(false))
    }, [refreshId])

    const handleMakeSSL = (serverName) => {
        if (!window.confirm('Please confirm you want to proceed')) {
            return
        }
        servers.makeSSL(serverName)
            .then(() => {
                notify('Request sent. Upon successful certificate generation a message will be sent in Telegram')
            })
    }

    const handleDelete = (id) => {
        if (!window.confirm('Please confirm you want to delete the server')) {
            return
        }
        servers.delete(id)
            .then(() => {
                notify('Server deleted')
                handleRefresh()
            })
    }
    const handleRefresh = () => {
        setRefreshId((prevId) => {
            return prevId + 1
        })
    }

    if (!serversData) return null

    return <React.Fragment>
        <Grid item xs={12}>
            <BreadCrumbs elements={[['Dashboard', '/'], ['Servers', '/servers']]}/>
        </Grid>
        <Grid item xs={12}>
            <Stack spacing={2} direction="row">
                <Button to="/servers/add" component={RouterLink} variant="outlined" startIcon={<Add />}>
                    Add Server
                </Button>
                <Button onClick={handleRefresh} variant="outlined" startIcon={<Refresh />}>
                    Refresh
                </Button>
            </Stack>
        </Grid>
        <Grid item xs={12}>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', overflowX: 'auto'}}>
                <Title>Servers</Title>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Type & Location</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>IPs</TableCell>
                            <TableCell>Created</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {serversData.map((row) => (
                            <TableRow key={`customer-${row.id}`}>
                                <TableCell>
                                    <Link color="primary" to={`edit/?id=${row.id}`} component={RouterLink} server={row}>
                                        {row.id}
                                    </Link>
                                </TableCell>
                                <TableCell>{row.name}</TableCell>
                                <TableCell>{`${row.server_type.name} - ${row.datacenter.name} `}</TableCell>
                                <TableCell>{row.status}</TableCell>
                                <TableCell>{`${row.public_net.ipv4.ip} / ${row.public_net.ipv6.ip}`}</TableCell>
                                <TableCell>{helpers.getDate(row.created)}</TableCell>
                                <TableCell>
                                    <EnhancedEncryptionIcon sx={{cursor: "pointer"}} onClick={() => {
                                        handleMakeSSL(row.name)
                                    }} />
                                    <DeleteForever sx={{cursor: "pointer"}} onClick={() => {
                                        handleDelete(row.id)
                                    }} />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Paper>
        </Grid>
    </React.Fragment>
}

