import request from "../helpers/request";
import customers from "./customers";

const servers = {}

servers.getServers = async() => {
    return request.get('/servers', {})
}

servers.getServer = async(id) => {
    return request.get('/servers/get', {id})
}

servers.add = async (data) => {
    return request.post('/servers/add', {name: data.name, image: data.image}, true)
}
servers.edit = async (data) => {
    return request.put('/servers/edit', {name: data.name, id: data.id}, true)
}
servers.makeSSL = async (serverName) => {
    return request.post('/servers/make-cert', {name: serverName})
}

servers.delete = async (id) => {
    return request.delete('/servers/delete', {id})
}

servers.getImages = async () => {
    return request.get('/servers/get-images')
}

servers.FORM_FIELDS = ['name', 'image']

servers.methodValidator = {}
servers.methodValidator.add = (fields, errors = {}) => {
    servers.fieldValidator.name(fields, errors)
    servers.fieldValidator.image(fields, errors)

    return errors
}
servers.methodValidator.edit = (fields, errors = {}) => {
    servers.fieldValidator.name(fields, errors)
    servers.fieldValidator.id(fields, errors)

    return errors
}


servers.fieldValidator = {}
servers.fieldValidator.name = (fields, errors) => {
    if (!fields.name) {
        errors.name = 'Please insert domain name'
    }

    fields.name = fields.name.trim()
    fields.name = fields.name.toLowerCase()

    const testDomainName = /^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/
    if (!testDomainName.test(fields.name)) {
        errors.name = 'Incorrect domain name'
    }
}
servers.fieldValidator.image = (fields, errors) => {
    if (!fields.image) {
        errors.image = 'Please select a image'
    }
    fields.image = parseInt(fields.image)
}

servers.fieldValidator.id = (fields, errors) => {
    if (!fields.id || typeof fields.id !== 'number') {
        errors.image = 'Incorrect ID'
    }
}


export default servers