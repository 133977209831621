import * as React from 'react';
import Grid from '@mui/material/Grid';
import {
    FormHelperText, InputLabel, MenuItem, Select, TextField
} from '@mui/material';
import Button from '@mui/material/Button';
import {Save} from '@mui/icons-material';
import helpers from '../../helpers/common';
import projects from '../../models/projects';
import {useEffect, useState} from 'react';
import useRootHook from '../../hooks/useRoot';
import PropTypes from 'prop-types';
import paymethods from '../../models/paymethods';

ProjectMethodForm.propTypes = {
    project: PropTypes.object.isRequired,
    selectedMethod: PropTypes.object,
    excludeIDs: PropTypes.array.isRequired,
    refresh: PropTypes.func.isRequired,
    closeForm: PropTypes.func.isRequired,
};

export default function ProjectMethodForm({project, selectedMethod, excludeIDs, refresh, closeForm}) {
    const [errors, setErrors] = useState({});
    const [methods, setMethods] = useState(null);
    const {setShowLoader, notify, defaultCurrency} = useRootHook();

    const defaultFields = selectedMethod ? {
        ...selectedMethod,
        project_id: project.id,
        dispute_cost: parseFloat(selectedMethod.dispute_cost) / defaultCurrency.subunit,
    } : {project_id: project.id};
    const [fields, setFields] = useState(defaultFields);

    useEffect(() => {
        paymethods.index()
            .then((data) => {
                data && setMethods(data.filter(method => !excludeIDs.includes(method.id)));//filter already enabled methods
            })
            .catch();
    }, []);

    const handleSubmit = (ev) => {
        ev.preventDefault();
        const validationErrors = projects.methodValidator.addEditMethod(fields);
        setErrors(validationErrors);

        if (Object.keys(validationErrors).length !== 0) return;

        setShowLoader(true);

        const sendMethod = selectedMethod ? projects.editMethod : projects.addMethod;
        sendMethod(fields)
            .then((result) => {
                setShowLoader(false);
                result && refresh() && closeForm() && notify(selectedMethod ? 'Method saved' : 'Method added');
            }).catch(() => setShowLoader(false));
    };

    const handleChange = (ev) => {
        const [target, value, name] = helpers.getFormEventData(ev);
        console.log(name, value);

        setErrors({...errors, [name]: undefined});

        setFields((prevState) => {
            let newFields = {...prevState};
            newFields[name] = value;

            return newFields;
        });
    };

    const handleBlur = (ev) => {
        const [target, value, name] = helpers.getFormEventData(ev);

        const newErrors = {...errors};
        const newFields = {...fields};

        typeof projects.fieldValidator[name] === 'function'
        && projects.fieldValidator[name](newFields, newErrors);

        setFields(newFields);

        setErrors(newErrors);
    };


    if (!methods) return null;

    return <React.Fragment>
        <Grid container spacing={2}
              component="form"
              onSubmit={handleSubmit}
              autoComplete="off"
        >
            <Grid item xs={12} md={4}>
                <InputLabel>Select method</InputLabel>
                <Select
                    fullWidth={true}
                    error={!!errors.paymethod_id}
                    required={true}
                    name="paymethod_id"
                    variant="standard"
                    id="paymethod_id"
                    value={fields.paymethod_id || ''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={!!selectedMethod}
                >
                    {
                        methods.flatMap((method) => {
                            //if (!selectedMethod && excludeIDs.includes(method.id)) return []
                            return <MenuItem key={`id-${method.id}`} value={method.id}>{method.name}</MenuItem>;
                        })
                    }
                </Select>
                {errors.paymethod_id && <FormHelperText>{errors.paymethod_id}</FormHelperText>}
            </Grid>
            <Grid item xs={6} md={4}>
                <InputLabel>Fee</InputLabel>
                <TextField
                    error={!!errors.fee}
                    value={typeof fields.fee !== 'undefined' ? fields.fee : ''}
                    name="fee"
                    id="fee"
                    required={true}
                    variant="standard"
                    fullWidth={true}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                {errors.fee && <FormHelperText>{errors.fee}</FormHelperText>}
            </Grid>
            <Grid item xs={6} md={4}>
                <InputLabel>Dispute cost</InputLabel>
                <TextField
                    error={!!errors.dispute_cost}
                    value={typeof fields.dispute_cost !== 'undefined' ? fields.dispute_cost : ''}
                    name="dispute_cost"
                    id="dispute_cost"
                    required={true}
                    variant="standard"
                    fullWidth={true}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                {errors.dispute_cost && <FormHelperText>{errors.dispute_cost}</FormHelperText>}
            </Grid>
            <Grid item xs={6} md={3}>
                <InputLabel>Hold days</InputLabel>
                <TextField
                    error={!!errors.hold_days}
                    value={typeof fields.hold_days !== 'undefined' ? fields.hold_days : ''}
                    name="hold_days"
                    id="hold_days"
                    required={true}
                    variant="standard"
                    fullWidth={true}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                {errors.hold_days && <FormHelperText>{errors.hold_days}</FormHelperText>}
            </Grid>
            <Grid item xs={6} md={3}>
                <InputLabel>Hold amount %</InputLabel>
                <TextField
                    error={!!errors.hold_amount}
                    value={typeof fields.hold_amount !== 'undefined' ? fields.hold_amount : ''}
                    name="hold_amount"
                    id="hold_amount"
                    required={true}
                    variant="standard"
                    fullWidth={true}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                {errors.hold_amount && <FormHelperText>{errors.hold_amount}</FormHelperText>}
            </Grid>
            <Grid item xs={6} md={3}>
                <InputLabel>Rolling reserve days</InputLabel>
                <TextField
                    error={!!errors.reserve_days}
                    value={typeof fields.reserve_days !== 'undefined' ? fields.reserve_days : ''}
                    name="reserve_days"
                    id="reserve_days"
                    required={true}
                    variant="standard"
                    fullWidth={true}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                {errors.reserve_days && <FormHelperText>{errors.reserve_days}</FormHelperText>}
            </Grid>
            <Grid item xs={6} md={3}>
                <InputLabel>Rolling amount %</InputLabel>
                <TextField
                    error={!!errors.reserve_amount}
                    value={typeof fields.reserve_amount !== 'undefined' ? fields.reserve_amount : ''}
                    name="reserve_amount"
                    id="reserve_amount"
                    required={true}
                    variant="standard"
                    fullWidth={true}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                {errors.reserve_amount && <FormHelperText>{errors.reserve_amount}</FormHelperText>}
            </Grid>
            <Grid container item spacing={3} sx={{mt: 2}}>
                <Grid item xs={6}>
                    <Button type="submit" onClick={handleSubmit} variant="outlined" startIcon={<Save/>}>
                        Save
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    </React.Fragment>;
}
