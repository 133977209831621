import * as React from 'react';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from '../Title';
import {useEffect, useState} from "react";
import transactions from '../../models/transactions'
import helpers from "../../helpers/common";
import useRootHook from "../../hooks/useRoot";
import {Chip, Tooltip} from "@mui/material";
import {Link as RouterLink, useNavigate} from "react-router-dom";
import {Info, SettingsBackupRestore} from "@mui/icons-material";
import Pagination from "../Pagination";
import Typography from "@mui/material/Typography";
import TransactionDetailsDialog from "./TransactionDetailsDialog";
import IconButton from "@mui/material/IconButton";
import moment from "moment";

const MAX_REFUND_DAYS = 30//todo confirm

export default function TransactionIndex({fullPage, searchParams, setSearchParams, refreshId, refresh}) {
    fullPage = typeof fullPage === 'boolean' ? fullPage : true
    const [transactionsData, setTransactionsData] = useState(null)
    const [dialogIsOpened, setDialogIsOpened] = useState(false)
    const [selectedTransaction, setSelectedTransaction] = useState(null)
    const {setShowLoader, userData} = useRootHook()
    const navigate = useNavigate()

    useEffect(() => {
        setShowLoader(true)
        transactions.getData(searchParams ? Object.fromEntries(searchParams) : {})
            .then((data) => {
                if (!data) {
                    setShowLoader(false)
                    return
                }
                data && setTransactionsData(data)
                setShowLoader(false)
            }).catch((err) => {
                setShowLoader(false)
        })
    }, [searchParams, refreshId])

    const handleSetFilters = (field, value) => {
        if (setSearchParams) {
            if (searchParams.get(field) === value.toString()){
                searchParams.delete(field)
            } else {
                searchParams.set(field, value)
            }
            setSearchParams(searchParams)
        } else {
            navigate(`/transactions?${field}=${value}`)
        }
    }

    const handleRefund = (uuid) => {
        setShowLoader(true)
        transactions.refund(helpers.UUIDv4Transform(uuid))
            .then(() => helpers.sleep(3000))//Transaction will be updated by webhook, so delay the refresh
            .then(() => refresh)
            .catch((err) => {
                console.log(err)
                setShowLoader(false)
            })
    }

    if (!transactionsData) return null

    const now = moment()

    return (
        <React.Fragment>
            <Title>Recent Transactions</Title>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Customer</TableCell>
                        <TableCell>Merchant</TableCell>
                        <TableCell>Project</TableCell>
                        {userData.role !== helpers.roles.M && <TableCell>Method</TableCell>}
                        <TableCell>Status</TableCell>
                        <TableCell>Amount</TableCell>
                        <TableCell>Created</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {transactionsData.transactions.map((row) => {
                        return <TableRow key={`tr-${row.id}`}>
                            <TableCell>
                                <Link onClick={() => handleSetFilters('customer_id', row['customer.id'])}
                                      underline="none" sx={{cursor: "pointer"}}>
                                    {helpers.cutLongString(row['customer.email'], 20)}
                                </Link>
                            </TableCell>
                            <TableCell>
                                <Link onClick={() => handleSetFilters('merchant_id', row['merchant.id'])}
                                      underline="none" sx={{cursor: "pointer"}}>
                                    {helpers.cutLongString(row['merchant.name'], 20)}
                                </Link>
                            </TableCell>
                            <TableCell>
                                <Link onClick={() => handleSetFilters('project_id', row['project.id'])} underline="none"
                                      sx={{cursor: "pointer"}}>
                                    {helpers.cutLongString(row['project.name'], 20)}
                                </Link>
                            </TableCell>
                            {userData.role !== helpers.roles.M && <TableCell>
                                <Link onClick={() => handleSetFilters('paymethod_id', row['paymethod.id'])}
                                      underline="none" sx={{cursor: "pointer"}}>
                                    {helpers.cutLongString(row['paymethod.name'], 20)}
                                </Link>
                            </TableCell>}
                            <TableCell>
                                <Chip size="small" color={transactions.getChipStatusColor(row.status)} label={transactions.enumToStatus[row.status].capitalize()}/>
                            </TableCell>
                            <TableCell>
                                {helpers.getSubunitAmountString(row.amount_currency, row['currency.symbol'], row['currency.subunit'])}
                            </TableCell>
                            <TableCell>
                                <Typography variant="body2"
                                            sx={{fontWeight: 'bold'}}>{`${helpers.getTime(row.created)}`}</Typography>
                                <Typography variant="body2">{`${helpers.getDate(row.created)}`}</Typography>
                            </TableCell>
                            <TableCell>
                                <Tooltip title="Transaction details & callbacks">
                                    <IconButton onClick={() => {
                                        setSelectedTransaction(row)
                                        setDialogIsOpened(true)
                                    }}>
                                        <Info fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                                {row.status === transactions.statusToEnum.completed
                                    && now.diff(moment(row.created), 'days') <= MAX_REFUND_DAYS
                                    && <Tooltip title="Refund transaction">
                                    <IconButton onClick={() => {
                                        if (window.confirm('Are you sure you want to refund this transaction?')) {
                                            handleRefund(row.uuid)
                                        }
                                    }}>
                                        <SettingsBackupRestore fontSize="small" color="error"/>
                                    </IconButton>
                                </Tooltip>}
                            </TableCell>
                        </TableRow>
                    })}
                </TableBody>
            </Table>
            {!fullPage &&
            <Link color="primary" to="/transactions" sx={{ mt: 3 }} component={RouterLink}>
                See more transactions
            </Link>
            }
            {fullPage && <Pagination url={'/transactions'} count={transactionsData.count}/>}
            <TransactionDetailsDialog dialogIsOpened={dialogIsOpened} setDialogIsOpened={setDialogIsOpened} selectedTransaction={selectedTransaction} setSelectedTransaction={setSelectedTransaction} refresh={refresh}/>
        </React.Fragment>
    );
}