import {useEffect, useState, Fragment} from "react";
import useRootHook from "../../hooks/useRoot";
import projects from "../../models/projects";
import {Stack, TableContainer} from "@mui/material";
import Button from "@mui/material/Button";
import {Add, Check, Delete} from "@mui/icons-material";
import Grid from "@mui/material/Grid";
import * as React from "react";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Link from "@mui/material/Link";
import helpers from "../../helpers/common";
import ProjectMethodForm from "./ProjectMethodForm";
import IconButton from "@mui/material/IconButton";

export default function ProjectEditMethods({project}) {
    const [projectMethods, setProjectMethods] = useState(null)
    const [showForm, setShowForm] = useState(false)
    const [selectedMethod, setSelectedMethod] = useState(null)
    const [refreshID, setRefreshID] = useState(0)
    const {setShowLoader, notify} = useRootHook()

    useEffect(() => {
        setShowLoader(true)
        projects.getMethods(project.id)
            .then((projectsData) => {
                setProjectMethods(projectsData)
                setShowLoader(false)
            }).catch(() => setShowLoader(false))
    }, [refreshID])

    const handleAddClick = () => {
        setSelectedMethod(null)
        setShowForm(true)
    }

    const handleEdit = (method) => {
        setSelectedMethod(method)
        setShowForm(true)
    }

    const handleDelete = (method) => {
        if (!window.confirm('Are you sure you want to proceed?')) return
        setShowLoader(true)
        projects.deleteMethod(method.project_id, method.paymethod_id)
            .then((result) => {
                result && refresh() && notify('Method deleted')
                setShowLoader(false)
            }).catch(() => setShowLoader(false))
    }

    const refresh = () => {
        setRefreshID(prevState => prevState + 1)
        return true
    }

    const closeForm = () => {
        setSelectedMethod(null)
        setShowForm(false)
        return true
    }

    if (!projectMethods) return null

    if (showForm) return <ProjectMethodForm project={project} excludeIDs={projectMethods.map(method => method.paymethod_id)} selectedMethod={selectedMethod} refresh={refresh} closeForm={closeForm} />

    return <Fragment>
        <Grid item xs={12} sx={{mb: 3}}>
            <Stack spacing={2} direction="row">
                <Button onClick={handleAddClick} variant="outlined" startIcon={<Add />}>
                    Add Method
                </Button>
            </Stack>
        </Grid>
        <Grid item xs={12}>
            <TableContainer>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Method Enabled</TableCell>
                            <TableCell>Date Added</TableCell>
                            <TableCell align="right">Delete</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {projectMethods.map((row) => (
                            <TableRow key={`pro-${row.paymethod_id}`}>
                                <TableCell>
                                    <Link onClick={() => handleEdit(row)} sx={{cursor: "pointer"}}>
                                        {row.paymethod.name}
                                    </Link>
                                </TableCell>
                                <TableCell>{!!row.paymethod.enabled ? <Check /> : ''}</TableCell>
                                <TableCell>{helpers.getDate(row.created)}</TableCell>
                                <TableCell align="right">
                                    <IconButton onClick={() => handleDelete(row)}>
                                        <Delete fontSize="small" />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Grid>
    </Fragment>
}