import {useNavigate, useSearchParams} from "react-router-dom";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Title from "../Title";
import * as React from "react";
import BreadCrumbs from "../BreadCrumbs";
import {useEffect, useState} from "react";
import useRootHook from "../../hooks/useRoot";
import helpers from "../../helpers/common";
import PayoutForm from "./PayoutForm";
import payouts from "../../models/payouts";
import methods from "../../models/payout-methods";

export default function PayoutEdit() {
    const [searchParams] = useSearchParams();
    const [payoutData, setPayoutData] = useState(null)
    const [payoutMethods, setPayoutMethods] = useState(null)
    const [fields, setFields] = useState({})
    const [errors, setErrors] = useState({});
    const {setShowLoader, userData} = useRootHook();
    const navigate = useNavigate()

    useEffect(() => {
        setShowLoader(true)
        Promise.all(
            [
                methods.list(),
                payouts.get(searchParams.get('id'))
            ]
        )
            .then(([methodsData, payoutData]) => {
                setShowLoader(false)
                methodsData && setPayoutMethods(methodsData)
                payoutData && setPayoutData(payoutData)

                payoutData && setFields({
                    id: payoutData.id,
                    amount_total: helpers.subunitAmount(payoutData.amount_total, userData.defaultCurrency.subunit),
                    amount: helpers.subunitAmount(payoutData.amount, userData.defaultCurrency.subunit),
                    details: payoutData.details,
                    status: payoutData.status,
                    method_id: payoutData.method_id
                })
                console.log(helpers.subunitAmount(payoutData.amount, userData.default.subunit, 2))
            }).catch(() => setShowLoader(false))
    }, [])

    const handleChange = (ev) => {
        const [target, value, name] = helpers.getFormEventData(ev);

        setErrors({...errors, [name]: undefined});

        setFields((prevState) => {
            let newFields = {...prevState};
            newFields[name] = value;

            return newFields
        })
    }

    const handleBlur = (ev) => {
        const [target, value, name] = helpers.getFormEventData(ev)

        const newErrors = {...errors}
        const newFields = {...fields}

        typeof payouts.fieldValidator[name] === 'function'
        && payouts.fieldValidator[name](newFields, newErrors)

        setErrors(newErrors)
    }

    const handleSubmit = (ev) => {
        ev.preventDefault()
        const validationErrors = payouts.methodValidator.edit(fields)
        setErrors(validationErrors)

        if (Object.keys(validationErrors).length !== 0) return

        setShowLoader(true)
        payouts.edit(fields)
            .then(() => {
                setShowLoader(false)
                navigate('/payouts')
            }).catch(() => setShowLoader(false))
    }

    if (!payoutData || !payoutMethods) return null

    return <React.Fragment>
        <Grid item xs={12}>
            <BreadCrumbs elements={[['Dashboard', '/'], ['Payouts', '/payouts'], ['Edit', `/payouts/edit?id=${payoutData.id}`]]}/>
        </Grid>
        <Grid item xs={12}>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                <Title>Update Payout</Title>
                <PayoutForm payoutMethods={payoutMethods} fields={fields} errors={errors} handleChange={handleChange}
                            handleBlur={handleBlur} handleSubmit={handleSubmit} />
            </Paper>
        </Grid>
    </React.Fragment>
}