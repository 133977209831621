import * as React from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import {useNavigate, useSearchParams} from 'react-router-dom';
import Title from '../Title';
import BreadCrumbs from '../BreadCrumbs';
import {useEffect, useState} from 'react';
import useRootHook from '../../hooks/useRoot';
import projects from '../../models/projects';
import helpers from '../../helpers/common';
import customers from '../../models/customers';
import CustomerForm from './CustomerForm';

export default function CustomerAdd(props) {

    const [searchParams] = useSearchParams();
    const {setShowLoader} = useRootHook();
    const navigate = useNavigate();
    const [projectsData, setProjectsData] = useState([]);
    const [errors, setErrors] = useState({});

    const defaultFields = searchParams.get('project_id') ? {project_id: parseInt(searchParams.get('project_id'))} : {};
    const [fields, setFields] = useState(defaultFields);

    useEffect(() => {
        setShowLoader(true);
        projects.index({})
            .then((data) => {
                setShowLoader(false);
                data && setProjectsData(data);
                if (fields.project_id && !data.some((project) => project.id === fields.project_id)) {
                    setFields({});
                }
            }).catch(() => setShowLoader(false));
    }, []);

    const handleChange = (ev) => {
        const [target, value, name] = helpers.getFormEventData(ev);

        setErrors({...errors, [name]: undefined});

        setFields((prevState) => {
            let newFields = {...prevState};
            newFields[name] = value;

            return newFields;
        });
    };

    const handleBlur = (ev) => {
        const [target, value, name] = helpers.getFormEventData(ev);

        if (!customers.FORM_FIELDS.includes(name)) return;

        const newErrors = {...errors};
        const newFields = {...fields};

        typeof customers.fieldValidator[name] === 'function'
        && customers.fieldValidator[name](newFields, newErrors);

        setErrors(newErrors);
    };

    const handleSubmit = (ev) => {
        ev.preventDefault();
        const validationErrors = customers.methodValidator.add(fields);
        setErrors(validationErrors);

        if (Object.keys(validationErrors).length !== 0) return;

        setShowLoader(true);
        customers.add(fields)
            .then((data) => {
                setShowLoader(false);
                if (data) navigate('/customers');
            }).catch(() => setShowLoader(false));
    };

    if (projectsData.length === 0) return null;

    return <React.Fragment>
        <Grid item xs={12}>
            <BreadCrumbs elements={[['Dashboard', '/'], ['Customers', '/customers'], ['Add', '/customers/add']]}/>
        </Grid>
        <Grid item xs={12}>
            <Paper sx={{p: 2, display: 'flex', flexDirection: 'column'}}>
                <Title>Add Customer</Title>
                <CustomerForm projectsData={projectsData} fields={fields} errors={errors} handleChange={handleChange}
                              handleBlur={handleBlur} handleSubmit={handleSubmit}
                              project_id={!!searchParams.get('project_id')}/>
            </Paper>
        </Grid>
    </React.Fragment>;
}