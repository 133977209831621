import Grid from "@mui/material/Grid";
import {
    Autocomplete,
    Checkbox,
    FormControlLabel,
    FormHelperText,
    InputLabel,
    TextField
} from "@mui/material";
import Button from "@mui/material/Button";
import {Save} from "@mui/icons-material";
import * as React from "react";
import helpers from "../../helpers/common";
import useRootHook from "../../hooks/useRoot";
import {useEffect, useState} from "react";
import merchants from "../../models/merchants";

export default function ProjectForm ({project, fields, setFields, errors, setErrors, handleChange, handleBlur, handleSubmit}) {
    const {userData} = useRootHook()
    const [merchantsList, setMerchantList] = useState(null)
    useEffect(() => {
        userData.role !== helpers.roles.M && merchants.list()
            .then((data) => {
                setMerchantList(data)
            })
    }, [])

    if (userData.role !== helpers.roles.M && !merchantsList) return null

    return <React.Fragment>
        <Grid container spacing={2}
              component="form"
              onSubmit={handleSubmit}
              autoComplete="off"
        >
            {userData.role !== helpers.roles.M && !project && <Grid item xs={12}>
                <InputLabel>Merchant</InputLabel>
                <Autocomplete
                    disablePortal
                    id="merchant_id"
                    name="merchant_id"
                    options={merchantsList}
                    fullWidth={true}
                    required={true}
                    onChange={(_, newValue) => {
                        setErrors({...errors, merchant_id: undefined});
                        setFields((prevFields) => {return {...prevFields, merchant_id: newValue ? newValue.id : null}})
                    }}
                    onBlur={() => {
                        if (!fields.merchant_id) setErrors((prevErrors) => {return {...prevErrors, merchant_id: 'No merchant selected'}})
                    }}
                    renderInput={(params) => <TextField {...params} variant="standard" error={!!errors.merchant_id}/>}
                />
                {errors.merchant_id && <FormHelperText>{errors.merchant_id}</FormHelperText>}
            </Grid>}
            <Grid item xs={12} md={6}>
                <InputLabel>Name</InputLabel>
                <TextField
                    error={!!errors.name}
                    value={fields.name || ""}
                    name="name"
                    id="name"
                    required={true}
                    variant="standard"
                    fullWidth={true}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                {errors.name && <FormHelperText>{errors.name}</FormHelperText>}
            </Grid>
            <Grid item xs={12} md={6}>
                <InputLabel>Email</InputLabel>
                <TextField
                    error={!!errors.email}
                    value={fields.email || ""}
                    name="email"
                    id="email"
                    required={true}
                    variant="standard"
                    fullWidth={true}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                {errors.email && <FormHelperText>{errors.email}</FormHelperText>}
            </Grid>
            <Grid item xs={12} md={6}>
                <InputLabel>Phone</InputLabel>
                <TextField
                    error={!!errors.phone}
                    value={fields.phone || ""}
                    name="phone"
                    id="phone"
                    required={true}
                    variant="standard"
                    fullWidth={true}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                {errors.phone && <FormHelperText>{errors.phone}</FormHelperText>}
            </Grid>
            <Grid item xs={12} md={6}>
                <InputLabel>URL</InputLabel>
                <TextField
                    disabled={project && project.url && userData.role === helpers.roles.M}
                    error={!!errors.url}
                    value={fields.url || ""}
                    name="url"
                    id="url"
                    required={true}
                    variant="standard"
                    fullWidth={true}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                {errors.url && <FormHelperText>{errors.url}</FormHelperText>}
            </Grid>
            <Grid item xs={12}>
                {<FormControlLabel
                    control={
                        <Checkbox
                            name="enabled"
                            disabled={userData.role === helpers.roles.M}
                            checked={typeof fields.enabled === 'boolean' ? fields.enabled : false}
                            onChange={handleChange}
                            value={typeof fields.enabled === 'boolean' ? fields.enabled : false}
                            color="secondary"
                        />
                    }
                    label="Enabled"
                />}
            </Grid>
            <Grid container item spacing={3} sx={{mt: 2}}>
                <Grid item xs={6}>
                    <Button type="submit" onClick={handleSubmit} variant="outlined" startIcon={<Save />} >
                        Save
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    </React.Fragment>
}
