import * as React from 'react';
import Title from '../Title';
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import TransactionIndex from "./TransactionIndex";
import {useState} from "react";
import {useSearchParams} from "react-router-dom";
import BreadCrumbs from "../BreadCrumbs";
import {Accordion, AccordionDetails, AccordionSummary, Stack} from "@mui/material";
import {ExpandMore, Refresh, FileDownload} from "@mui/icons-material";
import Button from "@mui/material/Button";
import TransactionFilter from "./TransactionFilter";
import IconButton from "@mui/material/IconButton";
import helpers from '../../helpers/common';

export default function TransactionContainer() {
    const [searchParams, setSearchParams] = useSearchParams()
    const [refreshId, setRefreshId] = useState(0)
    const [accordionExpanded, setAccordionExpanded] = useState(Array.from(searchParams).length > 0)

    const refresh = () => {
        setRefreshId((prevId) => {
            return prevId + 1
        })
    }

    return <React.Fragment>
        <Grid item xs={12}>
            <BreadCrumbs elements={[['Dashboard', '/'], ['Transactions', '/transactions']]}/>
        </Grid>
        <Grid item xs={12}>
            <Accordion expanded={accordionExpanded}>
                <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    onClick={() => setAccordionExpanded(expanded => !expanded)}
                >
                    <Title>Filters</Title>
                </AccordionSummary>
                <AccordionDetails>
                    <TransactionFilter />
                </AccordionDetails>
            </Accordion>
        </Grid>
        <Grid item xs={12}>
            <Stack spacing={2} direction="row" sx={{display: {xs: 'none', md: 'inherit'}}}>
                <Button onClick={refresh} color="primary" variant="contained" startIcon={<Refresh />}>
                    Refresh
                </Button>
                <Button href={`/iapi/transactions?${searchParams.toString()}${helpers.forcedMerchantID ? `&merchant_id=${helpers.forcedMerchantID}` : ''}&export`} color="secondary" variant="contained" startIcon={<FileDownload />}>
                    Export CSV
                </Button>
            </Stack>
            <Stack spacing={2} direction="row" sx={{display: {xs: 'inherit', md: 'none'}}}>
                <IconButton onClick={refresh} color="primary">
                    <Refresh />
                </IconButton>
                <Button href={`/iapi/transactions?${searchParams.toString()}${helpers.forcedMerchantID && `&merchant_id=${helpers.forcedMerchantID}`}&export`} color="primary">
                    <FileDownload />
                </Button>
            </Stack>
        </Grid>
        <Grid item xs={12}>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', overflowX: 'auto'}}>
                <TransactionIndex fullPage={true} searchParams={searchParams} setSearchParams={setSearchParams} refreshId={refreshId} refresh={refresh}/>
            </Paper>
        </Grid>
    </React.Fragment>
}