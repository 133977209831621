import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import merchants from "../models/merchants";
import useRootHook from "../hooks/useRoot";
import {Link as RouterLink, useNavigate} from "react-router-dom";
import {useEffect} from "react";
import {ADMIN_PATH, TYPE_ADMIN, TYPE_MANAGER} from "./Login";

const theme = createTheme();

export default function ForgotPassword() {
    const {authed, setShowLoader, notify} = useRootHook();
    const navigate = useNavigate()
    const type = window.location.pathname.slice(-6) === ADMIN_PATH ? TYPE_ADMIN : TYPE_MANAGER

    useEffect(() => {
        if (authed) {
            navigate('/')
        }
    }, [authed])

    const handleSubmit = (event) => {
        event.preventDefault();
        setShowLoader(true)
        const data = new FormData(event.currentTarget);
        merchants.forgotPassword(data.get('email'),  type)
            .then((result) => {
                setShowLoader(false)
                notify('You will receive an email shortly if such an account exists')
            }).catch(() => setShowLoader(false))
    }

    const loginLink = '/login' + (type === 'a' ? '/admin' : '')

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Reset Password
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            autoFocus
                        />
                        {/*{<FormControlLabel
                            control={<Checkbox name="remember" value="remember" color="primary" />}
                            label="Remember me"
                        />}*/}
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Recover Password
                        </Button>
                        <Grid container>
                            <Grid item xs>
                                <Link to={loginLink} variant="body2" component={RouterLink}>
                                    Login
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Container>
        </ThemeProvider>
    );
}