import helpers from "../helpers/common";
import request from "../helpers/request";

const payouts = {}

const STATUS_PENDING = 'pending'
const STATUS_COMPLETED = 'completed'
const STATUS_CANCELED = 'canceled'

payouts.getStatuses = () => {
    return [
        STATUS_PENDING, STATUS_COMPLETED, STATUS_CANCELED
    ]
}

payouts.index = async (filters = {}) => {
    helpers.addForcedMerchantID(filters)
    return request.get('/payouts', filters, true)
}

payouts.get = async (id) => {
    return request.get('/payouts/get', {id}, true)
}

payouts.add = async (data) => {
    return request.post('/payouts/add', data, true)
}

payouts.edit = async (data) => {
    return request.put('/payouts/edit', data, true)
}

payouts.methodValidator = {}
payouts.methodValidator.add = (fields, errors = {}) => {
    payouts.fieldValidator.amount_total(fields, errors)
    payouts.fieldValidator.method_id(fields, errors)
    return errors
}
payouts.methodValidator.edit = (fields, errors = {}) => {
    payouts.fieldValidator.id(fields, errors)
    payouts.fieldValidator.amount(fields, errors)
    payouts.fieldValidator.amount_total(fields, errors)
    payouts.fieldValidator.method_id(fields, errors)
    return errors
}

payouts.fieldValidator = {}
payouts.fieldValidator.id = (fields, errors) => {
    if (!fields.id) {
        errors.id = 'Incorrect currency ID'
    }
    fields.id = parseInt(fields.id)
}
payouts.fieldValidator.amount = (fields, errors) => {
    if (!fields.amount) {
        errors.amount = 'Please insert amount'
        return
    }
    if (typeof fields.amount === 'string') {
        fields.amount = fields.amount.trim()
        fields.amount = parseFloat(fields.amount)
    }

    if (fields.amount <= 0) {
        errors.amount = 'Amount should be greater than 0'
    }
}
payouts.fieldValidator.amount_total = (fields, errors) => {
    if (!fields.amount_total) {
        errors.amount_total = 'Please insert amount'
        return
    }
    if (typeof fields.amount_total === 'string') {
        fields.amount_total = fields.amount_total.trim()
        fields.amount_total = parseFloat(fields.amount_total)
    }

    if (fields.amount_total <= 0) {
        errors.amount_total = 'Amount should be greater than 0'
    }
}

payouts.fieldValidator.method_id = (fields, errors) => {
    if (!fields.method_id || typeof fields.method_id !== 'number') {
        errors.method_id = 'Please select a valid method'
    }
}

export default payouts