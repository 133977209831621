import * as React from 'react';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from '../Title';
import {useEffect, useState} from "react";
import merchants from '../../models/merchants'
import helpers from "../../helpers/common";
import useRootHook from "../../hooks/useRoot";
import {
    ExpandMore,
    Add,
    Search,
    Refresh,
    RestartAlt,
    Check,
    FormatListBulleted,
    People,
    BusinessCenter, Visibility
} from "@mui/icons-material";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {Link as RouterLink, useNavigate, useSearchParams} from "react-router-dom";
import Paper from "@mui/material/Paper";
import {Accordion, AccordionDetails, AccordionSummary, Stack, TextField, Tooltip} from "@mui/material";
import Typography from "@mui/material/Typography";
import BreadCrumbs from "../BreadCrumbs";
import Pagination from "../Pagination";
import {AclRouterLink} from "../ACL";
import IconButton from "@mui/material/IconButton";

export default function MerchantIndex() {
    const [searchParams, setSearchParams] = useSearchParams()
    const [refreshId, setRefreshId] = useState(0)
    const [merchantsData, setMerchantsData] = useState(null)
    const {setShowLoader, setForcedMerchant} = useRootHook()
    const navigate = useNavigate()
    const [accordionExpanded, setAccordionExpanded] = useState(Array.from(searchParams).length > 0)

    useEffect(() => {
        setShowLoader(true)
        merchants.getData(searchParams ? searchParams.toString() : {})
            .then((data) => {
                data && setMerchantsData(data)
                setShowLoader(false)
            }).catch(() => setShowLoader(false))
    }, [searchParams, refreshId])

    const handleSetFilters = (field, value) => {
        searchParams.set(field, value)
        setSearchParams(searchParams)
    }

    const handleSetForceMerchantID = (merchantID) => {
        setForcedMerchant(merchantID)
        navigate('/?force_merchant_id=' + merchantID)
    }

    const handleSearch = (event) => {
        event.preventDefault()
        const formData = new FormData(event.currentTarget)
        formData.forEach((value, key) => {
            searchParams.set(key, value.toString())
        })
        setSearchParams(searchParams)
    }

    const handleRefresh = () => {
        setRefreshId((prevId) => {
            return prevId + 1
        })
    }

    if (!merchantsData) return null

    return <React.Fragment>
        <Grid item xs={12}>
            <BreadCrumbs elements={[['Dashboard', '/'], ['Merchants', '/merchants']]}/>
        </Grid>
        <Grid item xs={12}>
            <Accordion expanded={accordionExpanded}>
                <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    onClick={() => setAccordionExpanded(expanded => !expanded)}
                >
                    <Typography>Filters</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Box component="form"
                         sx={{
                             '& > :not(style)': { m: 1, width: '25ch' }
                         }}
                         onSubmit={handleSearch}
                         noValidate
                         autoComplete="off"
                    >
                        <TextField defaultValue={searchParams.get('email')} id="email" name="email" label="Email" variant="standard" size="small"/>
                        <TextField defaultValue={searchParams.get('name')} id="name" name="name" label="Name" variant="standard" size="small"/>
                        <TextField defaultValue={searchParams.get('surname')} id="surname" name="surname" label="Surname" variant="standard" size="small"/>
                        <TextField defaultValue={searchParams.get('test')} id="standard-basic" name="test" label="Test" variant="standard" size="small"/>
                        <Stack spacing={2} direction="row">
                            <Button type="submit" variant="outlined" startIcon={<Search />}>
                                Search
                            </Button>
                            <Button onClick={() => setSearchParams({})} variant="outlined" color="secondary" startIcon={<RestartAlt />}>
                                Reset
                            </Button>
                        </Stack>
                    </Box>
                </AccordionDetails>
            </Accordion>
        </Grid>
        <Grid item xs={12}>
            <Stack spacing={2} direction="row">
                <Button to="/merchants/add" component={AclRouterLink} variant="contained" startIcon={<Add />}>
                    Add Merchant
                </Button>
                <Button onClick={handleRefresh} variant="contained" startIcon={<Refresh />}>
                    Refresh
                </Button>
            </Stack>
        </Grid>
        <Grid item xs={12}>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', overflowX: 'auto'}}>
                <Title>Merchants</Title>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Username</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Enabled</TableCell>
                            <TableCell>Added</TableCell>
                            <TableCell align="right"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {merchantsData.merchants.map((row) => (
                            <TableRow key={`mer-${row.id}`}>
                                <TableCell>
                                    {row.id}
                                </TableCell>
                                <TableCell>
                                    <Link to={`edit?uuid=${helpers.UUIDv4Transform(row.uuid)}`} component={RouterLink}>
                                        {row.username}
                                    </Link>
                                </TableCell>
                                <TableCell>
                                    {row.name}
                                </TableCell>
                                <TableCell>{row.enabled ? <Check  fontSize="small" /> : ''}</TableCell>
                                <TableCell>{helpers.getDateTime(row.created)}</TableCell>
                                <TableCell align="right">
                                    <Tooltip title="View as merchant">
                                        <IconButton onClick={() => handleSetForceMerchantID(row.id)}>
                                            <Visibility fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="View projects">
                                        <IconButton to={`/projects?merchant_id=${row.id}`} component={RouterLink}>
                                            <BusinessCenter fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="View transactions">
                                        <IconButton to={`/transactions?merchant_id=${row.id}`} component={RouterLink}>
                                            <FormatListBulleted fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="View customers">
                                        <IconButton to={`/customers?merchant_id=${row.id}`} component={RouterLink}>
                                            <People fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <Pagination url={'/merchants'} count={merchantsData.count}/>
            </Paper>
        </Grid>
    </React.Fragment>
}

