import request from "../helpers/request";
import moment from "moment";
import helpers from "../helpers/common";

const stats = {}

stats.padHour = (time) => {
    return time < 10 ? `0${time}` : time
}
stats.getAmount = (amount, subunit) => {
    if (typeof amount === "string") {
        amount = parseFloat(amount)
    }
    return parseFloat((amount / subunit).toFixed(2))
}

stats.buildData = (statsData, interval, from, to, subunit = 1) => {
    const mappedData = {}
    statsData.forEach((stat) => {
        const date = moment.utc(stat.date, 'YYYY-MM-DD').format('DD/MM/YY')
        const key = date + (interval === 'hour' ? ` - ${stats.padHour(stat.hour)}` : '')
        mappedData[key] = {
            amount: stat.value,
            count: stat.count
        }
    })

    const chartData = []
    from = moment.utc(from)
    to = moment.utc(to)
    while (from.isBefore(to)) {
        const date = from.format('DD/MM/YY')
        if (interval === 'hour') {
            for (let i = 0; i < 24; i++) {
                const dataKey = `${date} - ${stats.padHour(i)}`
                const key = `${date} - ${stats.padHour(i)}:00-${i === 23 ? "23:59" : stats.padHour(i + 1) + ':00'}`
                chartData.push({
                    time: key,
                    amount: mappedData[dataKey] ? stats.getAmount(mappedData[dataKey].amount, subunit) : 0,
                    count: mappedData[dataKey] ? mappedData[dataKey].count : 0
                })
            }
        } else {
            chartData.push({
                time: date,
                amount: mappedData[date] ? stats.getAmount(mappedData[date].amount, subunit) : 0,
                count: mappedData[date] ? mappedData[date].count : 0
            })
        }
        from.add(1, 'day')
    }
    console.log(chartData)
    return chartData
}

stats.getStats = (filters) => {
    helpers.addForcedMerchantID(filters)
    return request.get('/stats', filters)
}

export default stats