import request from "../helpers/request"
import helpers from "../helpers/common"
const projects = {}

//requests
projects.index = async (filters = {}) => {
    helpers.addForcedMerchantID(filters)
    return request.get('/projects', filters, true)
}
projects.get = async (uuid) => {
    const filters = {uuid}
    helpers.addForcedMerchantID(filters)
    return request.get('/projects/get', filters, true)
}
projects.add = (data) => {
    return request.post('/projects/add', data, true)
}
projects.edit = (data) => {
    return request.put('/projects/edit', data, true)
}
projects.getMethods = async (id) => {
    const filters = {id}
    helpers.addForcedMerchantID(filters)
    return request.get('/projects/get-methods', filters, true)
}
projects.setNewSecretKey = (id) => {
    return request.put('/projects/set-secret-key', {id}, true)
}
projects.addMethod = (data) => {
    return request.post('/projects/add-method', data, true)
}
projects.editMethod = (data) => {
    return request.put('/projects/edit-method', data, true)
}
projects.deleteMethod = (project_id, paymethod_id) => {
    return request.delete('/projects/delete-method', {project_id, paymethod_id}, true)
}
projects.editAPI = (data) => {
    return request.put('/projects/edit-api', data, true)
}

//validators
projects.methodValidator = {}
projects.methodValidator.edit = (fields, errors = {}) => {
    projects.fieldValidator.email(fields, errors)
    projects.fieldValidator.name(fields, errors)
    projects.fieldValidator.url(fields, errors)
    projects.fieldValidator.phone(fields, errors)

    return errors
}
projects.methodValidator.add = (fields, errors = {}) => {
    projects.fieldValidator.email(fields, errors)
    projects.fieldValidator.name(fields, errors)
    projects.fieldValidator.url(fields, errors)
    projects.fieldValidator.phone(fields, errors)

    return errors
}
projects.methodValidator.addEditMethod = (fields, errors = {}) => {
    projects.fieldValidator.project_id(fields, errors)
    projects.fieldValidator.paymethod_id(fields, errors)
    return errors
}
projects.methodValidator.addEditMethod = (fields, errors = {}) => {
    projects.fieldValidator.project_id(fields, errors)
    projects.fieldValidator.paymethod_id(fields, errors)
    return errors
}
projects.methodValidator.editAPI = (fields, errors = {}) => {
    projects.fieldValidator.webhook(fields, errors)
    return errors
}



projects.fieldValidator = {}
projects.fieldValidator.uuid = (fields, errors) => {
    if (!fields.uuid || fields.uuid.length !== 32) {
        errors.uuid = 'Incorrect project uuid'
    }
}
projects.fieldValidator.project_id = (fields, errors) => {
    if (!fields.project_id || !helpers.onlyDigitsRegex(fields.project_id)) {
        return errors.project_id = 'No project ID'
    }
    fields.project_id = parseInt(fields.project_id.toString())
}
projects.fieldValidator.paymethod_id = (fields, errors) => {
    if (!fields.paymethod_id || !helpers.onlyDigitsRegex(fields.paymethod_id)) {
        return errors.paymethod_id = 'Please select a payment method'
    }
    fields.paymethod_id = parseInt(fields.paymethod_id.toString())
}
projects.fieldValidator.email = (fields, errors) => {
    if (typeof fields.email !== 'string' || !fields.email) {
        errors.email = 'Please insert email'
        return
    }
    helpers.emailValidator(fields, errors, 'email')
}
projects.fieldValidator.name = (fields, errors) => {
    let name = fields.name?.trim()
    if (typeof name !== 'string' || !name) {
        errors.name = 'Please insert name'
        return
    }
    if (helpers.noTagRegex.test(name)) {
        errors.name = 'Prohibited characters'
        return
    }
    if (name.length > 50 ) {
        errors.name = 'Name should be max 50 characters long'
    }
}
projects.fieldValidator.url = (fields, errors) => {
    let url = fields.url?.trim()
    if (typeof url !== 'string' || !url) {
        errors.url = 'Please insert url'
        return
    }
    if (helpers.noTagRegex.test(url)) {
        errors.url = 'Prohibited characters'
        return
    }
    if (url.length > 255 ) {
        errors.url = 'Name should be max 50 characters long'
    }
}
projects.fieldValidator.phone = (fields, errors) => {
    if (!fields.phone) {
        errors.phone = 'Please insert phone'
        return
    }
    if (fields.phone.length < 5 || fields.phone.length > 20) {
        return errors.phone ='Phone number should be between 5 and 20 digits'
    }
    fields.phone = fields.phone.trim()
}
projects.fieldValidator.webhook = (fields, errors) => {
    if (!fields.webhook) {
        return
    }
    fields.webhook = fields.webhook.trim()
    if (!helpers.isValidHttpUrl(fields.webhook)) {
        errors.webhook = 'Please insert a valid URL'
    }
    if (fields.webhook.slice(0,5) !== 'https') {
        errors.webhook = 'Only https endpoints allowed'
    }
}
projects.fieldValidator.fee = (fields, errors) => {
    if (typeof fields.fee !== "number") {
        if (!fields.fee) {
            return errors.fee = 'Please insert fee'
        }
        fields.fee = fields.fee.trim()
        if (!helpers.testDecimal(fields.fee)) {
            return errors.fee = 'Please insert an integer or decimal value'
        }
        fields.fee = parseFloat(fields.fee)
    }

    if (fields.fee < 0 || fields.fee > 100) {
        errors.fee = 'Fee should be min 0, max 100'
    }
}
projects.fieldValidator.hold_amount = (fields, errors) => {
    if (typeof fields.hold_amount !== "number") {
        if (!fields.hold_amount) {
            return errors.hold_amount = 'Please insert hold amount %'
        }
        fields.hold_amount = fields.hold_amount.trim()
        if (!helpers.testDecimal(fields.hold_amount)) {
            return errors.hold_amount = 'Please insert an integer or decimal value'
        }
        fields.hold_amount = parseFloat(fields.hold_amount)
    }

    if (fields.hold_amount < 0 || fields.hold_amount > 100) {
        errors.hold_amount = 'Hold amount should be min 0, max 100'
    }
}
projects.fieldValidator.reserve_amount = (fields, errors) => {
    if (typeof fields.reserve_amount !== "number") {
        if (!fields.reserve_amount) {
            return errors.reserve_amount = 'Please insert reserve amount %'
        }
        fields.reserve_amount = fields.reserve_amount.trim()
        if (!helpers.testDecimal(fields.reserve_amount)) {
            return errors.reserve_amount = 'Please insert an integer or decimal value'
        }
        fields.reserve_amount = parseFloat(fields.reserve_amount)
    }

    if (fields.reserve_amount < 0 || fields.reserve_amount > 100) {
        errors.reserve_amount = 'Rolling reserve amount should be min 0, max 100'
    }
}
projects.fieldValidator.dispute_cost = (fields, errors) => {
    if (typeof fields.dispute_cost !== "number") {
        if (!fields.dispute_cost) {
            return errors.dispute_cost = 'Please insert dispute cost'
        }
        fields.dispute_cost = fields.dispute_cost.trim()
        if (!helpers.onlyDigitsRegex(fields.dispute_cost)) {
            return errors.dispute_cost = 'Please insert an integer dispute cost'
        }
        fields.dispute_cost = parseInt(fields.dispute_cost)
    }

    if (fields.dispute_cost < 0) {
        errors.dispute_cost = 'Fee should be a positive integer'
    }
}
projects.fieldValidator.hold_days = (fields, errors) => {
    if (typeof fields.hold_days !== "number") {
        if (!fields.hold_days) {
            return errors.hold_days = 'Please insert hold duration in days'
        }
        fields.hold_days = fields.hold_days.trim()
        if (!helpers.onlyDigitsRegex(fields.hold_days)) {
            return errors.hold_days = 'Please insert the number of days or 0'
        }
        fields.hold_days = parseInt(fields.hold_days)
    }

    if (fields.hold_days < 0) {
        errors.hold_days = 'Hold should be a positive integer or 0'
    }
}
projects.fieldValidator.reserve_days = (fields, errors) => {
    if (typeof fields.reserve_days !== "number") {
        if (!fields.reserve_days) {
            return errors.reserve_days = 'Please insert rolling reserve duration in days'
        }
        fields.reserve_days = fields.reserve_days.trim()
        if (!helpers.onlyDigitsRegex(fields.reserve_days)) {
            return errors.reserve_days = 'Please insert the number of days or 0'
        }
        fields.reserve_days = parseInt(fields.reserve_days)
    }

    if (fields.reserve_days < 0) {
        errors.reserve_days = 'Rolling reserve should be a positive integer or 0'
    }
}

export default projects