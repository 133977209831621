import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    MenuItem,
    TextField
} from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import helpers from "../../helpers/common";
import {useEffect, useRef, useState} from "react";
import currencies from "../../models/currencies";
import useRootHook from "../../hooks/useRoot";
import * as React from "react";
import {ContentCopy} from "@mui/icons-material";
import Container from "@mui/material/Container";

export default function GetLinkDialog(props) {
    const { setDialogIsOpened, dialogIsOpened, setSelectedUser, selectedUser} = props
    const {notify, defaultCurrency} = useRootHook();
    const amountRef = useRef(null)
    const currencyRef = useRef(null)
    const [currencyData, setCurrencyData] = useState([])

    useEffect(() => {
        currencies.index()
            .then((data) => {
                setCurrencyData(data)
            })
    }, [])

    const handleClose = () => {
        setDialogIsOpened(false)
        setSelectedUser(null)
    }

    const validate = (amount, currency) => {
        if (!amount) {
            return [false, 'Please insert amount']
        }
        if (!currency) {
            return [false, 'Please select currency']
        }
        return [true]
    }

    const handleCopy = (value) => {
        const amount = amountRef.current.value
        const currency = currencyRef.current.value

        const [validated, message] = validate(amount, currency)

        if (!validated) {
            notify(message, 3000)
            return
        }

        const currencyRecord = currencyData.find((data) => {
            return data.currency = currency.toUpperCase()
        })

        const subunitAmount = parseInt(parseFloat(amount) * currencyRecord.subunit)

        const linkQueryData = new URLSearchParams({
            c: helpers.UUIDv4Transform(selectedUser.uuid),
            a: subunitAmount,
            cr: currency
        }).toString()

        const link = `https://${window.location.hostname}/payment?${linkQueryData}`

        helpers.copyTextToClipboard(link)
            .then(() => {
                notify('Link copied to Clipboard', 2000)
                handleClose()
            })
    }

    if (!currencyData) return null

    return <Dialog onClose={handleClose} open={dialogIsOpened}>
        <Container component="form" onSubmit={ev => ev.preventDefault()}>
            <DialogTitle>Create Static Payment Link</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Please select amount and currency to create a static payment link
                </DialogContentText>
                <TextField
                    inputRef={amountRef}
                    required={true}
                    autoFocus
                    margin="dense"
                    id="amount"
                    label="Amount"
                    type="number"
                    fullWidth
                    variant="standard"

                />
                <TextField
                    inputRef={currencyRef}
                    select // tell TextField to render select
                    label="Currency"
                    id="currency"
                    name="currency"
                    fullWidth
                    variant="standard"
                    defaultValue={defaultCurrency.currency}
                >
                    {
                        currencyData.map((currencyRecord) => {
                            return <MenuItem key={`id-${currencyRecord.id}`} data-subunit={currencyRecord.subunit} value={currencyRecord.currency}>{currencyRecord.currency}</MenuItem>
                        })
                    }
                </TextField>
            </DialogContent>
            <DialogActions>
                <Button type="submit" onClick={handleCopy} variant="contained" startIcon={<ContentCopy />}>
                    Copy Link
                </Button>
            </DialogActions>
        </Container>
    </Dialog>
}