import React from 'react'
import {Link as RouterLink, Navigate, Outlet, useLocation} from 'react-router-dom'
import useRootHook from "../hooks/useRoot";

const AclRouterLink = React.forwardRef((props, ref) => {
    const {userData} = useRootHook()

    const path = props.to.split('?')

    const newProps = {...props}
    delete newProps.forceRoles

    if (props.forceRoles && props.forceRoles.includes(userData.role)) {
        return <RouterLink ref={ref} {...newProps}/>
    }

    if (!props.forceRoles && userData.urls && userData.urls.includes(path[0])) {
        return <RouterLink ref={ref} {...newProps}/>
    }
    return <div style={{display: "none"}}>{props.children}</div>
})

const RequireAuth = (props) => {
    const { authed, userData } = useRootHook()
    const location = useLocation()

    const { children } = props
    let pathName = location.pathname
    if (pathName.length > 1 && pathName.slice(1) === '/') {
        pathName = pathName.slice(0, -1)
    }

    let allowed = false

    if (authed && props.forceRoles && props.forceRoles.includes(userData.role)) {
        allowed = true
    } else if (authed && !props.forceRoles && userData.urls && userData.urls.includes(pathName)) {
        allowed = true
    }

    return allowed ? (
        children ? children : <Outlet />
    ) : (
        <Navigate to="/" replace state={{ path: location.pathname }} />
    );
}

export {AclRouterLink, RequireAuth}