import * as React from 'react';
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import {useNavigate} from "react-router-dom";
import Title from "../Title";
import BreadCrumbs from "../BreadCrumbs";
import {useEffect, useState} from "react";
import useRootHook from "../../hooks/useRoot";

import helpers from "../../helpers/common";
import servers from "../../models/servers";
import ServerForm from "./ServerForm";

export default function ServerAdd(props) {
    const {setShowLoader} = useRootHook()
    const navigate = useNavigate()
    const [images, setImages] = useState([])
    const [errors, setErrors] = useState({});
    const [fields, setFields] = useState({})

    useEffect(() => {
        setShowLoader(true)
        servers.getImages()
            .then((data) => {
                setShowLoader(false)
                data && setImages(data)
                data && setFields((prevFields) => {
                    return {...prevFields, image: data[0].id}
                })
            }).catch(() => setShowLoader(false))
    }, [])

    const handleChange = (ev) => {
        const [target, value, name] = helpers.getFormEventData(ev);

        setErrors({...errors, [name]: undefined});

        setFields((prevState) => {
            let newFields = {...prevState};
            newFields[name] = value;

            return newFields
        })
    }

    const handleBlur = (ev) => {
        const [target, value, name] = helpers.getFormEventData(ev)

        if (!servers.FORM_FIELDS.includes(name)) return

        const newErrors = {...errors}
        const newFields = {...fields}

        typeof servers.fieldValidator[name] === 'function'
        && servers.fieldValidator[name](newFields, newErrors)

        setErrors(newErrors)
    }

    const handleSubmit = (ev) => {
        ev.preventDefault()
        const validationErrors = servers.methodValidator.add(fields)
        setErrors(validationErrors)

        if (Object.keys(validationErrors).length !== 0) return

        setShowLoader(true)
        servers.add(fields)
            .then(() => {
                setShowLoader(false)
                navigate('/servers')
            }).catch(() => setShowLoader(false))
    }

    if (images.length === 0) return null

    return <React.Fragment>
        <Grid item xs={12}>
            <BreadCrumbs elements={[['Dashboard', '/'], ['Servers', '/servers'], ['Add', '/servers/add']]}/>
        </Grid>
        <Grid item xs={12}>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                <Title>Add Customer</Title>
                <ServerForm images={images} fields={fields} errors={errors} handleChange={handleChange}
                            handleBlur={handleBlur} handleSubmit={handleSubmit} />
            </Paper>
        </Grid>
    </React.Fragment>
}