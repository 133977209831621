import {createTheme, ThemeProvider} from "@mui/material/styles";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Typography from "@mui/material/Typography";
import {LogoutOutlined, VisibilityOff} from "@mui/icons-material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import {MainListItems} from "./Menu";
import * as React from "react";
import useRootHook from "../hooks/useRoot";
import Dashboard from "./Dashboard";
import {Route, Routes, useLocation, useNavigate} from "react-router-dom";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import TransactionContainer from "./transactions/TransactionContainer";
import {RequireAuth} from './ACL'
import AppBar from './AppBar'
import Drawer from './Drawer'
import StatsIndex from "./stats/StatsIndex";
import ConfirmDialog from "./ConfirmDialog";
import helpers from "../helpers/common";
import CustomerAdd from "./customers/CustomerAdd";
import CustomerIndex from "./customers/CustomerIndex";
import CustomerEdit from "./customers/CustomerEdit";
import CurrencyIndex from "./currencies/CurrencyIndex";
import CurrencyEdit from "./currencies/CurrencyEdit";
import CurrencyAdd from "./currencies/CurrencyAdd";
import ServerIndex from "./servers/ServerIndex";
import ServerEdit from "./servers/ServerEdit";
import ServerAdd from "./servers/ServerAdd";
import PayMethodIndex from "./paymethods/PayMethodIndex";
import PayMethodEdit from "./paymethods/PayMethodEdit";
import PayMethodAdd from "./paymethods/PayMethodAdd";
import merchants from "../models/merchants";
import admins from "../models/admins";
import AdminIndex from "./admins/AdminIndex";
import AdminAdd from "./admins/AdminAdd";
import AdminEdit from "./admins/AdminEdit";
import Clock from "./Clock";
import MerchantIndex from "./merchants/MerchantIndex";
import MerchantEdit from "./merchants/MerchantEdit";
import MerchantAdd from "./merchants/MerchantAdd";
import ProjectIndex from "./projects/ProjectIndex";
import ProjectEdit from "./projects/ProjectEdit";
import ProjectAdd from "./projects/ProjectAdd";
import {Chip} from "@mui/material";
import PayoutIndex from "./payouts/PayoutIndex";
import PayoutEdit from "./payouts/PayoutEdit";
import PayoutAdd from "./payouts/PayoutAdd";
import PayoutMethodIndex from "./payout-methods/PayoutMethodIndex";
import PayoutMethodEdit from "./payout-methods/PayoutMethodEdit";
import PayoutMethodAdd from "./payout-methods/PayoutMethodAdd";

const mdTheme = createTheme()

export default function Main() {
    const {setAuthed, userData, setUserData, setShowLoader, forcedMerchantID, removeForcedMerchant} = useRootHook();
    const [open, setOpen] = React.useState(true)
    const [confirmOpen, setConfirmOpen] = React.useState(false)
    const navigate = useNavigate()
    const location = useLocation()

    const toggleDrawer = () => {
        setOpen(!open)
    }
    const confirmClearMerchant = () => {
        const confirmed = window.confirm('Clear merchant?')
        if (confirmed) {
            removeForcedMerchant()
            const oldPath = location.pathname
            navigate('/')
            oldPath === '/' && window.location.reload()
        }
    }

    const MerchantIDInfo = () => {
        if (!forcedMerchantID) return ''

        return <Chip label={`MID ${forcedMerchantID}`} deleteIcon={<VisibilityOff />} color="primary" onDelete={confirmClearMerchant} />
    }

    const handleLogout = () => {
        setShowLoader(true)
        const logoutModel = userData.role === helpers.roles.M ? merchants : admins
        logoutModel.logout().then(() => {
            setShowLoader(false)
            setAuthed(false)
            setUserData(false)
        })
    }

    return (
        <ThemeProvider theme={mdTheme}>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <AppBar position="absolute" open={open} color={forcedMerchantID ? 'default' : 'primary'}>
                    <Toolbar
                        sx={{
                            pr: '24px', // keep right padding when drawer closed
                        }}
                    >
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            onClick={toggleDrawer}
                            sx={{
                                marginRight: '36px',
                                ...(open && { display: 'none' }),
                            }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Typography
                            component="h1"
                            variant="h6"
                            color="inherit"
                            noWrap
                            sx={{ flexGrow: 1 }}
                        >
                            Atlas 24
                            {' '}
                            <MerchantIDInfo />
                        </Typography>
                        <Clock />
                        <IconButton color="inherit" onClick={() => setConfirmOpen(true)}>
                            <LogoutOutlined />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <Drawer variant="permanent" open={open}>
                    <Toolbar
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            px: [1],
                        }}
                    >
                        <IconButton onClick={toggleDrawer}>
                            <ChevronLeftIcon />
                        </IconButton>
                    </Toolbar>
                    <Divider />
                    <List component="nav">
                        <MainListItems />
                        <Divider sx={{ my: 1 }} />
                        {/*{userData.role === helpers.roles.M && <SecondaryListItems />}*/}
                    </List>
                </Drawer>
                <Box
                    component="main"
                    sx={{
                        backgroundColor: (theme) =>
                            theme.palette.mode === 'light'
                                ? theme.palette.grey[100]
                                : theme.palette.grey[900],
                        flexGrow: 1,
                        height: '100vh',
                        overflow: 'auto',
                    }}
                >
                    <Toolbar />
                    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                        <Grid container spacing={3}>
                            <Routes>
                                <Route path="/*" element={<RequireAuth />}>
                                    <Route index element={<Dashboard />} />
                                    <Route path="stats" element={<StatsIndex />} />
                                    <Route path="merchants">
                                        <Route index element={<MerchantIndex />} />
                                        <Route path="edit" element={<RequireAuth />}>
                                            <Route index element={<MerchantEdit />} />
                                        </Route>
                                        <Route path="add" element={<RequireAuth />}>
                                            <Route index element={<MerchantAdd />} />
                                        </Route>
                                    </Route>
                                    <Route path="projects">
                                        <Route index element={<ProjectIndex />} />
                                        <Route path="edit" element={<RequireAuth />}>
                                            <Route index element={<ProjectEdit />} />
                                        </Route>
                                        <Route path="add" element={<RequireAuth />}>
                                            <Route index element={<ProjectAdd />} />
                                        </Route>
                                    </Route>
                                    <Route path="transactions" element={<RequireAuth />} >
                                        <Route index element={<TransactionContainer />} />
                                    </Route>
                                    <Route path="customers">
                                        <Route index element={<CustomerIndex />} />
                                        <Route path="edit" element={<RequireAuth />}>
                                            <Route index element={<CustomerEdit />} />
                                        </Route>
                                        <Route path="add" element={<RequireAuth />}>
                                            <Route index element={<CustomerAdd />} />
                                        </Route>
                                    </Route>
                                    <Route path="currencies" element={<RequireAuth forceRoles={[helpers.roles.S, helpers.roles.A]}/>} >
                                        <Route index element={<CurrencyIndex />} />
                                        <Route path="edit" element={<CurrencyEdit />} />
                                        <Route path="add" element={<CurrencyAdd />}/>
                                    </Route>
                                    <Route path="paymethods">
                                        <Route index element={<PayMethodIndex />} />
                                        <Route path="edit" element={<RequireAuth />}>
                                            <Route index element={<PayMethodEdit />} />
                                        </Route>
                                        <Route path="add" element={<RequireAuth />}>
                                            <Route index element={<PayMethodAdd />} />
                                        </Route>
                                    </Route>
                                    <Route path="payouts">
                                        <Route index element={<PayoutIndex />} />
                                        <Route path="edit" element={<RequireAuth />}>
                                            <Route index element={<PayoutEdit />} />
                                        </Route>
                                        <Route path="add" element={<RequireAuth />}>
                                            <Route index element={<PayoutAdd />} />
                                        </Route>
                                    </Route>
                                    <Route path="payout-methods">
                                        <Route index element={<PayoutMethodIndex />} />
                                        <Route path="edit" element={<RequireAuth />}>
                                            <Route index element={<PayoutMethodEdit />} />
                                        </Route>
                                        <Route path="add" element={<RequireAuth />}>
                                            <Route index element={<PayoutMethodAdd />} />
                                        </Route>
                                    </Route>
                                    <Route path="servers" element={<RequireAuth />} >
                                        <Route index element={<ServerIndex />} />
                                        <Route path="edit" element={<ServerEdit />} />
                                        <Route path="add" element={<ServerAdd />}/>
                                    </Route>
                                    <Route path="admins" element={<RequireAuth />} >
                                        <Route index element={<AdminIndex />} />
                                        <Route path="edit" element={<AdminEdit />} />
                                        <Route path="add" element={<AdminAdd />}/>
                                    </Route>

                                </Route>
                            </Routes>
                        </Grid>
                    </Container>
                </Box>
            </Box>
            <ConfirmDialog title="Please confirm you want to logout" confirmOpen={confirmOpen} setConfirmOpen={setConfirmOpen} onConfirm={handleLogout} />
        </ThemeProvider>
    );
}

//todo remove M role from currencies