import useRootHook from "../hooks/useRoot";
import {Snackbar} from "@mui/material";
import * as React from "react";
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';

export default function Notification () {
    const {showNotification, setShowNotification, notificationMessage, setNotificationMessage, notificationTimeout} = useRootHook();

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setShowNotification(false)
        setNotificationMessage('')
    }

    const action = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    )

    return <Snackbar
        open={showNotification}
        autoHideDuration={notificationTimeout}
        onClose={handleClose}
        message={notificationMessage}
        action={action}
    />
}