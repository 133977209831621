import * as React from 'react';
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import {useNavigate} from "react-router-dom";
import Title from "../Title";
import BreadCrumbs from "../BreadCrumbs";
import {useEffect, useState} from "react";
import useRootHook from "../../hooks/useRoot";
import helpers from "../../helpers/common";
import paymethods from "../../models/paymethods";
import paysystems from "../../models/paysystems";
import PayMethodForm from "./PayMethodForm";

export default function PayMethodAdd(props) {
    const {setShowLoader} = useRootHook()
    const navigate = useNavigate()
    const [paySystemsData, setPaySystemsData] = useState([])
    const [mapper, setMapper] = useState({
        keyToFieldMapper: {},
        fieldToKeyMapper: {}
    })
    const [errors, setErrors] = useState({});
    const [fields, setFields] = useState({enabled: true})

    useEffect(() => {
        setShowLoader(true)
        paysystems.getData({})
            .then((data) => {
                setShowLoader(false)
                data && setPaySystemsData(data)
                data && setFields({paysystem_id: data[0].id})
            }).catch(() => setShowLoader(false))
    }, [])

    useEffect(() => {
        if (!paySystemsData || paySystemsData.length === 0) return
        setMapperByRecordID(paySystemsData[0].id)
    }, [paySystemsData])

    const setMapperByRecordID = (id) => {
        const record = paySystemsData.find(system => system.id === id)
        if (!record || !record.paymethod_field_mapper) return
        const recordMapper = record.paymethod_field_mapper
        setMapper({keyToFieldMapper: recordMapper, fieldToKeyMapper: helpers.invertObject(recordMapper)})
    }

    const handleChange = (ev) => {
        const [target, value, name] = helpers.getFormEventData(ev);

        console.log(value, name)

        if (name === 'paysystem_id') setMapperByRecordID(parseInt(value))

        setErrors({...errors, [name]: undefined});

        setFields((prevState) => {
            let newFields = {...prevState};
            newFields[name] = value;

            return newFields
        })
    }

    const handleBlur = (ev) => {
        const [target, value, name] = helpers.getFormEventData(ev)
        const newErrors = {...errors}
        const newFields = {...fields}

        typeof paymethods.fieldValidator[name] === 'function'
        && paymethods.fieldValidator[name](newFields, newErrors)

        console.log(newErrors)
        setErrors(newErrors)
    }

    const handleSubmit = (ev) => {
        ev.preventDefault()
        const validationErrors = paymethods.methodValidator.add(fields)
        console.log(fields, validationErrors)
        setErrors(validationErrors)

        if (Object.keys(validationErrors).length !== 0) return

        setShowLoader(true)
        paymethods.add(fields)
            .then(() => {
                setShowLoader(false)
                navigate('/paymethods')
            }).catch(() => setShowLoader(false))
    }

    if (!paySystemsData || paySystemsData.length === 0) return null


    return <React.Fragment>
        <Grid item xs={12}>
            <BreadCrumbs elements={[['Dashboard', '/'], ['Methods', '/paymethods'], ['Add', '/paymethods/add']]}/>
        </Grid>
        <Grid item xs={12}>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                <Title>Add Payment Method</Title>
                <PayMethodForm paySystemsData={paySystemsData} mapper={mapper} fields={fields} errors={errors} handleChange={handleChange}
                               handleBlur={handleBlur} handleSubmit={handleSubmit} />
            </Paper>
        </Grid>
    </React.Fragment>
}