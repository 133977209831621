import * as React from 'react';
import {Link, useLocation} from 'react-router-dom';
import MuiPagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';

export default function Pagination({url, count}) {
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const page = parseInt(query.get('page') || '1', 10);

    const getPaginationItem = (item) => {
        if (item.page !== 1) {
            query.set('page', item.page)
        } else {
            query.delete('page')
        }

        return '?' + query.toString()
    }
    return (
        <MuiPagination
            sx={{mt: 2}}
            page={page}
            color="primary"
            count={count}
            renderItem={(item) => (
                <PaginationItem
                    component={Link}
                    to={`${url}${getPaginationItem(item)}`}
                    {...item}
                />
            )}
        />
    )
}

