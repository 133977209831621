import helpers from "../helpers/common";
import request from "../helpers/request";

const methods = {}

methods.FORM_FIELDS = ['id','paysystem_id', 'name', 'enabled']
methods.filterFormFields = (customerData, mapperFields) => {
    const allFields = methods.FORM_FIELDS.concat(mapperFields)
    return Object.keys(customerData)
        .filter(key => allFields.includes(key))
        .reduce((obj, key) => {
            obj[key] = customerData[key];
            return obj;
        }, {})
}

methods.index = async (filters = {}) => {
    return request.get('/payout-methods', filters, true)
}
methods.list = async (filters = {}) => {
    return request.get('/payout-methods/list', filters, true)
}

methods.get = async (id) => {
    return request.get('/payout-methods/get', {id}, true)
}

methods.add = async (data) => {
    return request.post('/payout-methods/add', data, true)
}

methods.edit = async (data) => {
    return request.put('/payout-methods/edit', data, true)
}

methods.methodValidator = {}
methods.methodValidator.add = (fields, errors = {}) => {
    methods.fieldValidator.fee(fields, errors)
    methods.fieldValidator.name(fields, errors)
    return errors
}
methods.methodValidator.edit = (fields, errors = {}) => {
    methods.fieldValidator.fee(fields, errors)
    methods.fieldValidator.name(fields, errors)
    return errors
}

methods.fieldValidator = {}
methods.fieldValidator.id = (fields, errors) => {
    if (!fields.id) {
        return errors.id = 'Incorrect currency ID'
    }
    fields.id = parseInt(fields.id)
}
methods.fieldValidator.name = (fields, errors) => {
    if (!fields.name) {
        return errors.name = 'Please insert a name'
    }
    fields.name = fields.name.trim()
    if (fields.name.length < 3) {
        return errors.name = 'Please insert at least 3 characters'
    }
}
methods.fieldValidator.fee = (fields, errors) => {
    console.log(fields)
    if (typeof fields.fee === 'string' && !fields.fee) {
        return errors.fee = 'Please insert a fee'
    }
    if (typeof fields.fee === 'string') {
        fields.fee = fields.fee.trim()
        fields.fee = parseFloat(fields.fee)
    }
    if (fields.fee < 0 && fields.fee > 100) {
        return errors.fee = 'Fee should be greater than 0 and less than 100'
    }
}

export default methods