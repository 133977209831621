import Grid from "@mui/material/Grid";
import {Checkbox, FormControlLabel, FormHelperText, InputLabel, TextField} from "@mui/material";
import Button from "@mui/material/Button";
import {Add} from "@mui/icons-material";
import * as React from "react";

export default function PayoutMethodForm ({fields, errors, handleChange, handleBlur, handleSubmit}) {
    return <React.Fragment>
        <Grid container spacing={2}
              component="form"
              onSubmit={handleSubmit}
              autoComplete="off"
        >
            <Grid item xs={12} md={4}>
                <InputLabel>Name</InputLabel>
                <TextField
                    error={!!errors.name}
                    value={fields.name || ""}
                    name="name"
                    id="name"
                    required={true}
                    variant="standard"
                    fullWidth={true}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                {errors.name && <FormHelperText>{errors.name}</FormHelperText>}
            </Grid>
            <Grid item xs={12} md={4}>
                <InputLabel>Fee</InputLabel>
                <TextField
                    error={!!errors.fee}
                    value={fields.fee || ""}
                    name="fee"
                    id="fee"
                    required={true}
                    variant="standard"
                    size="small"
                    fullWidth={true}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder={'Ex: 1.5'}
                />
                {errors.fee && <FormHelperText>{errors.fee}</FormHelperText>}
            </Grid>
            <Grid item xs={12}>
                {<FormControlLabel
                    control={
                        <Checkbox
                            name="enabled"
                            checked={typeof fields.enabled === 'boolean' ? fields.enabled : false}
                            onChange={handleChange}
                            value={typeof fields.enabled === 'boolean' ? fields.enabled : false}
                            color="primary"
                        />
                    }
                    label="Enabled"
                />}
            </Grid>
            <Grid container item spacing={3} sx={{mt: 2}}>
                <Grid item xs={6}>
                    <Button type="submit" onClick={handleSubmit} variant="outlined" startIcon={<Add />} >
                        Submit
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    </React.Fragment>
}
