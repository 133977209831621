import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import {FormControl, InputLabel, OutlinedInput, Select, Tab, Tabs} from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Slide from '@mui/material/Slide';
import transactions from '../../models/transactions';
import {useEffect} from 'react';
import useRootHook from '../../hooks/useRoot';
import helpers from '../../helpers/common';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
})

export default function TransactionStatusDialog(props) {
    const { setDialogIsOpened, dialogIsOpened, setSelectedTransaction, selectedTransaction, refresh, closeDetailsDialog} = props;
    const [status, setStatus] = React.useState(null);
    const {setShowLoader, notify} = useRootHook()

    const handleChange = (event) => {
        setStatus(event.target.value);
    };

    const handleClose = () => {
        setDialogIsOpened(false)
        setSelectedTransaction(null)
    }

    const handleSubmit = () => {
        if (selectedTransaction.status === status) {
            handleClose();
            return;
        };
        setShowLoader(true);
        transactions.setStatus(helpers.UUIDv4Transform(selectedTransaction.uuid), status)
            .then(() => {
                setShowLoader(false);
                notify('Status changed');
                refresh();
                handleClose();
                closeDetailsDialog();
            });
    }

    const descriptionElementRef = React.useRef(null);
    React.useEffect(() => {
        if (dialogIsOpened) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [dialogIsOpened])

    useEffect(() => {
        if (!selectedTransaction) return;
        setStatus(selectedTransaction.status)
    }, [selectedTransaction]);

    if (!selectedTransaction) return null

    return (
        <div>
            <Dialog
                open={dialogIsOpened}
                onClose={handleClose}
                TransitionComponent={Transition}
                keepMounted
                scroll="paper"
                aria-labelledby="transaction-details-title"
                aria-describedby="transaction-details"
                maxWidth='md'
            >
                <DialogTitle>Transaction status change</DialogTitle>
                <DialogContent dividers={true} sx={{p: 0, borderTop: "none"}}>
                    <Box sx={{ width: '100%' }}>
                        <FormControl sx={{ m: 1, minWidth: 200 }}>
                            <InputLabel htmlFor="demo-dialog-native">Status</InputLabel>
                            <Select
                                native
                                value={status}
                                onChange={handleChange}
                                input={<OutlinedInput label="Status" id="status-change-dialog" />}
                            >
                                {
                                    Object.keys(transactions.enumToStatus).map(status => {
                                        return <option key={status} value={status}>{transactions.enumToStatus[status]}</option>
                                    })
                                }
                            </Select>
                        </FormControl>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                    <Button onClick={handleSubmit}>OK</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}