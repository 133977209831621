import * as React from 'react';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from '../Title';
import {useEffect, useState} from "react";
import methods from '../../models/payout-methods'
import helpers from "../../helpers/common";
import useRootHook from "../../hooks/useRoot";
import {
    Add,
    Check,
} from "@mui/icons-material";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import {Link as RouterLink, useNavigate} from "react-router-dom";
import Paper from "@mui/material/Paper";
import {Stack} from "@mui/material";
import BreadCrumbs from "../BreadCrumbs";
import {AclRouterLink} from "../ACL";

export default function PayoutMethodIndex() {
    const [payoutMethodsData, setPayoutMethodsData] = useState(null)
    const {setShowLoader} = useRootHook()
    const navigate = useNavigate()

    useEffect(() => {
        setShowLoader(true)
        methods.index()//booleans are stringified to 'true' or 'false' so check validator and casting function on API
            .then((data) => {
                data && setPayoutMethodsData(data)
                setShowLoader(false)
            }).catch(() => setShowLoader(false))
    }, [])

    if (!payoutMethodsData) return null

    return <React.Fragment>
        <Grid item xs={12}>
            <BreadCrumbs elements={[['Dashboard', '/'], ['Payout Methods', '/payout-methods']]}/>
        </Grid>
        <Grid item xs={12}>
            <Stack spacing={2} direction="row">
                <Button to="/payout-methods/add" component={AclRouterLink} variant="contained" startIcon={<Add />}>
                    Add Payout Method
                </Button>
            </Stack>
        </Grid>
        <Grid item xs={12}>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', overflowX: 'auto'}}>
                <Title>Payout Methods</Title>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Method</TableCell>
                            <TableCell>Fee</TableCell>
                            <TableCell>Enabled</TableCell>
                            <TableCell>Created</TableCell>
                            <TableCell>Modified</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {payoutMethodsData.map((row) => (
                            <TableRow key={`payout-${row.id}`}>
                                <TableCell>
                                    {row.id}
                                </TableCell>
                                <TableCell>
                                    <Link to={`edit?id=${row.id}`} component={RouterLink}>
                                        {row.name}
                                    </Link>
                                </TableCell>
                                <TableCell>
                                    {row.fee}
                                </TableCell>
                                <TableCell>{row.enabled ? <Check  fontSize="small" /> : ''}</TableCell>
                                <TableCell>{helpers.getDate(row.created)}</TableCell>
                                <TableCell>{helpers.getDate(row.modified)}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Paper>
        </Grid>
    </React.Fragment>
}

