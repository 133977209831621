import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Title from "../Title";
import * as React from "react";
import BreadCrumbs from "../BreadCrumbs";
import {useEffect, useState} from "react";
import useRootHook from "../../hooks/useRoot";
import helpers from "../../helpers/common";
import currencies from "../../models/currencies";
import CurrencyForm from "./CurrencyForm";

export default function CurrencyEdit() {
    const [searchParams] = useSearchParams();
    const {setShowLoader} = useRootHook()
    const navigate = useNavigate()
    const [currencyData, setCurrencyData] = useState(null)

    const [fields, setFields] = useState({})
    const [errors, setErrors] = useState({});

    useEffect(() => {
        setShowLoader(true)

        currencies.get(searchParams.get('id'))
            .then((currency) => {
                setShowLoader(false)
                if (!currency) return
                currency && setCurrencyData(currency)

                const fieldsData = {
                    id: currency.id,
                    currency: currency.currency,
                    subunit: currency.subunit.toString(),
                    symbol: currency.symbol
                }
                setFields(fieldsData)
            }).catch(() => setShowLoader(false))

    }, [])

    const handleChange = (ev) => {
        const [target, value, name] = helpers.getFormEventData(ev);

        setErrors({...errors, [name]: undefined});

        setFields((prevState) => {
            let newFields = {...prevState};
            newFields[name] = value;

            return newFields
        })
    }

    const handleBlur = (ev) => {
        const [target, value, name] = helpers.getFormEventData(ev)

        const newErrors = {...errors}
        const newFields = {...fields}

        typeof currencies.fieldValidator[name] === 'function'
        && currencies.fieldValidator[name](newFields, newErrors)

        setErrors(newErrors)
    }

    const handleSubmit = (ev) => {
        ev.preventDefault()
        const validationErrors = currencies.methodValidator.edit(fields)
        setErrors(validationErrors)

        if (Object.keys(validationErrors).length !== 0) return

        setShowLoader(true)
        currencies.edit(fields)
            .then(() => {
                setShowLoader(false)
                navigate('/currencies')
            }).catch(() => setShowLoader(false))
    }

    if (!currencyData) return null

    return <React.Fragment>
        <Grid item xs={12}>
            <BreadCrumbs elements={[['Dashboard', '/'], ['Currencies', '/currencies'], ['Edit', '/currencies/edit']]}/>
        </Grid>
        <Grid item xs={12}>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                <Title>Edit Currency</Title>
                <CurrencyForm fields={fields} errors={errors} handleChange={handleChange}
                              handleBlur={handleBlur} handleSubmit={handleSubmit} />
            </Paper>
        </Grid>
    </React.Fragment>
}