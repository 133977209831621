import * as React from 'react';
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import {useNavigate} from "react-router-dom";
import Title from "../Title";
import BreadCrumbs from "../BreadCrumbs";
import {useEffect, useState} from "react";
import useRootHook from "../../hooks/useRoot";
import methods from "../../models/payout-methods";
import payouts from "../../models/payouts";
import helpers from "../../helpers/common";
import PayoutForm from "./PayoutForm";

export default function PayoutAdd(props) {
    const {setShowLoader} = useRootHook()
    const navigate = useNavigate()
    const [errors, setErrors] = useState({})
    const [fields, setFields] = useState({})
    const [payoutMethods, setPayoutMethods] = useState(null)
    //const [selectedMethod, setSelectedMethod] = useState(null)

    useEffect(() => {
        methods.list()
            .then((data) => {
                data && setPayoutMethods(data)
            })
    }, [])

    /*useEffect(() => {

    }, [selectedMethod])*/

    const handleChange = (ev) => {
        const [target, value, name] = helpers.getFormEventData(ev);

        setErrors({...errors, [name]: undefined});

        setFields((prevState) => {
            let newFields = {...prevState};
            newFields[name] = value;

            return newFields
        })
    }

    const handleBlur = (ev) => {
        const [target, value, name] = helpers.getFormEventData(ev)

        const newErrors = {...errors}
        const newFields = {...fields}

        typeof payouts.fieldValidator[name] === 'function'
        && payouts.fieldValidator[name](newFields, newErrors)

        setErrors(newErrors)
        /*if (name === 'method_id' && !errors['method_id']) {
            payoutMethods.every((method) => {
                if (method.id === value) {
                    setSelectedMethod(method)
                    return false
                }
                return true
            })
        }*/
    }

    const handleSubmit = (ev) => {
        ev.preventDefault()
        const validationErrors = payouts.methodValidator.add(fields)
        setErrors(validationErrors)

        if (Object.keys(validationErrors).length !== 0) return

        setShowLoader(true)
        payouts.add(fields)
            .then(() => {
                setShowLoader(false)
                navigate('/payouts')
            }).catch(() => setShowLoader(false))
    }

    if (!payoutMethods) return null

    return <React.Fragment>
        <Grid item xs={12}>
            <BreadCrumbs elements={[['Dashboard', '/'], ['Payouts', '/payouts'], ['Add', '/payouts/add']]}/>
        </Grid>
        <Grid item xs={12}>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                <Title>Request Payout</Title>
                <PayoutForm payoutMethods={payoutMethods} fields={fields} errors={errors} handleChange={handleChange}
                            handleBlur={handleBlur} handleSubmit={handleSubmit} />
            </Paper>
        </Grid>
    </React.Fragment>
}