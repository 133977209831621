import * as React from 'react';
import useRootHook from './hooks/useRoot'
import {Routes, Route, useSearchParams, Navigate} from "react-router-dom";

import Login, {TYPE_ADMIN, TYPE_KEY} from "./components/Login";
import {useEffect, useState} from "react";

import Main from "./components/Main";
import Loader from './components/Loader'
import ForgotPassword from "./components/ForgotPassword";
import Notification from "./components/Notification";
import Payment from "./components/Payment"
import merchants from "./models/merchants";

export default function App() {
    const { authed, setAuthed, userData, setUserData, setShowLoader, setDefaultCurrency, setForcedMerchant} = useRootHook();
    const [isInited, setIsInited] = useState(false)
    const [searchParams] = useSearchParams()

    useEffect(() => {
        setShowLoader(true)
        if (window.location.protocol !== 'https:' && process.env.NODE_ENV !== 'development') {
            //window.location.replace(`https:${window.location.href.substring(window.location.protocol.length)}`)
            //return
        }
        merchants.init().then((data) => {
            setShowLoader(false)
            data && data.username && setAuthed(true)
            data && data.username && setUserData(data)
            data && data.defaultCurrency && setDefaultCurrency(data.defaultCurrency)

            //we set here the userData because getting it from the hook may result in old or null data since hooks are async
            searchParams.has('force_merchant_id') && setForcedMerchant(searchParams.get('force_merchant_id'), data)

            setIsInited(true)
        }).catch(() => setShowLoader(false))
    }, [])

    if (!isInited) return null

    return <React.Fragment>
        <Routes>
            <Route path="/*" element={authed ? <Main /> : <Navigate replace to={localStorage.getItem(TYPE_KEY) === TYPE_ADMIN ? '/login/admin' : 'login'} />} />
            <Route path="/login/*" element={<Login />} />
            <Route path="/forgot-password/*" element={<ForgotPassword />} />
            <Route path="/payment" element={<Payment />} />
        </Routes>
        <Loader />
        <Notification />
    </React.Fragment>
}

