import Grid from "@mui/material/Grid";
import {Checkbox, FormControlLabel, FormHelperText, InputLabel, MenuItem, Select, TextField} from "@mui/material";
import Button from "@mui/material/Button";
import {Add} from "@mui/icons-material";
import * as React from "react";

export default function AdminForm ({fields, errors, handleChange, handleBlur, handleSubmit}) {
    return <React.Fragment>
        <Grid container spacing={2}
              component="form"
              onSubmit={handleSubmit}
              autoComplete="off"
        >
            <Grid item xs={12} md={6}>
                <InputLabel>Email</InputLabel>
                <TextField
                    error={!!errors.username}
                    value={fields.username || ""}
                    name="username"
                    id="username"
                    required={true}
                    variant="standard"
                    fullWidth={true}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                {errors.username && <FormHelperText>{errors.username}</FormHelperText>}
            </Grid>
            <Grid item xs={12} md={6}>
                <InputLabel>Password</InputLabel>
                <TextField
                    error={!!errors.password}
                    value={fields.password || ""}
                    type="password"
                    autoComplete="new-password"
                    name="password"
                    id="password"
                    required={true}
                    variant="standard"
                    size="small"
                    fullWidth={true}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder={fields.id ? 'Keep old password' : 'A-Z a-z 0-9 and !@#$%^&*() =+_-'}
                />
                {errors.password && <FormHelperText>{errors.password}</FormHelperText>}
            </Grid>
            <Grid item xs={12}>
                {<FormControlLabel
                    control={
                        <Checkbox
                            name="super"
                            checked={typeof fields.super === 'boolean' ? fields.super : false}
                            onChange={handleChange}
                            value={typeof fields.super === 'boolean' ? fields.super : false}
                            color="primary"
                        />
                    }
                    label="Super Admin"
                />}
            </Grid>
            <Grid container item spacing={3} sx={{mt: 2}}>
                <Grid item xs={6}>
                    <Button type="submit" onClick={handleSubmit} variant="outlined" startIcon={<Add />} >
                        Submit
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    </React.Fragment>
}
