import Grid from "@mui/material/Grid";
import {Checkbox, FormControlLabel, FormHelperText, InputLabel, MenuItem, Select, TextField} from "@mui/material";
import Button from "@mui/material/Button";
import {Add} from "@mui/icons-material";
import * as React from "react";

export default function PayMethodForm ({paySystemsData, fields, mapper, errors, handleChange, handleBlur, handleSubmit, project_id}) {
    return <React.Fragment>
        <Grid container spacing={2}
              component="form"
              onSubmit={handleSubmit}
              autoComplete="off"
        >
            <Grid item xs={12} md={6}>
                <InputLabel>Payment System</InputLabel>
                <Select
                    fullWidth={true}
                    error={!!errors.paysystem_id}
                    required={true}
                    name="paysystem_id"
                    variant="standard"
                    id="paysystem_id"
                    value={fields.paysystem_id || ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    readOnly={!!project_id}
                >
                    {
                        paySystemsData.map((system, index) => {
                            return <MenuItem key={`id-${system.id}`} value={system.id}>{system.name}</MenuItem>
                        })
                    }
                </Select>
                {errors.paysystem_id && <FormHelperText>{errors.paysystem_id}</FormHelperText>}
            </Grid>
            <Grid item xs={12} md={6}>
                <InputLabel>Name</InputLabel>
                <TextField
                    error={!!errors.name}
                    value={fields.name || ""}
                    name="name"
                    id="name"
                    required={true}
                    variant="standard"
                    fullWidth={true}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                {errors.name && <FormHelperText>{errors.name}</FormHelperText>}
            </Grid>
            {
                Object.keys(mapper.keyToFieldMapper).map((key) => {
                    console.log(key, mapper.keyToFieldMapper[key])
                    return <Grid key={mapper.keyToFieldMapper[key]} item xs={12} md={6}>
                        <InputLabel>{key}</InputLabel>
                        <TextField
                            error={!!errors[mapper.keyToFieldMapper[key]]}
                            value={fields[mapper.keyToFieldMapper[key]] || ""}
                            name={mapper.keyToFieldMapper[key]}
                            id={mapper.keyToFieldMapper[key]}
                            required={true}
                            variant="standard"
                            size="small"
                            fullWidth={true}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                        {errors[mapper.keyToFieldMapper[key]] && <FormHelperText>{errors[mapper.keyToFieldMapper[key]]}</FormHelperText>}
                    </Grid>
                })
            }
            <Grid item xs={6} md={2}>
                <InputLabel>Min Amount</InputLabel>
                <TextField
                    error={!!errors.min_amount}
                    value={fields.min_amount}
                    name="min_amount"
                    id="min_amount"
                    required={true}
                    variant="standard"
                    fullWidth={true}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                {errors.min_amount && <FormHelperText>{errors.min_amount}</FormHelperText>}
            </Grid>
            <Grid item xs={6} md={2}>
                <InputLabel>Max Amount</InputLabel>
                <TextField
                    error={!!errors.max_amount}
                    value={fields.max_amount}
                    name="max_amount"
                    id="max_amount"
                    required={true}
                    variant="standard"
                    fullWidth={true}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                {errors.max_amount && <FormHelperText>{errors.max_amount}</FormHelperText>}
            </Grid>
            <Grid item xs={6} md={2}>
                <InputLabel>Max Day Total</InputLabel>
                <TextField
                    error={!!errors.max_day_total}
                    value={fields.max_day_total}
                    name="max_day_total"
                    id="max_day_total"
                    required={true}
                    variant="standard"
                    fullWidth={true}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                {errors.max_day_total && <FormHelperText>{errors.max_day_total}</FormHelperText>}
            </Grid>
            <Grid item xs={3} md={2}>
                {<FormControlLabel
                    control={
                        <Checkbox
                            name="enabled"
                            checked={typeof fields.enabled === 'boolean' ? fields.enabled : true}
                            onChange={handleChange}
                            value={typeof fields.enabled === 'boolean' ? fields.enabled : true}
                            color="primary"
                        />
                    }
                    label="Enabled"
                />}
            </Grid>
            <Grid item xs={3} md={2}>
                {<FormControlLabel
                    control={
                        <Checkbox
                            name="available"
                            checked={typeof fields.available === 'boolean' ? fields.available : true}
                            onChange={handleChange}
                            value={typeof fields.available === 'boolean' ? fields.available : true}
                            color="primary"
                        />
                    }
                    label="Available"
                />}
            </Grid>
            <Grid container item spacing={3} sx={{mt: 2}}>
                <Grid item xs={6}>
                    <Button type="submit" onSubmit={handleSubmit} onClick={handleSubmit} variant="outlined" startIcon={<Add />} >
                        Submit
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    </React.Fragment>
}
