import * as React from "react";
import Grid from "@mui/material/Grid";
import {
    FormHelperText, Input,
    InputAdornment,
    InputLabel,
    TextField
} from "@mui/material";
import Button from "@mui/material/Button";
import {Refresh, ContentCopy, Save} from "@mui/icons-material";
import helpers from "../../helpers/common";
import projects from "../../models/projects";
import {useEffect, useState} from "react";
import useRootHook from "../../hooks/useRoot";
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";

export default function ProjectEditAPI ({project, refresh}) {
    const [fields, setFields] = useState({...project})
    const [errors, setErrors] = useState({})
    const {setShowLoader, notify, userData} = useRootHook()

    useEffect(() => {
        if (!project) return
        if (!project.secret_key) {
            setNewKey()
        }
        setFields({
            id: project.id,
            secret_key: project.secret_key,
            webhook: project.webhook
        })
    }, [project])

    const setNewKey = () => {
        projects.setNewSecretKey(project.id)
            .then(() => {
                refresh()
            })
            .catch()
    }

    const handleCopy = (text) => {
        helpers.copyTextToClipboard(text)
            .then(() => {
                notify('Key copied to Clipboard', 2000)
            })
    }

    if (!project) return null

    const handleChange = (ev) => {
        const [target, value, name] = helpers.getFormEventData(ev);

        setErrors({...errors, [name]: undefined});

        setFields((prevState) => {
            let newFields = {...prevState}
            newFields[name] = value

            return newFields
        })
    }

    const handleBlur = (ev) => {
        const [target, value, name] = helpers.getFormEventData(ev)

        const newErrors = {...errors}
        const newFields = {...fields}

        typeof projects.fieldValidator[name] === 'function'
        && projects.fieldValidator[name](newFields, newErrors)

        setFields(newFields)

        setErrors(newErrors)
    }

    const handleSubmit = (ev) => {
        ev.preventDefault()
        const validationErrors = projects.methodValidator.editAPI(fields)
        setErrors(validationErrors)

        if (Object.keys(validationErrors).length !== 0) return

        setShowLoader(true)
        projects.editAPI(fields)
            .then(() => {
                setShowLoader(false)
                notify('Project updated')
                refresh()
                //navigate('/merchants')
            }).catch(() => setShowLoader(false))
    }

    return <React.Fragment>
        <Grid container spacing={2}
              component="form"
              onSubmit={handleSubmit}
              autoComplete="off"
        >
            <Grid item xs={12}>
                <InputLabel>Webhook URL (HTTPS POST JSON)</InputLabel>
                <TextField
                    error={!!errors.webhook}
                    value={fields.webhook || ""}
                    name="webhook"
                    id="webhook"
                    required={true}
                    variant="standard"
                    fullWidth={true}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                {errors.webhook && <FormHelperText>{errors.webhook}</FormHelperText>}
            </Grid>
            <Grid item xs={12}>
                <InputLabel>Secret Key</InputLabel>
                <Input
                    error={!!errors.secret_key}
                    value={fields.secret_key || ""}
                    name="secret_key"
                    id="secret_key"
                    required={true}
                    variant="standard"
                    fullWidth={true}
                    readOnly={true}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="Copy key"
                                edge="start"
                                onClick={() => handleCopy(fields.secret_key)}
                            >
                                <ContentCopy />
                            </IconButton>
                            <Divider orientation="vertical" />
                            <IconButton
                                aria-label="Set new key"
                                edge="end"
                                onClick={() => window.confirm('A new secret key will be set if you confirm. Are you sure you want to proceed?') && setNewKey()}
                            >
                                <Refresh />
                            </IconButton>
                        </InputAdornment>
                    }
                />
                {errors.secret_key && <FormHelperText>{errors.secret_key}</FormHelperText>}
            </Grid>
            <Grid container item spacing={3} sx={{mt: 2}}>
                <Grid item xs={6}>
                    <Button type="submit" onClick={handleSubmit} variant="outlined" startIcon={<Save />} >
                        Save
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    </React.Fragment>
}
