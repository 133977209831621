import * as React from 'react';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from '../Title';
import {useEffect, useState} from "react";
import customers from '../../models/customers'
import helpers from "../../helpers/common";
import useRootHook from "../../hooks/useRoot";
import {
    ExpandMore,
    Add,
    Refresh,
    FileDownload,
    FormatListBulleted
} from "@mui/icons-material";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import {Link as RouterLink, useNavigate, useSearchParams} from "react-router-dom";
import Paper from "@mui/material/Paper";
import {Accordion, AccordionDetails, AccordionSummary, Stack, Tooltip} from "@mui/material";
import Typography from "@mui/material/Typography";
import BreadCrumbs from "../BreadCrumbs";
import GetLinkDialog from "./GetLinkDialog";
import LinkIcon from '@mui/icons-material/Link';
import IconButton from "@mui/material/IconButton";
import Pagination from "../Pagination";
import CustomersFilter from "./CustomersFilter";
import {AclRouterLink} from "../ACL";

export default function CustomerIndex(props) {
    const [searchParams] = useSearchParams()
    const fullPage = typeof props.fullPage === 'boolean' ? props.fullPage : true
    const [customersData, setCustomersData] = useState(null)
    const [refreshId, setRefreshId] = useState(0)
    const {setShowLoader} = useRootHook()
    const navigate = useNavigate()

    useEffect(() => {
        setShowLoader(true)
        customers.getData(searchParams ? Object.fromEntries(searchParams) : {})
            .then((data) => {
                data && setCustomersData(data)
                setShowLoader(false)
            }).catch(() => setShowLoader(false))
    }, [searchParams, refreshId])

    const handleRefresh = () => {
        setRefreshId((prevId) => {
            return prevId + 1
        })
    }

    const handleReset = () => {
        navigate('?')
    }

    const handleSearch = (event) => {
        const data = new FormData(event.currentTarget)
        navigate('?' + new URLSearchParams(Object.fromEntries(data.entries())).toString())
    }


    if (fullPage) {
        return (
        <Container filters={Object.fromEntries(searchParams.entries())} handleRefresh={handleRefresh} handleReset={handleReset} handleSearch={handleSearch}>
            <Content data={customersData}/>
        </Container>
        )
    }

    return <Content data={customersData} />

}

function Container({children, filters, handleRefresh}) {

    const handleExport = () => {
    }

    return <React.Fragment>
        <Grid item xs={12}>
            <BreadCrumbs elements={[['Dashboard', '/'], ['Customers', '/customers']]}/>
        </Grid>
        <Grid item xs={12}>
            <Accordion defaultExpanded={filters && Object.keys(filters).length > 0}>
                <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography>Filters</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <CustomersFilter />
                </AccordionDetails>
            </Accordion>
        </Grid>
        <Grid item xs={12}>
            <Stack spacing={2} direction="row" sx={{display: {xs: 'none', md: 'inherit'}}}>
                <Button onClick={handleRefresh} color="primary" variant="contained" startIcon={<Refresh />}>
                    Refresh
                </Button>
                <Button onClick={handleExport} color="secondary" variant="contained" startIcon={<FileDownload />}>
                    Export CSV
                </Button>
                <Button to="/customers/add" component={AclRouterLink} color="primary" variant="contained" startIcon={<Add />}>
                    Add Customer
                </Button>
            </Stack>
            <Stack spacing={2} direction="row" sx={{display: {xs: 'inherit', md: 'none'}}}>
                <IconButton onClick={handleRefresh} color="primary">
                    <Refresh />
                </IconButton>
                <Button onClick={handleExport} color="secondary">
                    <FileDownload />
                </Button>
                <IconButton to="/customers/add" component={AclRouterLink} color="primary">
                    <Add />
                </IconButton>
            </Stack>
        </Grid>
        <Grid item xs={12}>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                {children}
            </Paper>
        </Grid>
    </React.Fragment>
}

function Content(props) {
    const {data} = props
    const [dialogIsOpened, setDialogIsOpened] = useState(false)
    const [selectedUser, setSelectedUser] = useState(null)

    return (
        <React.Fragment>
            <Title>Customers</Title>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Name Surname</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Merchant</TableCell>
                        <TableCell>Project</TableCell>
                        <TableCell>Country</TableCell>
                        <TableCell>Date</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {(data?.customers || []).map((row) => (
                        <TableRow key={`customer-${row.id}`} sx={{backgroundColor: row.blocked ? "#ece3e3" : "inherit"}}>
                            <TableCell>
                                <Link color="primary" to={`edit?uuid=${helpers.UUIDv4Transform(row.uuid)}`} component={RouterLink} customer={row}>
                                    {`${row.name} ${row.surname}`}
                                </Link>
                            </TableCell>
                            <TableCell>{row.email}</TableCell>
                            <TableCell>{row['merchant.name']}</TableCell>
                            <TableCell>{row['project.name']}</TableCell>
                            <TableCell>{row.country.toUpperCase()}</TableCell>
                            <TableCell>{helpers.getDateTime(row.created)}</TableCell>
                            <TableCell>
                                <Tooltip title="Get payment link">
                                    <IconButton onClick={() => {
                                        setSelectedUser(row)
                                        setDialogIsOpened(true)
                                    }}>
                                        <LinkIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="View transactions">
                                    <IconButton to={`/transactions?cemail=${row.email}`} component={RouterLink}>
                                        <FormatListBulleted fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <Pagination url={'/customers'} count={data?.count || 1}/>
            <GetLinkDialog dialogIsOpened={dialogIsOpened} setDialogIsOpened={setDialogIsOpened} selectedUser={selectedUser} setSelectedUser={setSelectedUser}/>
        </React.Fragment>

    )
}

