import * as React from "react";
import helpers from "../../helpers/common";
import projects from "../../models/projects";
import {useEffect, useState} from "react";
import useRootHook from "../../hooks/useRoot";
import ProjectForm from "./ProjectForm";

export default function ProjectEditMain ({project, refresh}) {
    const [fields, setFields] = useState({})
    const [errors, setErrors] = useState({})
    const {setShowLoader, notify, userData} = useRootHook()

    useEffect(() => {
        if (!project) return

        setFields({
            id: project.id,
            name: project.name,
            email: project.email,
            url: project.url,
            phone: project.phone,
            enabled: project.enabled
        })
    }, [project])

    if (!project) return null

    const handleChange = (ev) => {
        const [target, value, name] = helpers.getFormEventData(ev)

        setErrors({...errors, [name]: undefined})

        setFields((prevState) => {
            let newFields = {...prevState}
            newFields[name] = value

            return newFields
        })
    }

    const handleBlur = (ev) => {
        const [target, value, name] = helpers.getFormEventData(ev)

        const newErrors = {...errors}
        const newFields = {...fields}

        typeof projects.fieldValidator[name] === 'function'
        && projects.fieldValidator[name](newFields, newErrors)

        setFields(newFields)

        setErrors(newErrors)
    }

    const handleSubmit = (ev) => {
        ev.preventDefault()
        const validationErrors = projects.methodValidator.edit(fields)
        setErrors(validationErrors)
        if (Object.keys(validationErrors).length !== 0) return

        setShowLoader(true)
        projects.edit(fields)
            .then(() => {
                setShowLoader(false)
                notify('Project updated')
                refresh()
            }).catch(() => setShowLoader(false))
    }

    return <ProjectForm project={project} fields={fields} errors={errors} handleSubmit={handleSubmit} handleChange={handleChange} handleBlur={handleBlur}/>
}
