import Grid from "@mui/material/Grid";
import {FormHelperText, InputLabel, MenuItem, Select, TextField} from "@mui/material";
import Button from "@mui/material/Button";
import {Add} from "@mui/icons-material";
import * as React from "react";

export default function ServerForm ({images, serverData, fields, errors, handleChange, handleBlur, handleSubmit}) {
    return <React.Fragment>
        <Grid container spacing={2}
              component="form"
              onSubmit={handleSubmit}
              autoComplete="off"
        >
            <Grid item xs={12} md={6}>
                <InputLabel>Domain Name</InputLabel>
                <TextField
                    error={!!errors.name}
                    value={fields.name || ""}
                    name="name"
                    id="name"
                    required={true}
                    variant="standard"
                    fullWidth={true}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                {errors.name && <FormHelperText>{errors.name}</FormHelperText>}
            </Grid>
            {images && <Grid item xs={12} md={6}>
                <InputLabel>Image</InputLabel>
                <Select
                    fullWidth={true}
                    error={!!errors.image}
                    required={true}
                    name="image"
                    variant="standard"
                    id="image"
                    value={fields.image || ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                >
                    {
                        images.map((image, key) => {
                            return <MenuItem key={`id-${image.id}`}
                                             value={image.id}>{`${image.description} - ${image.status}`}</MenuItem>
                        })
                    }
                </Select>
                {errors.image && <FormHelperText>{errors.image}</FormHelperText>}
            </Grid>
            }
            <Grid container item spacing={3} sx={{mt: 2}}>
                <Grid item xs={6}>
                    <Button type="submit" onClick={handleSubmit} variant="outlined" startIcon={<Add />} >
                        Submit
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    </React.Fragment>
}
