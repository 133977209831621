import Grid from "@mui/material/Grid";
import {Checkbox, FormControlLabel, FormHelperText, InputLabel, MenuItem, Select, TextField} from "@mui/material";
import countries from "../../helpers/countries";
import Button from "@mui/material/Button";
import {Add} from "@mui/icons-material";
import * as React from "react";

export default function CustomerForm ({projectsData, fields, errors, handleChange, handleBlur, handleSubmit, project_id}) {
    return <React.Fragment>
        <Grid container spacing={2}
              component="form"
              onSubmit={handleSubmit}
              autoComplete="off"
        >
            <Grid item xs={12} md={6}>
                <InputLabel>Project</InputLabel>
                <Select
                    fullWidth={true}
                    error={!!errors.project_id}
                    required={true}
                    name="project_id"
                    variant="standard"
                    id="project_id"
                    value={fields.project_id || ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    readOnly={!!project_id}
                >
                    {
                        projectsData.projects.map((project) => {
                            return <MenuItem key={`id-${project.id}`} value={project.id}>{project.name}</MenuItem>
                        })
                    }
                </Select>
                {errors.project_id && <FormHelperText>{errors.project_id}</FormHelperText>}
            </Grid>
            <Grid item xs={12} md={6}>
                <InputLabel>Email</InputLabel>
                <TextField
                    error={!!errors.email}
                    value={fields.email || ""}
                    name="email"
                    id="email"
                    required={true}
                    variant="standard"
                    fullWidth={true}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                {errors.email && <FormHelperText>{errors.email}</FormHelperText>}
            </Grid>
            <Grid item xs={12} md={6}>
                <InputLabel>Name</InputLabel>
                <TextField
                    error={!!errors.name}
                    value={fields.name || ""}
                    name="name"
                    id="name"
                    required={true}
                    variant="standard"
                    size="small"
                    fullWidth={true}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                {errors.name && <FormHelperText>{errors.name}</FormHelperText>}
            </Grid>
            <Grid item xs={12} md={6}>
                <InputLabel>Surname</InputLabel>
                <TextField
                    error={!!errors.surname}
                    value={fields.surname || ""}
                    name="surname"
                    id="surname"
                    required={true}
                    variant="standard"
                    size="small"
                    fullWidth={true}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                {errors.surname && <FormHelperText>{errors.surname}</FormHelperText>}
            </Grid>
            <Grid item xs={12} md={6}>
                <InputLabel>Phone</InputLabel>
                <TextField
                    error={!!errors.phone}
                    value={fields.phone || ""}
                    name="phone"
                    id="phone"
                    required={true}
                    variant="standard"
                    size="small"
                    fullWidth={true}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                {errors.phone && <FormHelperText>{errors.phone}</FormHelperText>}
            </Grid>
            <Grid item xs={12} md={6}>
                <InputLabel>Address</InputLabel>
                <TextField
                    error={!!errors.address}
                    value={fields.address || ""}
                    name="address"
                    id="address"
                    required={true}
                    variant="standard"
                    size="small"
                    fullWidth={true}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                {errors.address && <FormHelperText>{errors.address}</FormHelperText>}
            </Grid>
            <Grid item xs={6} md={4}>
                <InputLabel>Zip</InputLabel>
                <TextField
                    error={!!errors.zip}
                    value={fields.zip || ""}
                    name="zip"
                    id="zip"
                    required={true}
                    variant="standard"
                    size="small"
                    fullWidth={true}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                {errors.zip && <FormHelperText>{errors.zip}</FormHelperText>}
            </Grid>
            <Grid item xs={6} md={4}>
                <InputLabel>City</InputLabel>
                <TextField
                    error={!!errors.city}
                    value={fields.city || ""}
                    name="city"
                    id="city"
                    required={true}
                    variant="standard"
                    size="small"
                    fullWidth={true}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                {errors.city && <FormHelperText>{errors.city}</FormHelperText>}
            </Grid>
            <Grid item xs={6} md={4}>
                <InputLabel>Country</InputLabel>
                <Select
                    fullWidth={true}
                    error={!!errors.country}
                    required={true}
                    name="country"
                    size="small"
                    variant="standard"
                    id="country"
                    value={fields.country || ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                >
                    {
                        countries.map((country) => {
                            return <MenuItem key={`id-${country.code}`} value={country.code}>{country.name}</MenuItem>
                        })
                    }
                </Select>
                {errors.country && <FormHelperText>{errors.country}</FormHelperText>}
            </Grid>
            <Grid item xs={12}>
                {<FormControlLabel
                    control={
                        <Checkbox
                            name="blocked"
                            checked={typeof fields.blocked === 'boolean' ? fields.blocked : false}
                            onChange={handleChange}
                            value={typeof fields.blocked === 'boolean' ? fields.blocked : false}
                            color="primary"
                        />
                    }
                    label="Blocked"
                />}
            </Grid>
            <Grid container item spacing={3} sx={{mt: 2}}>
                <Grid item xs={6}>
                    <Button type="submit" onClick={handleSubmit} variant="outlined" startIcon={<Add />} >
                        Submit
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    </React.Fragment>
}
