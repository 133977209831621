import request from "../helpers/request";
import helpers from "../helpers/common";

const merchants = {}

merchants.init = async () => {
    return request.get('/init')
}

merchants.getData = (filters = {}) => {
    return request.get('/merchants', filters, true)
}
merchants.list = (filters = {}) => {
    return request.get('/merchants/list', filters, true)
}
merchants.get = (uuid) => {
    return request.get('/merchants/get', {uuid}, true)
}

merchants.add = async (data) => {
    return request.post('/merchants/add', data, true)
}

merchants.edit = async (data) => {
    return request.put('/merchants/edit', data, true)
}

merchants.login = async (username, password) => {
    return request.post('/merchants/login', {username, password}, true)
}

merchants.logout = async () => {
    await request.post('/merchants/logout', {}, true)
    //window.location.href = '/'
}

merchants.addMerchant = async (data) => {
    return request.post('/merchants/add', {...data, type: 'm'}, true)
}

merchants.forgotPassword = async (email, type = 'm') => {
    return request.post('/merchants/forgot-password', {email, type}, true)
}

merchants.methodValidator = {}
merchants.methodValidator.add = (fields, errors = {}) => {
    merchants.fieldValidator.username(fields, errors)
    merchants.fieldValidator.password(fields, errors)
    merchants.fieldValidator.name(fields, errors)
    return errors
}
merchants.methodValidator.edit = (fields, errors = {}) => {
    merchants.fieldValidator.username(fields, errors)
    fields.password && merchants.fieldValidator.password(fields, errors)
    merchants.fieldValidator.name(fields, errors)
    return errors
}

merchants.fieldValidator = {}
merchants.fieldValidator.username = (fields, errors) => {
    helpers.emailValidator(fields, errors, 'username')
}
merchants.fieldValidator.password = (fields, errors) => {
    helpers.passwordValidator(fields, errors)
}
merchants.fieldValidator.name = (fields, errors) => {
   if (!fields.name || fields.name.length < 3) return errors.name = 'Name should be at least 3 characters'
    fields.name = fields.name.trim()
}

export default merchants