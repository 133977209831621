import Grid from "@mui/material/Grid";
import {FormHelperText, InputLabel, MenuItem, Select, TextField} from "@mui/material";
import Button from "@mui/material/Button";
import {Add, Save} from "@mui/icons-material";
import * as React from "react";
import payouts from "../../models/payouts";

export default function PayoutForm ({payoutMethods, fields, errors, handleChange, handleBlur, handleSubmit}) {
    console.log(fields)
    return <React.Fragment>
        <Grid container spacing={2}
              component="form"
              onSubmit={handleSubmit}
              autoComplete="off"
        >
            <Grid item xs={12} md={3}>
                <InputLabel>Method</InputLabel>
                <Select
                    fullWidth={true}
                    error={!!errors.method_id}
                    required={true}
                    name="method_id"
                    variant="standard"
                    id="method_id"
                    value={fields.method_id || ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                >
                    {
                        payoutMethods.map((method) => {
                            return <MenuItem key={`id-${method.id}`} value={method.id}>{`${method.name} - fee ${method.fee}%`}</MenuItem>
                        })
                    }
                </Select>
                {errors.method_id && <FormHelperText>{errors.method_id}</FormHelperText>}
            </Grid>
            <Grid item xs={6} md={3}>
                <InputLabel>Amount</InputLabel>
                <TextField
                    error={!!errors.amount_total}
                    value={fields.amount_total || ""}
                    name="amount_total"
                    id="amount_total"
                    required={true}
                    variant="standard"
                    fullWidth={true}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                {errors.amount_total && <FormHelperText>{errors.amount_total}</FormHelperText>}
            </Grid>
            {fields.id && <Grid item xs={6} md={3}>
                <InputLabel>Net Amount</InputLabel>
                <TextField
                    error={!!errors.amount}
                    value={fields.amount || ""}
                    name="amount"
                    id="amount"
                    required={true}
                    variant="standard"
                    fullWidth={true}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                {errors.amount && <FormHelperText>{errors.amount}</FormHelperText>}
            </Grid>}
            {fields.id && <Grid item xs={12} md={3}>
                <InputLabel>Status</InputLabel>
                <Select
                    fullWidth={true}
                    error={!!errors.status}
                    required={true}
                    name="status"
                    variant="standard"
                    id="status"
                    value={fields.status || ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                >
                    {
                        payouts.getStatuses().map((status) => {
                            return <MenuItem key={`status-${status}`} value={status}>{status}</MenuItem>
                        })
                    }
                </Select>
                {errors.status && <FormHelperText>{errors.status}</FormHelperText>}
            </Grid>}
            {fields.id && <Grid item xs={12}>
                <InputLabel>Details</InputLabel>
                <TextField
                    error={!!errors.details}
                    value={fields.details || ""}
                    name="details"
                    id="details"
                    required={true}
                    variant="standard"
                    size="small"
                    fullWidth={true}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                {errors.details && <FormHelperText>{errors.details}</FormHelperText>}
            </Grid>}
            <Grid container item spacing={3} sx={{mt: 2}}>
                <Grid item xs={6}>
                    <Button type="submit" onClick={handleSubmit} variant="outlined" startIcon={fields.id ? <Save /> : <Add />} >
                        {fields.id ? 'Save' : 'Submit'}
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    </React.Fragment>
}
