import * as React from "react";
import helpers from "../helpers/common";

const rootContext = React.createContext({
    authed: false,
    setAuthed: () => null,
    userData: {},
    setUserData: () => null,
    defaultCurrency: {},
    setDefaultCurrency: () => null,
    showLoader: false,
    setShowLoader: () => null,
    showNotification: false,
    setShowNotification: () => null,
    notificationMessage: '',
    setNotificationMessage: () => null,
    notificationTimeout: 6000,
    setNotificationTimeout: () => null,
    forcedMerchantID: null,
    setForcedMerchantID: () => null,
    setForcedMerchant: () => null,
    removeForcedMerchant: () => null,
    notify: () => null
});

function useRootHook() {
    const [authed, setAuthed] = React.useState(false);
    const [userData, setUserData] = React.useState(null);
    const [defaultCurrency, setDefaultCurrency] = React.useState(null);
    const [showLoader, setShowLoader] = React.useState(false);
    const [showNotification, setShowNotification] = React.useState(false);
    const [notificationMessage, setNotificationMessage] = React.useState('');
    const [notificationTimeout, setNotificationTimeout] = React.useState(6000);
    const [forcedMerchantID, setForcedMerchantID] = React.useState(null);

    const notify = (text, timeout = 6000) => {
        setNotificationMessage(text)
        setNotificationTimeout(timeout)
        setShowNotification(true)
    }

    const setForcedMerchant = (merchantID, loginUserData = userData) => {
        if (
            ![helpers.roles.A, helpers.roles.S].includes(loginUserData.role)
            || !helpers.onlyDigitsRegex(merchantID)
        ) {
            return
        }

        helpers.forcedMerchantID = parseInt(merchantID)
        setForcedMerchantID(helpers.forcedMerchantID)
    }
    const removeForcedMerchant = (merchantID) => {
        helpers.forcedMerchantID = null
        setForcedMerchantID(helpers.forcedMerchantID)
        return true//for concatenating actions
    }

    return {
        authed,
        setAuthed,
        userData,
        setUserData,
        defaultCurrency,
        setDefaultCurrency,
        showLoader,
        setShowLoader,
        showNotification,
        setShowNotification,
        notificationMessage,
        setNotificationMessage,
        notificationTimeout,
        setNotificationTimeout,
        forcedMerchantID,
        setForcedMerchant,
        removeForcedMerchant,
        notify
    };
}

export function RootProvider({ children }) {
    const root = useRootHook();

    return <rootContext.Provider value={root}>{children}</rootContext.Provider>;
}

export default function RootConsumer() {
    return React.useContext(rootContext);
}

