import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {Chip, Tab, Tabs} from "@mui/material";
import TableBody from "@mui/material/TableBody";
import helpers from "../../helpers/common";
import transactions from "../../models/transactions";
import {Link as RouterLink} from 'react-router-dom'
import Link from "@mui/material/Link";
import {useEffect, useState} from 'react';
import useRootHook from '../../hooks/useRoot';
import PrettyJson from '../PrettyJson';
import callbacks from '../../models/callbacks';
import IconButton from '@mui/material/IconButton';
import {Settings} from '@mui/icons-material';
import {AclRouterLink} from '../ACL';
import TransactionStatusDialog from './TransactionStatusDialog';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
})

const TransactionDetailTableRow = ({name, children}) => {
    return <TableRow>
        <TableCell sx={{p: 0.5, borderBottom: 'none'}} align="left"><Typography variant="body2">{name}</Typography></TableCell>
        <TableCell sx={{p: 0.5, borderBottom: 'none'}} align="right">{children}</TableCell>
    </TableRow>
}
const TransactionDetails = ({transaction, refresh, closeDetailsDialog}) => {
    const {userData} = useRootHook();
    const [dialogIsOpened, setDialogIsOpened] = useState(false);
    const [selectedTransaction, setSelectedTransaction] = useState(null);

    return <>
        <Table>
            <TableBody>
                <TransactionDetailTableRow name="ID">
                    <Typography variant="body2" fontWeight="500">{transaction.uuid}</Typography>
                </TransactionDetailTableRow>
                {
                    !!transaction.provider_id &&
                    <TransactionDetailTableRow name="Provider ID">
                        <Typography variant="body2" fontWeight="500">{transaction.provider_id}</Typography>
                    </TransactionDetailTableRow>
                }
                {
                    !!transaction.external_id &&
                    <TransactionDetailTableRow name="Merchant Transaction ID">
                        <Typography variant="body2" fontWeight="500">{transaction.external_id}</Typography>
                    </TransactionDetailTableRow>
                }
                <TransactionDetailTableRow name="Status">
                    <Chip size="small" color={transactions.getChipStatusColor(transaction.status)} label={transactions.enumToStatus[transaction.status].capitalize()}/>
                    {
                        userData.role === helpers.roles.S &&
                        <IconButton size="small" onClick={() => {
                            setSelectedTransaction(transaction);
                            setDialogIsOpened(true);
                        }}>
                            <Settings fontSize="small"/>
                        </IconButton>
                    }
                </TransactionDetailTableRow>
                {
                    !!transaction.provider_status &&
                    <TransactionDetailTableRow name="Provider Status">
                        <Typography variant="body2" fontWeight="500">{transaction.provider_status}</Typography>
                    </TransactionDetailTableRow>
                }
                <TransactionDetailTableRow name="Info">
                    <Typography variant="body2" fontWeight="500">{transaction.info || ''}</Typography>
                </TransactionDetailTableRow>
                <TransactionDetailTableRow name="Currency Amount">
                    <Typography variant="body2" fontWeight="500">{helpers.getSubunitAmountString(transaction.amount_currency, transaction['currency.symbol'], transaction['currency.subunit'])}</Typography>
                </TransactionDetailTableRow>
                <TransactionDetailTableRow name="Amount">
                    <Typography variant="body2" fontWeight="500">{helpers.getSubunitAmountString(transaction.amount, transaction['default_currency.symbol'], transaction['default_currency.subunit'])}</Typography>
                </TransactionDetailTableRow>
                <TransactionDetailTableRow name="Fee">
                    <Typography variant="body2" fontWeight="500">{helpers.getSubunitAmountString(transaction.fee, transaction['default_currency.symbol'], transaction['default_currency.subunit'])}</Typography>
                </TransactionDetailTableRow>
                <TransactionDetailTableRow name="Net Amount">
                    <Typography variant="body2" fontWeight="500">{helpers.getSubunitAmountString(transaction.amount_net, transaction['default_currency.symbol'], transaction['default_currency.subunit'])}</Typography>
                </TransactionDetailTableRow>
                <TransactionDetailTableRow name="Hold Amount">
                    <Typography variant="body2" fontWeight="500">{helpers.getSubunitAmountString(transaction.hold_amount, transaction['default_currency.symbol'], transaction['default_currency.subunit'])}</Typography>
                </TransactionDetailTableRow>
                <TransactionDetailTableRow name="Hold Release Date">
                    <Typography variant="body2" fontWeight="500">{helpers.getDateTime(transaction.hold_release)}</Typography>
                </TransactionDetailTableRow>
                <TransactionDetailTableRow name="Rolling Reserve Amount">
                    <Typography variant="body2" fontWeight="500">{helpers.getSubunitAmountString(transaction.reserve_amount, transaction['default_currency.symbol'], transaction['default_currency.subunit'])}</Typography>
                </TransactionDetailTableRow>
                <TransactionDetailTableRow name="Rolling Reserve Release Date">
                    <Typography variant="body2" fontWeight="500">{helpers.getDateTime(transaction.reserve_release)}</Typography>
                </TransactionDetailTableRow>
                <TransactionDetailTableRow name="Merchant">
                    <Typography variant="body2" fontWeight="500">{transaction['merchant.name']}</Typography>
                </TransactionDetailTableRow>
                <TransactionDetailTableRow name="Project">
                    <Typography variant="body2" fontWeight="500">{transaction['project.name']}</Typography>
                </TransactionDetailTableRow>
                <TransactionDetailTableRow name="Payment Method">
                    <Typography variant="body2" fontWeight="500">{transaction['paymethod.name']}</Typography>
                </TransactionDetailTableRow>
                <TransactionDetailTableRow name="Customer">
                    <Link to={`?customer_id=${transaction.customer_id}`} component={RouterLink} target="_blank">
                        <Typography variant="body2" fontWeight="500">{`${transaction['customer.name']} ${transaction['customer.surname']}`}</Typography>
                    </Link>
                </TransactionDetailTableRow>
                <TransactionDetailTableRow name="Disputed">
                    <Chip size="small" color={transaction.disputed ? 'error' : 'default'} label={transaction.disputed ? 'Yes' : 'No'} />
                </TransactionDetailTableRow>
                {
                    !!transaction.disputed &&
                    <TransactionDetailTableRow name="Dispute Cost">
                        <Typography variant="body2" fontWeight="500">{transaction.dispute_cost}</Typography>
                    </TransactionDetailTableRow>
                }
                <TransactionDetailTableRow name="API">
                    <Chip size="small" color="default" label={transaction.api ? 'Yes' : 'No'}/>
                </TransactionDetailTableRow>
                <TransactionDetailTableRow name="Created">
                    <Typography variant="body2" fontWeight="500">{helpers.getDateTime(transaction.created)}</Typography>
                </TransactionDetailTableRow>
                <TransactionDetailTableRow name="Last Change">
                    <Typography variant="body2" fontWeight="500">{helpers.getDateTime(transaction.modified)}</Typography>
                </TransactionDetailTableRow>
                <TransactionDetailTableRow name="Completed">
                    <Typography variant="body2" fontWeight="500">{!!transaction.completed ? helpers.getDateTime(transaction.completed) : ''}</Typography>
                </TransactionDetailTableRow>
            </TableBody>
        </Table>
        <TransactionStatusDialog
            dialogIsOpened={dialogIsOpened}
            setDialogIsOpened={setDialogIsOpened}
            selectedTransaction={selectedTransaction}
            setSelectedTransaction={setSelectedTransaction}
            refresh={refresh}
            closeDetailsDialog={closeDetailsDialog}
        />
    </>
}

const TransactionCallbacks = ({transaction}) => {
    const [transactionDetails, setTransactionDetails] = useState(null)
    const {setShowLoader} = useRootHook();
    useEffect(() => {
        transactions.get(helpers.UUIDv4Transform(transaction.uuid), true)
            .then((data) => {
                if (!data) {
                    setShowLoader(false)
                    return
                }
                data && setTransactionDetails(data)
                setShowLoader(false)
            }).catch((err) => {
            setShowLoader(false)
        })
    }, [transaction]);

    if (!transactionDetails) return null;

    return (
        transactionDetails.callbacks.map((callback) => {
            const lastRequest = callback.callback_requests.length > 0 ? callback.callback_requests.slice(-1)[0] : null;
            return (
                <Table>
                    <TableBody>
                        <TransactionDetailTableRow name="Transaction Status">
                            <Typography variant="body2" fontWeight="500">{transactions.getStatusByEnum(callback.transaction_status)}</Typography>
                        </TransactionDetailTableRow>
                        <TransactionDetailTableRow name="Created date">
                            <Typography variant="body2" fontWeight="500">{helpers.getDateTime(callback.created)}</Typography>
                        </TransactionDetailTableRow>
                        <TransactionDetailTableRow name="URL">
                            <Typography variant="body2" fontWeight="500">{lastRequest && lastRequest.url}</Typography>
                        </TransactionDetailTableRow>
                        <TransactionDetailTableRow name="Status">
                            <Typography variant="body2" fontWeight="500">{callbacks.enumToStatus[callback.status]}</Typography>
                        </TransactionDetailTableRow>
                        <TransactionDetailTableRow name="Attempts">
                            <Typography variant="body2" fontWeight="500">{callback.requests}</Typography>
                        </TransactionDetailTableRow>
                        <TransactionDetailTableRow name="Last attempt">
                            <Typography variant="body2" fontWeight="500">{lastRequest && helpers.getDateTime(lastRequest.created)}</Typography>
                        </TransactionDetailTableRow>
                        <TransactionDetailTableRow name="Next attempt">
                            <Typography variant="body2" fontWeight="500">{lastRequest && helpers.getDateTime(callback.next_request)}</Typography>
                        </TransactionDetailTableRow>
                        <TransactionDetailTableRow name="HTTP status">
                            <Typography variant="body2" fontWeight="500">{lastRequest && lastRequest.status}</Typography>
                        </TransactionDetailTableRow>
                        <TableRow>
                            <TableCell sx={{p: 0.5, borderBottom: 'none'}} align="left"><Typography variant="body2">Response body</Typography></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell sx={{p: 0.5, borderBottom: 'none'}} align="left">{lastRequest && <PrettyJson json={lastRequest.response_body} />}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell sx={{p: 0.5, borderBottom: 'none'}} align="left"><Typography variant="body2">Request body</Typography></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell sx={{p: 0.5, borderBottom: 'none'}} align="left">{lastRequest && <PrettyJson json={lastRequest.request_body} />}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                            </TableCell>
                            <TableCell>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            )
        })
    )
}

export default function TransactionDetailsDialog(props) {
    const { setDialogIsOpened, dialogIsOpened, setSelectedTransaction, selectedTransaction, refresh} = props
    const [tabIndex, setTabIndex] = React.useState(0)

    const handleTabChange = (event, newIndex) => {
        setTabIndex(newIndex);
    }

    const handleClose = () => {
        setDialogIsOpened(false)
        setSelectedTransaction(null)
    }

    const descriptionElementRef = React.useRef(null);
    React.useEffect(() => {
        if (dialogIsOpened) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [dialogIsOpened])

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    if (!selectedTransaction) return null

    return (
        <div>
            <Dialog
                open={dialogIsOpened}
                onClose={handleClose}
                TransitionComponent={Transition}
                keepMounted
                scroll="paper"
                aria-labelledby="transaction-details-title"
                aria-describedby="transaction-details"
                maxWidth='md'
            >
                <DialogTitle sx={{p:0}}>
                    <Box sx={{ width: '100%' }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs variant="fullWidth" value={tabIndex} onChange={handleTabChange} aria-label="transaction details">
                                <Tab label="Transaction" {...a11yProps(0)} />
                                <Tab disabled={!selectedTransaction.api} label="Callbacks" {...a11yProps(1)} />
                            </Tabs>
                        </Box>
                    </Box>
                </DialogTitle>
                <DialogContent dividers={true} sx={{p: 0, borderTop: "none"}}>
                    <Box sx={{ width: '100%' }}>
                        <Box hidden={tabIndex !== 0} role='tabpanel' sx={{p: 1}}>
                            <TransactionDetails transaction={selectedTransaction} refresh={refresh} closeDetailsDialog={handleClose}/>
                        </Box>
                        <Box hidden={tabIndex !== 1} role='tabpanel' sx={{p: 1}}>
                            <TransactionCallbacks transaction={selectedTransaction} />
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}