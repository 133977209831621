import * as React from 'react';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from '../Title';
import {useEffect, useState} from "react";
import helpers from "../../helpers/common";
import useRootHook from "../../hooks/useRoot";
import {Add} from "@mui/icons-material";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import {Link as RouterLink} from "react-router-dom";
import Paper from "@mui/material/Paper";
import BreadCrumbs from "../BreadCrumbs";
import currencies from "../../models/currencies";
import Container from "@mui/material/Container";

export default function CurrencyIndex(props) {
    const [currenciesData, setCurrenciesData] = useState([])
    const {setShowLoader} = useRootHook();

    useEffect(() => {
        setShowLoader(true)
        currencies.index()
            .then((data) => {
                data && setCurrenciesData(data)
                setShowLoader(false)
            }).catch(() => setShowLoader(false))
    }, [])

    return <Container>
        <Grid item xs={12}>
            <BreadCrumbs elements={[['Dashboard', '/'], ['Currencies', '/currencies']]}/>
        </Grid>
        <Grid item xs={12} sx={{ p: 3, pl: 0}}>
            <Button to="/currencies/add" component={RouterLink} variant="contained" startIcon={<Add />}>
                Add Currency
            </Button>
        </Grid>
        <Grid item xs={12}>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                <Title>Currencies</Title>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Currency</TableCell>
                            <TableCell>Subunit</TableCell>
                            <TableCell>Created</TableCell>
                            <TableCell>Modified</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {currenciesData.map((row) => (
                            <TableRow key={`customer-${row.id}`}>
                                <TableCell>
                                    <Link
                                        color="primary"
                                        to={`edit?id=${row.id}`}
                                        component={RouterLink}
                                        customer={row}
                                        onClick={(ev) => {!window.confirm('Continue only if you know what you are doing!') && ev.preventDefault()}}
                                    >
                                        {`${row.id}`}
                                    </Link>
                                </TableCell>
                                <TableCell>{row.currency}</TableCell>
                                <TableCell>{row.subunit}</TableCell>
                                <TableCell>{helpers.getDate(row.created)}</TableCell>
                                <TableCell>{helpers.getDate(row.modified)}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Paper>
        </Grid>
    </Container>


}
