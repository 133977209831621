import request from '../helpers/request';
import helpers from '../helpers/common';

const transactions = {};

transactions.statusToEnum = {
    new: '1',
    completed: '2',
    declined: '3',
    disputed: '4',
    refunded: '5',
    expired: '6'
};
transactions.enumToStatus = helpers.invertObject(transactions.statusToEnum);

transactions.getStatusByEnum = enumID => transactions.enumToStatus[enumID.toString()];
transactions.getEnumByStatus = status => transactions.statusToEnum[status.toLowerCase()];

transactions.getChipStatusColor = (status) => {
    let color;
    switch (transactions.enumToStatus[status]) {
        case 'new':
            color = 'primary';
            break;
        case 'completed':
            color = 'success';
            break;
        case 'declined':
            color = 'error';
            break;
        case 'disputed':
            color = 'warning';
            break;
        case 'refunded':
            color = 'info';
            break;
        case 'expired':
            color = 'primary';
            break;
        default:
            color = 'default';
    }
    return color;
};

transactions.getData = async (filters = {}) => {
    helpers.addForcedMerchantID(filters);
    return request.get('/transactions', filters, false);
};
transactions.get = async (uuid, includeCallbacks = true) => {
    const data = {uuid, includeCallbacks};
    return request.get('/transactions/get', data, false);
};
transactions.getTotals = async (filters = {}) => {
    helpers.addForcedMerchantID(filters);
    return request.get('/transactions/get-total', filters, false);
};
transactions.refund = async (uuid) => {
    return request.post('/transactions/refund', {uuid}, true);
};
transactions.setStatus = async (uuid, status) => {
    return request.post('/transactions/set-status', {uuid, status}, true);
}

export default transactions;