import * as React from 'react';
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import BreadCrumbs from "../BreadCrumbs";
import TotalCompletedChart from "./TotalCompletedChart";

export default function StatsIndex() {
    return <React.Fragment>
        <Grid item xs={12}>
            <BreadCrumbs elements={[['Dashboard', '/'], ['Stats', '/stats']]}/>
        </Grid>
        {/* Recent Orders */}
        <Grid item xs={12} xl={6}>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', height: 300 }}>
                <TotalCompletedChart fullPage={true}/>
            </Paper>
        </Grid>
        <Grid item xs={12} xl={6}>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', height: 300 }}>
                <TotalCompletedChart fullPage={true}/>
            </Paper>
        </Grid>
        <Grid item xs={12} xl={6}>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', height: 300 }}>
                <TotalCompletedChart fullPage={true}/>
            </Paper>
        </Grid>
        <Grid item xs={12} xl={6}>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', height: 300 }}>
                <TotalCompletedChart fullPage={true}/>
            </Paper>
        </Grid>
        <Grid item xs={12} xl={6}>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', height: 300 }}>
                <TotalCompletedChart fullPage={true}/>
            </Paper>
        </Grid>
        <Grid item xs={12} xl={6}>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', height: 300 }}>
                <TotalCompletedChart fullPage={true}/>
            </Paper>
        </Grid>
    </React.Fragment>
}