const PrettyJson = ({json}) => {
    if (typeof json === 'string') {
        try {
            json = JSON.parse(json)
        } catch(err) {

        }

    }
    if (typeof json !== 'object') return <pre>{json}</pre>;
    return (
        <pre>{JSON.stringify(json, null, 2)}</pre>
    );
};

export default PrettyJson;